import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`

  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal,sans-serif;

  .time {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  }

  .submit-time {
    font-size: 12px;
    font-weight: 400;
    color: ${color.TLRAProject};
  }

  .step-four {
    padding-bottom: 13px;
    border-left: solid ${color.button};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }

  .first-line, .second-line, .first-line-right {
    display: flex;
  }

  .first-line, .second-line {
    justify-content: space-between;
  }

  .left-word, .second-line {
    font-size: 14px;
    font-weight: 400;
    color: ${color.loginTitle};
  }

  .right-word {
    line-height: 22px;
    font-size: 12px;
    font-weight: 400;
    color: ${color.loginError};
  }
  
  .ant-drawer-body {
    padding-top: 12px;
  }
  .my-hr {
    border: none;!important;
    height: 0.5px!important;
    background-color:${color.hr};!important;
  }
  .ant-drawer .ant-drawer-content {
    border-radius:1rem 1rem 0 0 ;
  }
`;
export {Wrapper}
