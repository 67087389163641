import React from "react";
import {Wrapper} from "./style";
import UniversalButton from "../../components/UniversalButton";
import {ServerEnum} from "../../enum/field/field";

interface Prop {
    onClick: () => void;
}

export default function Approval(props: Prop) {

    return (
        <Wrapper>
            <div className='pop-root'>
                <div className='txt'>
                    {ServerEnum.MESSAGE}
                </div>
                <hr className="my-hr"/>
                <div className='button'>
                    <UniversalButton
                        name={ServerEnum.KNOW_BUTTON}
                        disabled={false}
                        onClick={props.onClick}
                        style={{
                            fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
                        }}
                    />
                </div>
            </div>
        </Wrapper>
    )
}
