import React, {useEffect, useRef} from "react";
import {Wrapper} from "./style";
import {Card} from "antd";

interface somPpeResponseList {
    hazardControlId: string,
    ppeId: string,
}

interface Prop {
    /**
     * 图片名字
     */
    fileName: string,
    /**
     * 图片地址
     */
    isChecked?: boolean,
    filePath: string
    hazardControlId: string
    hazardControlName: string
    ppeId: string
    somPpeRequestList: somPpeResponseList[]
    setSomPpeRequestList: React.Dispatch<React.SetStateAction<somPpeResponseList[]>>
    isClear: boolean
}


const MyCard = (props: Prop) => {
    const button = useRef<HTMLButtonElement | null>(null);
    const card = useRef<HTMLDivElement | null>(null);
    const {
        isChecked, fileName, filePath, hazardControlId,
        ppeId, somPpeRequestList, setSomPpeRequestList, isClear
    } = props;
    useEffect(() => {
        if (isChecked)
            if (button.current && !button.current?.classList.contains('select')) {
                button.current?.classList.toggle('select');
                card.current?.classList.toggle('card-selected');
            }
    }, [isChecked])
    useEffect(() => {
        if (isClear)
            if (button.current && button.current?.classList.contains('select')) {
                button.current?.classList.toggle('select');
                card.current?.classList.toggle('card-selected');
            }
    }, [isClear])
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.currentTarget.classList.toggle('card-selected');
        if (button.current) {
            button.current?.classList.toggle('select')
            if (button.current) {
                if (button.current?.classList.contains('select')) {
                    const temp = [...somPpeRequestList];
                    const ppeResponse: somPpeResponseList = {
                        hazardControlId: hazardControlId,
                        ppeId: ppeId
                    }
                    temp.push(ppeResponse);
                    setSomPpeRequestList(temp);
                } else {
                    const temp = [...somPpeRequestList];
                    temp.map((item, index) => {
                        if (item.ppeId === ppeId) {
                            temp.splice(index, 1);
                        }
                    })
                    setSomPpeRequestList(temp);
                }
            }
        }
    }
    return (
        <Wrapper>
            <div className="my-card" ref={card} onClick={(event) => {
                handleClick(event)
            }}>
                <Card
                    cover={<img width="100px" height="100px" alt={fileName} src={"/es/file/" + filePath}/>}
                >
                </Card>
                <button ref={button} className="s_type">{fileName}</button>
            </div>
        </Wrapper>
    )
}
export default MyCard;
