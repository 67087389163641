import React, {useState} from "react";
import {Wrapper} from "./style";
import {LMRAEnum} from "../../../../enum/field/field";
import {color} from "../../../../static/color/color";


interface Prop {
    /**
     * 上一行文字
     */
    pre?: string;

    /**
     * 下一行文字
     */
    end?: string;

    /**
     * 点击后的回调函数
     */
    onClick: () => void;
}

export default function Approval(props: Prop) {

    useState()
    return (
        <Wrapper>
            <div className='Pop-root'>
                <div className='txt'>
                    <div style={{display: props.pre !== "" && props.end !== "" ? "none" : "block"}}>
                        <a style={{color: color.risk}}> {LMRAEnum.TXT_PRE}</a><a style={{color: color.loginError}}> {LMRAEnum.TXT_CENTER}</a>
                        <br/>
                        <a style={{color: color.risk}}> {LMRAEnum.TXT_END}</a>
                    </div>

                    {/*默认文字:红色警告*/}
                    <div style={{display: props.pre !== undefined && props.end !== undefined ? "block" : "none"}}>
                        {props.pre}
                        <br/>
                        {props.end}
                    </div>
                </div>
                <hr className="my-hr"/>
                <div className='button' onClick={props.onClick}>
                    {LMRAEnum.CONFIRM}
                </div>
            </div>
        </Wrapper>
    )
}
