import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`
  .boxs-four {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 3%;
  }
  .boxs-four > div{
    margin-bottom: 3%;
  }
  .boxs-five {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 3%;
  }
  .boxs-five > div{
    margin-bottom: 3%;
  }
  .step-five {
    margin-top: 20px;
  }

  .status-point {
    display: inline-block;
    width: 6pt;
    height: 6pt;
    margin-left: 2pt;
    border-radius: 50%;
    background-color: ${color.circle};
  }

  .step-four > div:nth-child(3) {
    margin-top: 12px;
  }

  .step-six {
    margin-top: 22px;
  }

  .step-seven, .step-eight, .step-end {
    margin-top: 15px;
  }

  .title-four, .title-five {
    display: flex;
    justify-content: space-between;
  }

  .title-four > div:first-child, .title-five > div:first-child {
    display: flex;
    align-items: center;
  }

  //标题右侧警告文字
  .title-four > div:last-child, .title-five > div:last-child {
    color: ${color.loginError};
  }

  .title-six + div {
    font-size: 10px;
    color: ${color.TLRAProject};
  }


  .step-end {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  .red-title {
    color: ${color.loginError};
  }

  .my-card {
    padding: 1px;
  }

  //单选框样式
  .edit .ant-radio-inner {
    border: 2px solid ${color.risk};
  }

  .view .ant-radio-inner {
    border: 2px solid ${color.tlraFile};
  }

  .view .ant-radio-checked .ant-radio-inner{
    border-color: #d9d9d9;
  }

  .ant-radio-inner::after {
    background-color: ${color.button};
  }

  .ant-radio-checked {
    border-color: unset;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${color.risk};
    background-color: unset;
  }

  .ant-radio-wrapper .ant-radio-inner:hover {
    border-color: ${color.risk};
  }

  .ant-radio-wrapper {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-group {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-input {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-wrapper .ant-radio-checked::after {
    border: unset;
  }

  .ant-input-affix-wrapper {
    margin-bottom: 16px;
  }


`;
export {Wrapper};
