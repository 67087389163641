import styled from "styled-components";
import {color} from "../../static/color/color";




const Wrapper = styled.div`
  hr {
    height: 1px;
    border: none;
    background-color: ${color.br};
  }

  .Pop-root {
    z-index: 2;
    width: 320px;
    margin-top: -120px;;
    border-radius: 8px;
    background-color: ${color.user};
  }

  .txt {
    text-align: center;
    font-size: 1.0625rem;
    padding: 28px 24px 28px 24px;
  }
  
  .buttons{
    display: flex;
    padding: 16px 0 16px 0;
  }

  .buttonLeft {
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }
  
  .buttonRight{
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  @media (min-height: 420px) and (max-width: 1300px) {
    hr {
      height: 1px;
      margin-top: 3.4475vh;
      margin-bottom: 0;
      background-color: ${color.br};
      border: none;
    }

    .Pop-root {
      width: 85.3vw;
      margin-top: -35vh;
      border-radius: 8px;
      background-color: ${color.user};
    }

    .txt {
      text-align: center;
      font-size: 1.0625rem;
      padding: 3.4475vh 6.4vw 0 6.4vw;
    }

    .buttons{
      /*padding: 1.97vh 6.4vw 1.97vh 6.4vw;*/
      padding: 1.97vh 0 1.97vh 0;
      height: 25px;
    }
    

  }
  
  .my-hr {
    border: none;
    height: 0.5px;
    margin-top: 12px;
    background-color:${color.hr};
  }

  @media (min-width: 650px) and (max-height: 800px){
    .Pop-root {
      width: 65.3vw;
      margin-top: -50vh;
      margin-left: 12vw;
      border-radius: 8px;
      background-color: ${color.user};
    }

    .buttons{
      padding: 0;
      height: 55px;
    }
  }

  @media (min-width: 1360px) and (min-height: 910px){
    .Pop-root {
      width: 85.3vw;
      margin-top: -35vh;
      border-radius: 8px;
      background-color: ${color.user};
    }

    .buttons{
      padding: 0;
      height: 55px;
    }
  }

  @media (min-width:1230px){
    .Pop-root {
      width: 400px;
      height: 150px;
      margin-top: -35vh;
      border-radius: 8px;
      margin-left: 100px;
      background-color: #FFFFFF;
    }
  }

  @media (width:1280px){
    .Pop-root {
      width: 56.3vw;
      margin-top: -26vh;
      margin-left: -2vw;
      border-radius: 8px;
      background-color: #FFFFFF;
    }
  }

  @media (width:1280px) and (height: 800px){
    .Pop-root {
      width: 65.3vw;
      margin-top: -50vh;
      margin-left: 12vw;
      border-radius: 8px;
      background-color: ${color.user};
    }
  }

`;

export {Wrapper};
