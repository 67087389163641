import React, {useEffect, useRef} from "react";
import {Wrapper} from "./style";

interface Prop {
    /**
     * 盒子内文字
     */
    potentialHazardName: string
    /**
     * 盒子是否选中
     */
    isChecked?: boolean
    /**
     * 潜在危害因素对应id
     */
    potentialHazardId: string
    potentialHazardIdList: any[]
    setPotentialHazardIdList: React.Dispatch<React.SetStateAction<any>>;
    isClear: boolean
}

const MyBox = (props: Prop) => {
    const {
        potentialHazardName, potentialHazardId, potentialHazardIdList,
        setPotentialHazardIdList, isChecked, isClear
    } = props;
    const button = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
        if (isChecked)
            if (button.current && !button.current?.classList.contains('select'))
                button.current?.classList.toggle('select');
    }, [isChecked])
    useEffect(() => {
        if (isClear)
            if (button.current && button.current?.classList.contains('select'))
                button.current?.classList.toggle('select');
    }, [isClear])
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.classList.toggle('select');
        if (button.current) {
            if (button.current?.classList.contains('select')) {
                const temp = [...potentialHazardIdList];
                temp.push(potentialHazardId);
                setPotentialHazardIdList(temp);
            } else {
                const temp = [...potentialHazardIdList];
                temp.map((item, index) => {
                    if (item === potentialHazardId) {
                        temp.splice(index, 1);
                    }
                })
                setPotentialHazardIdList(temp);
            }
        }
    }
    return (
        <Wrapper>
            <button className="s_type" ref={button} onClick={
                (event) => {
                    handleClick(event);
                }
            }>{potentialHazardName}</button>
        </Wrapper>
    )
}
export default MyBox;