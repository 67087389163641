import React, {CSSProperties} from "react";
import {useNavigate} from "react-router-dom";
import {Wrapper} from "./style";
import {LeftOutlined} from "@ant-design/icons";
import note from "../../static/img/Filed/Notification.svg";
import {color} from "../../static/color/color";
import {LMRAEnum, otherEnum} from "../../enum/field/field";
import * as store from "store";

interface headProps {
    /**
     * 传入标题
     */
    name: string

    /**
     * 图标显示状态
     */
    state: string

    /**
     * 路由返回路径地址
     */
    Return: string | number

    /**
     * 头部样式
     */
    style?: CSSProperties;

    /**
     * 图标点击事件
     */
    onClick?: () => void;
}

const Head = (props: headProps) => {

    const {name, state, Return} = props;

    const history = useNavigate();

    // 返回导航栏
    const goBack = () => {
        store.remove("LMRA");
        if (typeof Return === "number") {
            history(Return);
        } else  {
            history(Return);
        }
    }

    return (
        <Wrapper>
            {/*整体div房间*/}
            <div className="approval"
                 style={{
                     backgroundColor: name === LMRAEnum.LMRA ? color.button : name === LMRAEnum.SIGNATURE ? color.user : color.headBlue,
                     fontSize: name === otherEnum.APPROVAL ? "12px" : "1rem"
                 }}>
                {/*头部*/}
                <div className="approval-heard">
                    {/*返回按钮开始*/}
                    <div className="return-button">
                        <div className="change-pic">
                            <LeftOutlined className="return-pic" onClick={goBack} style={{marginLeft: "50%"}}/>
                        </div>
                    </div>
                    {/*返回按钮结束*/}

                    {/*居中文字开始*/}
                    <div className="center-word">
                        <div className="top-word">
                            {name}
                        </div>
                        <div className="txt-pig">
                            <img src={note} alt="note" className="note" style={{display: state, width: 26, height: 26}}
                                 onClick={props.onClick}
                            />
                        </div>


                    </div>
                    {/*居中文字结束*/}
                </div>
            </div>
        </Wrapper>
    )
}

export default Head;
