import React, {CSSProperties, useEffect, useState} from "react";
import {Badge, Input} from 'antd';
import UniversalButton from "../../../../components/UniversalButton";
import Close from "../../../../static/img/NotesWindow/Close.svg"
import {Wrapper} from "./style";
import {color} from "../../../../static/color/color";
import {TLRAEnum} from "../../../../enum/field/field";
import {somEngineerServiceRequestTlraAudit_insert} from "../../../../api/TLRA";
import {useNavigate} from "react-router-dom";
import {MessageInstance} from "antd/es/message/interface";


interface Prop {
    name: string
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    setForbidden: React.Dispatch<React.SetStateAction<string>>;
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>;
    tlraID: string;
    setSpinning: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi:MessageInstance;
}

/**
 * 按钮样式
 */
const buttonStyle: CSSProperties = {
    width: "150px",
    height: "36px",
    fontFamily: "SourceHanSansCN-Normal",
    background: `${color.button}`,
}
const buttonStyleForbidden: CSSProperties = {
    width: "150px",
    height: "36px",
    fontFamily: "SourceHanSansCN-Normal",
    background: `${color.confirmForbidden}`,
    color: `${color.user}`,
}

const {TextArea} = Input;

const NotesWindow = (props: Prop) => {

    const history = useNavigate();

    // setDisabled控制弹窗是否出现
    const {
        name, setDisabled, setForbidden, value, setValue, tlraID,
        setSpinning,messageApi
    } = props;
    // disabled控制按钮是否可以点击
    const [disabled, setThisDisabled] = useState(false);
    const [btStyle, setBtStyle] = useState(buttonStyle);
    useEffect(() => {
        // 如果是拒绝按钮点开的则确认按钮初始状态为无法点击
        if (name === TLRAEnum.REFUSE_REASONS) {
            setThisDisabled(true);
            setBtStyle(buttonStyleForbidden);
        }

    }, [])
    const submit = () => {
        setSpinning(true);
        const param = {
            // approver:getUser().eid,
            approveOrReject: name !== TLRAEnum.REFUSE_REASONS,
            comments: value === "" ? "" : value,
            tlraID: tlraID,
        }
        const error = () => {
            messageApi.open({
                type: 'error',
                content: TLRAEnum.NETWORK_ERROR,
            });
            setSpinning(false);
        };
        // 七秒后如果还没有请求成功则提示错误消息
        setTimeout(error,7000);
        somEngineerServiceRequestTlraAudit_insert(param).then(res => {
            if (res !== "Error") {
                setSpinning(false);
                history('/Approval');
            }
        })
    }
    return (
        <Wrapper>
            <div className="window">
                <div className="header-window">
                    <div className="close">
                        <img src={Close} alt=""
                             onClick={() => {
                                 setDisabled(false);
                                 setForbidden("");
                                 setValue("");
                             }}
                             style={{margin: 7}}
                        />
                    </div>

                    <div className="title">
                        {TLRAEnum.NOTE}
                    </div>

                </div>

                <div className="body-window">
                    <div className="sub-title">{name}
                        {name === TLRAEnum.REFUSE_REASONS ? <Badge dot color="#BE271C" offset={[2, 5]}/> :
                            <span>&nbsp;&nbsp;&nbsp;({TLRAEnum.NOR_REQUIRED})</span>}
                    </div>
                    <div className="text-area">
                        <TextArea
                            value={value}
                            onChange={
                                (e) => {
                                    // 内容为空则不可点击
                                    if (name === TLRAEnum.REFUSE_REASONS) {
                                        setThisDisabled(e.target.value === "");
                                        if (!(e.target.value === "")) {
                                            setBtStyle(buttonStyle);
                                        } else setBtStyle(buttonStyleForbidden);
                                    }
                                    setValue(e.target.value);
                                }
                            }
                            placeholder={TLRAEnum.TEXT_PLACEHOLDER}
                            autoSize={{minRows: 3, maxRows: 5}}
                        />
                    </div>
                </div>
                <div className="footer-window">
                    <UniversalButton
                        name={TLRAEnum.SURE_BUTTON}
                        disabled={disabled}
                        style={btStyle}
                        onClick={() => {
                            setDisabled(false);
                            setForbidden("");
                            submit();
                            setValue("");
                        }}/>
                </div>
            </div>
        </Wrapper>
    )
}
export default NotesWindow;
