import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`
  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;

  hr {
    height: 1px;
    width: 100%;
    margin-top: 0;
    background-color: ${color.br};
    border: none;
  }

  .approval-root {
    height: 6.875%;
    width: 100%;
  }

  .approval-heard {
    padding-top: 10px;
    height: 33px;
    line-height: 33px;
    display: flex;
  }

  .return-button {
    height: 100%;
    width: 10.66%;
    display: inline-block;
    float: left;
  }

  .return-pic {
    padding-top: 4px;
  }

  .center-word {
    height: 100%;
    width: 79.33%;
    display: flex;
    margin-right: 10.66%;
    justify-content: center;
  }

  .top-word {
    font-size: 1rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .txt-pig {
    margin-left: 2%;
    height: 30px;
    align-items: center;
    display: flex;
  }

  @media (max-height: 1000px) and (max-width: 700px) and (min-height: 1000px) and (min-width: 700px) {

    hr {
      height: 1px;
      width: 100%;
      margin-top: 0;
      background-color: ${color.br};
      border: none;
    }


    .note {
      margin-top: 2%;
    }

    .approval {
      width: 100vw;
    }

    .approval-heard {
      margin-top: 20px;
      height: 100%;
    }

    .return-button {
      height: 100%;
      width: 10.66vw;
      display: inline-block;
      float: left;
    }

    .return-pic {
      margin: 0
    }

    .center-word {
      height: 100%;
      width: 89.33vw;
      display: inline-block;
      float: left;
    }

    .top-word {
      height: 100%;
      margin: 0 10.66vw 0 0;
      font-size: 1rem;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .txt-pig {
      margin-left: 2%;
      display: inline-block;
    }
  }
  
  
  @media (max-width: 300px) {
    .approval-heard {
      height: 220%;
    }

    .center-word {
      height: 100%;
      width: 69.33vw;
      float: left;
      margin-left: 4vw;
    }

  }

  @media (width: 280px) {
    .approval-heard {
      height: 220%;
    }

    .center-word {
      height: 100%;
      width: 79.33%;
      float: left;
      margin-left: 4vw;
    }

  }
`;

export {Wrapper};
