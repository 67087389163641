import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import {color} from "../../../../static/color/color";
import {LMRAEnum} from "../../../../enum/field/field";
import {ConfigProvider, message, Radio, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import store from "store";
import {useParams} from "react-router-dom";
import {somEsrJobReview_insert} from "../../../../api/LMRA/LMRA";

interface Prop {
    /**
     * 传入状态
     */
    state: number;

    /**
     * 点击按钮点击状态
     */
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setStepFiveTime: React.Dispatch<React.SetStateAction<string>>;
    request: boolean;
    setRequest: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: (status: number, firstHandler: () => void, secondHandler: () => void) => void;
    setIsSpin: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PartFive(props: Prop) {

    /*设置页面禁止点击*/
    const [noClick, setNoclick] = useState(true);
    /*radio选择值*/
    const [radioValue, setRadioValue] = useState(false)
    /*警告输入框是否为红色*/
    const [orRed, setOrRed] = useState("")
    /*是否展开隐藏输入框*/
    const [is, setIs] = useState(false)
    /*获取后端radio状态值*/
    const [radio, setRadio] = useState(false)
    /*第一个输入框*/
    const [firstInput, setFirstInput] = useState("")
    /*第二个输入框*/
    const [secondInput, setSecondInput] = useState("")
    /*第三个输入框*/
    const [thirdInput, setThirdInput] = useState("")
    /*获取全局data的值*/
    const data = store.get("LMRA");
    /*获取jobId*/
    const {jobId} = useParams();

    /*第一个输入框的内容*/
    const onChangeOne = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFirstInput(e.target.value)
    };

    /*第二个输入框的内容*/
    const onChangeRed = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOrRed(e.target.value)
        setSecondInput(e.target.value)
    };

    /*第三个输入框的内容*/
    const onChangeTwo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setThirdInput(e.target.value)
    };

    /*监听state触发post请求*/
    useEffect(() => {
        if (props.state === 5 && props.request) {
            const param = {
                jobId: jobId,
                afollowUpRequired: radioValue,
                followUpRequiredDesc: secondInput,
                unexpectedHazardsDesc: firstInput,
                otherComments: thirdInput
            }
            somEsrJobReview_insert(param).then(res => {
                if (res !== "Error") {
                    props.refresh(res.data.status, () => {
                        message.error("该步骤已提交。");
                    }, () => {
                        props.setRequest(false);
                    })
                }
            })
        }

        /*点击按钮后设置禁用状态*/
        if (props.state >= 5) {
            setNoclick(false)
        }

    }, [props.state])
    /*监听获取数据库的值*/
    useEffect(() => {
        if (props.state >= 5) {
            if (data) {
                const data = store.get("LMRA");
                const somEsrJobReviewResponse = data.somEsrJobReviewResponse;
                if (somEsrJobReviewResponse) {
                    if (somEsrJobReviewResponse.unexpectedHazardsDesc) {
                        setFirstInput(somEsrJobReviewResponse.unexpectedHazardsDesc);
                    } else {
                        setFirstInput("");
                        setOrRed("");
                    }
                    if (somEsrJobReviewResponse.aFollowUpRequired) {
                        setSecondInput(somEsrJobReviewResponse.followUpRequiredDesc);
                        setOrRed(somEsrJobReviewResponse.followUpRequiredDesc);
                        setIs(true);
                        setRadio(true);
                    } else {
                        setIs(false);
                        setRadio(false);
                        setSecondInput("");
                        setOrRed("");
                    }
                    if (somEsrJobReviewResponse.otherComments) {
                        setThirdInput(somEsrJobReviewResponse.otherComments);
                    } else {
                        setThirdInput("");
                    }
                }
            }
        }
    }, [data])

    /*判断输入条件*/
    useEffect(() => {
        if (props.state === 4) {
            if (radioValue) {
                props.setIsClicked(secondInput.length === 0)
            } else {
                props.setIsClicked(false)
            }
        }

    }, [radioValue, secondInput])

    return (
        <Wrapper>
            {/*整体盒子*/}
            <div className="partFive-root" style={{pointerEvents: noClick ? "unset" : "none"}}>
                {/*第一个输入框*/}
                <div>
                    {/*上方文字*/}
                    <div className="wordOne">
                        {LMRAEnum.TXT_ONE}
                    </div>
                    {/*输入框*/}
                    <div className="txtOne">
                        <TextArea placeholder={noClick ? LMRAEnum.INPUT : undefined} showCount maxLength={225}
                                  onChange={onChangeOne}
                                  value={firstInput !== "" ? firstInput : undefined}
                                  style={{height: 108, resize: 'none'}}/>
                    </div>
                </div>
                {/*单选按钮行*/}
                <div className="identifying">
                    <div className="WAP" style={{color: color.risk, width: "74.34vw"}}>
                        {/*文字*/}
                        <span className="yellowWord">
                            {LMRAEnum.FIVE_RADIO}
                        </span>
                    </div>

                    {/*单选框*/}
                    <ConfigProvider
                        theme={{
                            components: {
                                Radio: {
                                    dotSize: 9,
                                },
                            },
                        }}
                    >
                        <Space>
                            <Radio.Group
                                defaultValue={LMRAEnum.YELLOW_NO}
                                value={radio || is ? LMRAEnum.YELLOW_YES : LMRAEnum.YELLOW_NO}
                                rootClassName={noClick ? "edit" : "view"}
                            >
                                <div style={{width: "25.66vw"}} className="radio">
                            <span style={{marginRight: "20px"}}>
                                {/*是*/}
                                <Radio onClick={() => {
                                    setIs(true)
                                    setRadioValue(true)
                                }}
                                       value={LMRAEnum.YELLOW_YES} className="RadioOne"
                                       style={{float: "right", fontWeight: "400"}}>{LMRAEnum.YELLOW_YES}</Radio>
                            </span>
                                    {/*否*/}
                                    <span style={{marginRight: "20px"}}>
                                <Radio onClick={() => {
                                    setIs(false)
                                    setRadioValue(false)
                                }}
                                       value={LMRAEnum.YELLOW_NO} className="RadioTwo"
                                       style={{float: "right", fontWeight: "400"}}>{LMRAEnum.YELLOW_NO}</Radio>
                            </span>
                                </div>
                            </Radio.Group>
                        </Space>
                    </ConfigProvider>
                </div>
                {/*第二个输入框*/}
                <div style={{display: is ? "block" : "none"}}>
                    <div className="wordRed">
                        {/*文字*/}
                        <span className="Word">
                             {LMRAEnum.TXT_RED}
                        </span>
                        {/*图片*/}
                        <span className="Pic" style={{display: orRed !== "" ? "none" : "block"}}/>
                    </div>
                    <div className="txtRed">
                        <TextArea placeholder={noClick ? LMRAEnum.INPUT : undefined} showCount maxLength={225}
                                  style={{
                                      borderColor: orRed !== "" ? color.tlraFile : !noClick ? undefined : color.redRound,
                                      height: 108,
                                      resize: 'none'
                                  }}
                                  onChange={onChangeRed} value={secondInput !== "" ? secondInput : undefined}/>
                    </div>
                </div>
                {/*第三个输入框*/}
                <div style={{marginTop: "10px"}}>
                    {/*文字*/}
                    <div className="wordTwo">
                        {LMRAEnum.TXT_TWO}
                    </div>
                    {/*文本框*/}
                    <div className="txtTwo">
                        <TextArea placeholder={noClick ? LMRAEnum.INPUT : undefined} showCount maxLength={225}
                                  onChange={onChangeTwo}
                                  value={thirdInput !== "" ? thirdInput : undefined}
                                  style={{height: 108, resize: 'none'}}/>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}


