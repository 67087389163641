import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`
  width: 28px;
  height: 17px;
  text-align: center;
  margin-left: 4px;
  color: ${color.risk};
  line-height: 17px;

  .risk-name {
    padding-top: 2px;
  }
`;

export {Wrapper}
