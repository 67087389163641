import styled from "styled-components";
import {color} from "../../../../static/color/color";
import {Input, Radio} from "antd";


const Wrapper = styled.div`
  font-size: 12px;
  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
`;

const BodyWrapper = styled.div`
  height: 350pt;
`
const TimeWrapper = styled.div`
  margin-bottom: 30pt;
`
const TimeArea = styled.div`
  width: 170px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: -30px;
  border: solid ${color.tlraFile} 1px;
`
const TimeIcon = styled.img`
  width: 15px;
  height: 15px;
  float: right;
  margin-top: 6px;
  margin-right: 6px;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const LittleTile = styled.div`
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  box-sizing: border-box;
  padding-top: 1px;
`
const TextArea = styled(Input.TextArea)`
  margin-top: 4px;
  height: 8rem;
  margin-bottom: 30pt;
  border-radius: 0;
`
const RadioGroup = styled(Radio.Group)`
  float: right;
`
const RadioGroupFirst = styled(Radio.Group)`
  float: left;
    .ant-radio-wrapper {
        margin-inline-end:47px;
    }
`
const Choose = styled.div`
  font-size: 14px;
  font-weight: 500;
  height: 40pt;

  .title {
    float: left;
    padding-top: 1px;
  }

  .edit .ant-radio-inner {
    border: 2px solid ${color.risk};
  }

  .view .ant-radio-inner {
    border: 2px solid ${color.tlraFile};
  }

  .view .ant-radio-checked .ant-radio-inner{
    border-color: #d9d9d9;
  }

  .ant-radio-inner::after {
    background-color: ${color.button};
  }

  .ant-radio-checked {
    border-color: unset;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${color.risk};
    background-color: unset;
  }

  .ant-radio-wrapper .ant-radio-inner:hover {
    border-color: ${color.risk};
  }

  .ant-radio-wrapper {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-group {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-input {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-wrapper .ant-radio-checked::after {
    border: unset;
  }

  .ant-input-affix-wrapper {
    margin-bottom: 16px;
  }

  .ant-space, .css-dev-only-do-not-override-20qpnr, .ant-space-horizontal, .ant-space-align-center {
    width: 100%;
  }

  .ymdua .ant-space-horizontal, .ymdua .ant-space-align-center {
    width: 100%;
  }

  .fQLnPR .ant-space-horizontal, .fQLnPR .ant-space-align-center {
    width: 0;
  }

  .ant-space-item {
    width: 100%;
  }

  .radio > span > span {
    margin-top: 1px;
  }
`
const RedPoint = styled.span`
  width: 6pt;
  height: 6pt;
  border-radius: 50%;
  background-color: ${color.R4};
  display: inline-block;
  margin-left: 7pt;
`
export {
    Wrapper,
    BodyWrapper,
    TimeWrapper,
    TextArea,
    RadioGroup,
    RadioGroupFirst,
    Choose,
    RedPoint,
    LittleTile,
    TimeArea,
    TimeIcon,
    TitleWrapper,
};
