import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import {color} from "../../../../static/color/color";

interface prop {
    /**
     * 传入编号
     */
    index: string

    /**
     * 显示提示文字
     */
    prompt: string

    /**
     * 显示圆框颜色
     */
    Color: boolean
}

const Prompt = (props: prop) => {

    const {index, prompt, Color} = props

    const [changeColor, setChangeColor] = useState(false);


    useEffect(() => {
        setChangeColor(Color);
    }, [Color])

    return (
        <Wrapper>
            {/*提示大盒子*/}
            <div className="prompt-root">
                {/*左边图片*/}
                <div className="left-pig">
                    <div className="pig" style={changeColor ? {
                            color: color.user,
                            background: color.blueRound,
                            border: ' 2px ' + 'solid ' + color.blueRound
                        }
                        : {color: color.redRound, background: color.user, border: '2px' + 'solid' + color.redRound}}>
                        <div className="pig-txt">
                            {index}
                        </div>
                    </div>
                </div>
                {/*右边文本*/}
                <div className="right-txt">
                    <div className="txt">
                        {prompt}
                    </div>
                </div>
                {/*空白填充*/}
                <div className="blank">
                </div>
            </div>
        </Wrapper>

    );
}

export default Prompt;
