import React, {CSSProperties, useEffect, /*useRef,*/ useState} from "react";
import {Wrapper} from "./style";
import Head from "../../components/Head";
import {ServerEnum, TLRAEnum} from "../../enum/field/field";
import {Collapse, CollapseProps, Spin} from "antd";
import Prompt from "./components/Prompt/Prompt";
import HoneywellRequest from "./components/HoneywellRequset";
import AddLMRA from "./components/AddLMRA";
import UniversalButton from "../../components/UniversalButton";
import DepartureDate from "./components/DepartureDate";
import PubSub from "pubsub-js";
import Block from "../../components/Block";
import {
    somEngineerServiceRequest_get,
    somEngineerServiceRequest_lmraSubmit,
    somEngineerServiceRequest_update,
} from "../../api/Server";
import Waring from "./components/Waring";
import {getName, getUser} from "../../utils/RememberPassword/storageUtils";
import RiskIdentification from "./components/RiskIdentification/RiskIdentification";
import {color} from "../../static/color/color";
import * as store from "store";

interface File {
    time: string,
    timeNumber: number;
    jobId: string,
    serviceRequestId: string
    probability: string,
    severity: string,
    stepIndex: number,
    aPhysicalConditionFitWork:boolean,
}

interface getFile {
    fileName: string,
    jobId: string,
    jobTime: number,
    serviceRequestId: string,
    probability: string,
    severity: string,
    stepIndex: number,
    aPhysicalConditionFitWork:boolean,
}

const Service = () => {
    // 缓存取值
    const {projectNo, estEntryDate, projectDesc, serviceRequestId} = store.get("ServiceItem");

    const [danger, setDanger] = useState("R1");
    const [array, setArray] = useState<File[]>();

    const [state, setState] = useState("");
    const [openButton, setOpenButton] = useState(true);
    const [colorOne, setColorOne] = useState(false);
    const [colorFour, setColorFour] = useState(false);
    const [colorFive, setColorFive] = useState(false);
    const [colorSix, setColorSix] = useState(false);

    const [display, setDisplay] = useState(false);
    const [riskCfm,/*setRiskCfm*/] = useState(true);

    // 离场服务日期
    const [departureTime, setDepartureTime] = useState(new Date());

    const [changeState, setChangeState] = useState(false);

    const [customerHigherHoney, setCustomerHigherHoney] = useState(false);

    const [switchOne, setSwitchOne] = useState(true);
    const [switchTwo, setSwitchTwo] = useState(true);
    const [after, setAfter] = useState("");
    const [negation, setNegation] = useState(false);

    const [all, setAll] = useState(0);

    const [spin, setSpin] = useState(false);
    const [spinAll, setSpinAll] = useState(true);
    const [counter, setCounter] = useState(0);

    // const mask = useRef(null);
    const getDepartureTime = (time: Date) => {
        setDepartureTime(time);
    }
    const buttonStyle: CSSProperties = {
        width: "100%",
        height: "38.4px",
        margin: "0 1rem 1rem 1rem",
        backgroundColor: color.button,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }
    const buttonForbiddenStyle: CSSProperties = {
        width: "100%",
        height: "38.4px",
        margin: "0 1rem 1rem 1rem",
        backgroundColor: color.dontClick,
        color: color.user,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }
    const font: CSSProperties = {
        fontFamily: "HoneywellSansWeb-Medium ,SourceHanSansCN-Normal, sans-serif",
    }

    const data = new Date(Number(estEntryDate));
    const Data = data.getFullYear() + "/" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "/" +
        (data.getDate() < 10 ? "0" + data.getDate() : data.getDate())

    PubSub.subscribe("callback", () => {
        PubSub.publish("callBack", serviceRequestId)
    })

    PubSub.subscribe('one', (_, data) => {
        setOpenButton(data);
        setState('one');
    })

    PubSub.subscribe('four', (_, data) => {
        setOpenButton(data);
        setState('four');
    })

    PubSub.subscribe('five', (_, data) => {
        setOpenButton(data);
        setState('five');
    })

    PubSub.subscribe('fiveDelete', () => {
        /*checkRiskFirst()*/
    })

    PubSub.subscribe('antdFive', () => {
        setState('Five')
    })


    PubSub.subscribe('six', (_, data) => {
        setOpenButton(data);
        setState('six');
    })

    PubSub.subscribe("YON", (_, data) => {
        if (data === "是") {
            setCustomerHigherHoney(true);
        } else if (data === "否") {
            setCustomerHigherHoney(false);
        }
    })


    const genExtra = (index: string, prompt: string, Color: boolean) => (
        <Prompt index={index} prompt={prompt} Color={Color}/>
    );

    const errorItems: CollapseProps['items'] = [
        {
            key: '1',
            children: <RiskIdentification itemNumber={projectNo !== undefined ? projectNo : ""}
                                          projectContent={projectDesc !== undefined ? projectDesc : ""}/>,
            extra: genExtra('1', ServerEnum.RISK_CONFIRM, colorOne)
        },
    ];

    // state表示是否高于霍尼韦尔要求
    const Four = (colorFour: boolean, prop?: { state: boolean, time: number, start: boolean }) => {
        return (
            {
                key: '1',
                children: <HoneywellRequest props={prop}/>,
                extra: genExtra('3', ServerEnum.HIGH_REQUIREMENT, colorFour)
            }
        )
    };

    // state控制AddLMRA内容状态
    const Five = (colorFive: boolean, Time?: number, prop?: { state: boolean, array: File[], time: number }) => {
        return (
            {
                key: '2',
                children: <AddLMRA Props={prop} Time={Time} onClick={() => {
                    checkRiskFirst()
                }}/>,
                extra: genExtra('4', ServerEnum.SUBMIT_TLRA, colorFive)
            }
        )
    };

    const Six = (colorSix: boolean, Time?: number) => {
        return (
            {
                key: '3',
                children: <DepartureDate getDepartureTime={getDepartureTime} Time={Time}/>,
                extra: genExtra('5', ServerEnum.LEAVE_TIME, colorSix)
            }
        )
    };

    const [select, setSelect] = useState<Array<any>>([Four(colorFour)]);
    const items: CollapseProps['items'] = select;

    const submit_four = () => {
        const param = {
            eid: getUser().eid,
            projectNo: projectNo,
            serviceRequestId: serviceRequestId,
            estEntryDate: Number(estEntryDate),
            customerHigherHoneywell: customerHigherHoney,
        }
        somEngineerServiceRequest_update(param).then(res => {
            if (res !== "Error") {
                if (counter < 1) {
                    checkRiskFirst()
                    setAfter("12")
                }
            }
        })
    }

    const submit_six = () => {
        const param = {
            eid: getUser().eid,
            projectNo: projectNo,
            serviceRequestId: serviceRequestId,
            estEntryDate: Number(estEntryDate),
            deptDate: departureTime.getTime(),
            riskCfm: riskCfm,
        }
        somEngineerServiceRequest_update(param).then(() => {
            setColorSix(true)
        })
    }

    const riskConfirm = () => {
        const param = {
            eid: getUser().eid,
            projectNo: projectNo,
            serviceRequestId: serviceRequestId,
            estEntryDate: Number(estEntryDate),
            riskCfm: true,
        }
        somEngineerServiceRequest_update(param).then(res => {
            if (res != "Error")
                setChangeState(false);
            setCounter(0)
            setAfter("55")
        })
    }

    const confirm = () => {
        if (state === 'four') {
            PubSub.publish('returnFour', true);
            setColorFour(true);
            setState("Four");
            setOpenButton(false)
            setSwitchOne(false)
            setSpinAll(true)
            submit_four();
        } else if (state === 'five') {
            setDisplay(true);
        } else if (state === 'six') {
            PubSub.publish('returnSix', true)
            PubSub.publish('colorSix', true);
            setState("Six");
            setColorSix(true);
            submit_six();
        } else if (state === 'one') {
            PubSub.publish('returnOne', true);
            setColorOne(true);
            setState("One");
            setChangeState(false);
            setOpenButton(true);
            riskConfirm();
        }
    }

    useEffect(() => {
        if (state === 'One') {
            setColorOne(false);
        } else if (state === 'Six') {
            setColorFive(false);
            setColorFour(false);
        }
        return (
            () => {
                setColorFive(false);
                setColorFour(false);
            }
        );
    }, [confirm])

    useEffect(() => {
        if (state === 'Five') {
            const params = {
                serviceRequestId: serviceRequestId
            }
            somEngineerServiceRequest_lmraSubmit(params).then(res => {
                if (res !== "Error") {
                    if (counter < 1) {
                        checkRiskFirst();
                        setAfter("44")
                    }
                }
            })
            PubSub.publish('returnFive', true);
            PubSub.publish('close', false);
            setOpenButton(true);
            setColorFive(true);
            setSpinAll(true)
        }
    }, [state])

    //打开正在填写组件，销毁已经填写组件
    const [collapse, setCollapse] = useState(
        <div>
            <Collapse defaultActiveKey={['1']} ghost expandIconPosition={"end"}
                      items={changeState ? errorItems : items} style={font}/>
        </div>
    );

    useEffect(() => {
        if (switchOne) {
            if (switchTwo) {
                setCollapse(
                    <div>
                        <Collapse defaultActiveKey={['1']} ghost expandIconPosition={"end"}
                                  items={changeState ? errorItems : items} style={font}/>
                    </div>
                )
            } else {
                setSpin(true)
                setCollapse(
                    <div>
                        <Collapse defaultActiveKey={['3']} ghost expandIconPosition={"end"}
                                  items={changeState ? errorItems : items} style={font}/>
                    </div>
                )
                setTimeout(() => {
                    setSpin(false)
                }, 1000)
            }
        } else {
            setSpin(true)
            setCollapse(
                <div>
                    <Collapse defaultActiveKey={['2']} ghost expandIconPosition={"end"}
                              items={changeState ? errorItems : items} style={font}/>
                </div>
            )
            setTimeout(() => {
                setSpin(false)
            }, 1000)
        }
    }, [projectNo, after, negation])

    useEffect(() => {
        if (state === "Six") {
            setColorSix(true)
        }
    }, [])

    useEffect(() => {
        if (items.length === 3) {
            items[2] = Six(colorSix, Number(estEntryDate))
            setCollapse(
                <div>
                    <Collapse defaultActiveKey={['3']} ghost expandIconPosition={"end"}
                              items={changeState ? errorItems : items} style={font}/>
                </div>
            )
        }
    }, [colorSix])

    useEffect(() => {
        if (changeState) {
            setCollapse(
                <div>
                    <Collapse defaultActiveKey={['1']} ghost expandIconPosition={"end"}
                              items={errorItems} style={font}/>
                </div>
            )
        }
    }, [changeState])

    useEffect(() => {
        if (!changeState) {
            setColorOne(false)
        }
    }, [changeState])

    useEffect(() => {

        if (array?.length !== 0 && array !== undefined) {
            if (all === array.length) {
                setOpenButton(false)
                setState("five")
            } else {
                setOpenButton(true)
            }
        } else {
            setOpenButton(true)
        }
    }, [array, all])
    useEffect(() => {
        }, []);
    // 检测风险等级是否变化并加载数据
    function checkRiskFirst() {
        if (serviceRequestId === undefined) return;
        somEngineerServiceRequest_get(serviceRequestId).then(res => {
            if (res != "Error") {
                if (!res.data.data.riskCfm) {
                    setDanger(res.data.data.contractRisk);
                    setChangeState(true);
                    setCounter(1)
                    setSpinAll(false)
                } else {
                    setDanger(res.data.data.contractRisk);
                    // 为true则5已完成
                    if (res.data.data.lmraTime) {
                        const Fileds: File[] = [];
                        setOpenButton(true);
                        setState("FiveAndSix");
                        res.data.data.somEngineerServiceRequestJobResponses.map((it: getFile) => {
                            const data = new Date(it.jobTime);
                            const Data = data.getFullYear() + "-" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "-" +
                                (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
                                (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
                                (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes())
                            Fileds.push({
                                time: Data,
                                timeNumber: it.jobTime,
                                jobId: it.jobId,
                                serviceRequestId: it.serviceRequestId,
                                probability: it.probability,
                                severity: it.severity,
                                stepIndex: it.stepIndex,
                                aPhysicalConditionFitWork:it.aPhysicalConditionFitWork
                            })
                        })
                        setSelect([Four(true, {
                            state: res.data.data.customerHigherHoneywell,
                            time: res.data.data.customerHigherHoneywellTime,
                            start: true
                        }), Five(true, 0, {
                            state: true,
                            array: Fileds,
                            time: res.data.data.lmraTime
                        }), Six(colorSix, Number(estEntryDate))]);
                        setSwitchOne(true)
                        setSwitchTwo(false)
                        setNegation(!negation)
                        setSpinAll(false)
                    } else if (res.data.data.somEngineerServiceRequestJobResponses.length !== 0) {
                        let All = 0;
                        const Fileds: File[] = [];
                        res.data.data.somEngineerServiceRequestJobResponses.map((it: getFile) => {
                            const data = new Date(it.jobTime);
                            const Data = data.getFullYear() + "-" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "-" +
                                (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
                                (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
                                (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes())
                            Fileds.push({
                                time: Data,
                                timeNumber: it.jobTime,
                                jobId: it.jobId,
                                serviceRequestId: it.serviceRequestId,
                                probability: it.probability,
                                severity: it.severity,
                                stepIndex: it.stepIndex,
                                aPhysicalConditionFitWork:it.aPhysicalConditionFitWork
                            })
                            if ((it.probability === "H" && it.severity === "H") || (it.probability === "H" &&
                                it.severity === "M") || (it.probability === "M" && it.severity === "H")) {
                                if (it.stepIndex === 4){
                                    All += 1;
                                }
                            } else if (!it.aPhysicalConditionFitWork){
                                if (it.stepIndex === 1){
                                    All += 1;
                                }
                            }else if (it.stepIndex === 5){
                                All += 1;
                            }

                            /* setArray(Fileds)*/
                        })
                        setSelect([Four(true, {
                            state: res.data.data.customerHigherHoneywell,
                            time: res.data.data.customerHigherHoneywellTime,
                            start: true
                        }), Five(colorFive, 0, {
                            state: false,
                            array: Fileds,
                            time: res.data.data.lmraTime
                        })]);
                        setSwitchOne(false)
                        setState("five");
                        /*setOpenButton(false);*/
                        setAfter("1");
                        setArray(Fileds)
                        setAll(All)
                        setSpinAll(false)
                    }
                    // 进入说明5未完成4完成
                    else if (res.data.data.customerHigherHoneywellTime != null) {
                        // 控制按钮文字
                        setState("Four");
                        setOpenButton(true);
                        setSelect([Four(true, {
                            state: res.data.data.customerHigherHoneywell,
                            time: res.data.data.customerHigherHoneywellTime,
                            start: true
                        }), Five(colorFive)]);
                        setSwitchOne(false)
                        setNegation(!negation)
                        setOpenButton(false)
                        setState("five");
                        setSpinAll(false)
                    } else {
                        setSpinAll(false)
                    }
                }
            }
        })
    }
    useEffect(() => {
        setTimeout(() => {
            checkRiskFirst()
        }, 50)
    }, [projectNo, changeState])

    return (
        <Wrapper>
            {display && <Waring setDisplay={setDisplay}/>}
            {display && <div className="mask"></div>}
            {spinAll ? <div>
                    <Spin tip="Loading..." className="SpinAll">
                    </Spin>
                </div>
                :
                <div>
                    <div>
                        <div className="all forbidden" >
                            <Head
                                name={projectDesc && projectNo !== undefined ? projectNo + "       " + projectDesc : ""}
                                state={"none"}
                                Return={'/fieldServer'}
                            />
                            <div className="body-scoller">
                                <div className="header">
                                    <div className="line-one">
                                        <div className="project-message project-num ">
                                            {TLRAEnum.PROJECT}{projectNo}<Block name={danger}/>
                                        </div>
                                        <div className="expected-entry">{TLRAEnum.ENTRANCE}{Data}</div>
                                    </div>
                                    <div
                                        className="project-message project-description">{ServerEnum.PROJECT_DESCRIPTION}
                                        {projectDesc}
                                    </div>
                                </div>
                                <div className="body-service">
                                    <div className="body-head">
                                        <span className="head-left">{ServerEnum.HEAD_LEFT}</span>
                                        <span className="head-right">{getUser().eid}({getName()})</span>
                                    </div>
                                    {spin ? <div>
                                            <Spin tip="Loading..." className="Spin">
                                            </Spin>
                                        </div>
                                        : collapse}
                                </div>
                            </div>
                            {state === 'Six'
                                ? <div className="thanks">
                                    {ServerEnum.THANK}
                                </div>
                                : ""}
                        </div>
                        {state === 'Six'
                            ? <div className="footer-service"/>
                            : <div className="footer-service">
                                <UniversalButton
                                    name={state === 'Four' || state === 'five' || state === 'Five' ? ServerEnum.FINISH : ServerEnum.SUBMIT}
                                    disabled={openButton}
                                    style={openButton ? buttonForbiddenStyle : buttonStyle}
                                    onClick={() => {
                                        confirm();
                                    }}
                                />
                            </div>
                        }
                    </div>


                </div>
            }
        </Wrapper>
    )
}
export default Service;
