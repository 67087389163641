import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`

  color: ${color.itemTitle};
  font-size: 12px;
  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;

  .spin .ant-spin .ant-spin-dot{
    margin-top: 70%;
    margin-left: 50vh;
  }

  .total {
    position: relative;
    z-index: 1;
  }

  .onePop {
    z-index: 20;
    position: fixed;
    top: 70%;
    left: 7%;
  }

  .twoPop {
    z-index: 20;
    position: fixed;
    top: 70%;
    left: 7%;
  }

  .cover {
    /*margin-top: -100vw;*/
    width: 100%;
    height: 100vh;
    z-index: 15;
    position: fixed;
    top: 0;
    background-color: ${color.prohibitClicking};
  }

  .body, .header {
    margin: 0 12px;
  }

  .top {
    height: 11vh;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  .line {
    height: 30px;
    margin: 0 12px;
    background-color: ${color.user};
  }

  .approval-heard {
    background-color: ${color.button};
    color: ${color.user};
  }

  .line-one, .project-num, .submit-person {
    display: flex;
  }

  .line-one {
    justify-content: space-between;
  }

  .line-one, .project-description {
    margin-bottom: 8px;
  }

  .ant-collapse-small > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .scoller {
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: 83vh;
  }

  .scoller::-webkit-scrollbar {
    display: none;
  }
  

  .footer {
    height: 6vh;
    display: flex;
    justify-content: center;
    //position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${color.user};
    box-sizing: border-box;
    padding: 0 1rem 1rem;
  }
`;
export {Wrapper};