import store from 'store';

const HEADER = 'header'

//保存
export const saveHeader = (header: object) => {
    const oldHeader = store.get(HEADER);
    // 存储请求头
    store.set(HEADER, {...oldHeader, ...header})
}
//读取
export const getHeader = () => {
    // 获取请求头
    return store.get(HEADER) || {}
}
//删除
export const deleteHeader = () => {
    // 移除请求头
    store.remove(HEADER)
}


