import {Wrapper} from "./style";
import React, {useEffect, useState} from "react";
import {ConfigProvider, Input, message, Radio, Space} from 'antd';
import {LMRAEnum} from "../../../../enum/field/field";
import {color} from "../../../../static/color/color";
import * as store from "store";
import {somEsrJobAssessment_insert} from "../../../../api/LMRA/LMRA";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";

const {TextArea} = Input;

interface Prop {
    /**
     * 传入状态
     */
    state: number;

    /**
     * 点击后的回调函数
     */
    onClick: () => void;

    /**
     * 点击按钮点击状态
     */
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setStepThreeTime: React.Dispatch<React.SetStateAction<string>>;
    request: boolean;
    setRequest: React.Dispatch<React.SetStateAction<boolean>>;

    setSerious: React.Dispatch<React.SetStateAction<boolean>>;
    refresh: (status: number, firstHandler: () => void, secondHandler: () => void) => void;
    setIsSpin: React.Dispatch<React.SetStateAction<boolean>>;

}

const PartThree = (props: Prop) => {
    /*设置页面禁止点击*/
    const [noClick, setNoclick] = useState(true);
    /*可能性和危险性的状态*/
    const [seriousness, setSeriousness] = useState("");
    const [seriousnessValue, setSeriousnessValue] = useState("");
    const [possibility, setPossibility] = useState("");
    const [possibilityValue, setPossibilityValue] = useState("");
    /*黄色危险等级的值  可修改其内容，调接口前无意义*/
    const [three, setThree] = useState("");
    /*渲染三色危险等级的界面的useState*/
    const [error, setError] = useState(
        <a></a>
    );
    /*输入框的值*/
    const [redInput, setRedInput] = useState("");
    const [yellowInput, setYellowInput] = useState("");
    /*获取全局data的值*/
    const data = store.get("LMRA");
    const somEsrJobAssessmentResponse = data.somEsrJobAssessmentResponse;
    /*获取jobId*/
    const {jobId} = useParams();

    /*获取输入框的值*/
    const onRedChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRedInput(e.target.value)
    };
    const onYellowChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setYellowInput(e.target.value)
    };

    //按钮状态更改刷新，判断值
    useEffect(() => {
        props.setSerious(false);
        if (possibility === "L" && seriousness === "L") {
            setError(
                <a style={{color: color.risk}}>
                    {LMRAEnum.DANGER_BLUE}
                </a>
            )
            setRedInput("")
            setYellowInput("")
        } else if ((possibility === "H" && seriousness === "H") || (possibility === "M" && seriousness === "H") || (possibility === "H" && seriousness === "M")) {
            props.setSerious(true);
            setError(
                <div><a
                    style={{color: color.risk}}>{LMRAEnum.DANGER}&nbsp;&nbsp;{seriousness}{possibility}&nbsp;&nbsp;{LMRAEnum.HORIZONTAL}&nbsp;&nbsp;
                </a>
                    <a style={{color: color.loginError}}>
                        {LMRAEnum.DANGER_RED}
                    </a>
                </div>
            )
            setYellowInput("")
        } else if ((possibility === "L" && seriousness === "H") || (possibility === "H" && seriousness === "L") || (possibility === "M" && seriousness === "M")
            || (possibility === "M" && seriousness === "L") || (possibility === "L" && seriousness === "M")) {
            setError(
                <a style={{color: color.risk}}>
                    {LMRAEnum.DANGER}&nbsp;&nbsp;{seriousness}{possibility}&nbsp;&nbsp;{LMRAEnum.HORIZONTAL}&nbsp;&nbsp;{LMRAEnum.DANGER_YELLOW}
                </a>
            )
            setRedInput("")
        } else {
            setError(
                <a style={{color: color.risk}}></a>
            )
        }
    }, [seriousness, possibility])

    useEffect(() => {
        if (props.state >= 3) {
            if (somEsrJobAssessmentResponse.probability && somEsrJobAssessmentResponse.severity) {
                setPossibilityValue(somEsrJobAssessmentResponse.probability)
                setSeriousnessValue(somEsrJobAssessmentResponse.severity)
            }
            if (somEsrJobAssessmentResponse.stopWorkDescReasons) {
                setRedInput(somEsrJobAssessmentResponse.stopWorkDescReasons)

            }
            if ( somEsrJobAssessmentResponse.riskControlMeasuresDesc){
                setYellowInput(somEsrJobAssessmentResponse.riskControlMeasuresDesc)
            }
            if (possibilityValue !== "" && seriousnessValue !== "") {
                setPossibility(possibilityValue)
                setSeriousness(seriousnessValue)
            }
        }
    }, [somEsrJobAssessmentResponse])


    useEffect(() => {
        if (props.state === 3 && props.request) {
            const param = {
                jobId: jobId,
                probability: seriousness,
                severity: possibility,
                stopWorkDescReasons: redInput,
                riskControlMeasuresDesc:yellowInput
            }
            somEsrJobAssessment_insert(param).then(res => {
                if (res != "Error") {
                    props.refresh(res.data.status, () => {
                        message.error("该步骤已提交。");
                    }, () => {
                        props.setStepThreeTime(dayjs(res.data.data.updatedAt).format('YYYY-MM-DD HH:mm'));
                        props.setRequest(false);
                    })
                }
            })
        }

        /*点击按钮后设置禁用状态*/
        if (props.state >= 3) {
            setNoclick(false)
        }
    }, [props.state])

    useEffect(() => {
        if (props.state === 2) {
            if (possibility === "L" && seriousness === "L") {
                props.setIsClicked(false)
            } else if ((possibility === "L" && seriousness === "H") || (possibility === "H" && seriousness === "L") || (possibility === "M" && seriousness === "M")
                || (possibility === "M" && seriousness === "L") || (possibility === "L" && seriousness === "M")) {
                if (three === LMRAEnum.YELLOW_YES && yellowInput !== "") {
                    props.setIsClicked(false)
                } else {
                    props.setIsClicked(true)
                }
            } else if ((possibility === "H" && seriousness === "H") || (possibility === "M" && seriousness === "H")
                || (possibility === "H" && seriousness === "M")) {
                if (redInput !== "") {
                    props.setIsClicked(false)
                } else {
                    props.setIsClicked(true)
                }
            } else {
                props.setIsClicked(true)
            }
        }
    }, [seriousness, possibility, three, redInput, yellowInput])

    return (

        <Wrapper>
            {/*整个盒子*/}
            <div className="PartThree-root" style={{pointerEvents: noClick ? "unset" : "none"}}>

                {/*上方导航栏文字*/}
                <div className="word">
                    {LMRAEnum.PART_THREE}
                </div>

                {/*严重性*/}
                <div className="seriousness">
                    <dl>
                        <dt>
                            {/*头部文字和红点*/}
                            <div className="seriousness-head">
                                <div className="seriousness-WAP">
                                    {/*文字*/}
                                    <span className="seriousness-word"
                                          style={{color: seriousness === "" ? color.loginError : color.risk}}>
                                        {LMRAEnum.POSSIBILITY}
                                    </span>
                                    {/*红点*/}
                                    <span className="seriousness-pic"/>
                                </div>
                            </div>
                        </dt>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >
                            <Space>
                                {/*H*/}
                                <Radio.Group value={seriousness} rootClassName={noClick ? "edit" : "view"}>
                                    <dd>
                                        <Radio onClick={() => {
                                            setSeriousness("H")
                                        }} checked={seriousnessValue === "H"} style={{fontWeight: "bold"}}
                                               value={"H"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">H</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.POSSIBILITY_H}</span></div>
                                        </Radio>

                                    </dd>
                                    {/*M*/}
                                    <dd>
                                        <Radio onClick={() => {
                                            setSeriousness("M")
                                        }} checked={seriousnessValue === "M"} style={{fontWeight: "bold"}}
                                               value={"M"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">M</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.POSSIBILITY_M}</span></div>
                                        </Radio>
                                    </dd>
                                    {/*L*/}
                                    <dd>
                                        <Radio onClick={() => {
                                            setSeriousness("L")
                                        }} checked={seriousnessValue === "L"} style={{fontWeight: "bold"}}
                                               value={"L"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">L</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.POSSIBILITY_L}</span></div>
                                        </Radio>
                                    </dd>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </dl>
                </div>

                {/*可能性*/}
                <div className="possibility">
                    <dl>

                        <dt>
                            {/*头部文字和红点*/}
                            <div className="possibility-head">
                                <div className="possibility-WAP">
                                    {/*文字*/}
                                    <div className="possibility-word"
                                         style={{color: possibility === "" ? color.loginError : color.risk}}>
                                        {LMRAEnum.SERIOUSNESS}
                                    </div>
                                    {/*红点*/}
                                    <span className="possibility-pic"/>
                                </div>
                            </div>
                        </dt>

                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >

                            <Space>
                                {/*H*/}
                                <Radio.Group value={possibility} rootClassName={noClick ? "edit" : "view"}>
                                    <dd>
                                        <Radio onClick={() => {
                                            setPossibility("H")
                                        }} checked={possibilityValue === "H"} style={{fontWeight: "bold"}} value={"H"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">H</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.SERIOUSNESS_H}</span></div>
                                        </Radio>
                                    </dd>
                                    {/*M*/}
                                    <dd>
                                        <Radio onClick={() => {
                                            setPossibility("M")
                                        }} checked={possibilityValue === "M"} style={{fontWeight: "bold"}} value={"M"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">M</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.SERIOUSNESS_M}</span></div>
                                        </Radio>
                                    </dd>
                                    {/*L*/}
                                    <dd>
                                        <Radio onClick={() => {
                                            setPossibility("L")
                                        }} checked={possibilityValue === "L"} style={{fontWeight: "bold"}} value={"L"}>
                                            <div style={{marginTop: "3px"}}><span className="allSpan">L</span><span
                                                style={{marginLeft: "0"}}>{LMRAEnum.SERIOUSNESS_L}</span></div>
                                        </Radio>
                                    </dd>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </dl>
                </div>

                {/*蓝黄红三色提示框*/}
                <div className="identifying" style={{
                    display: possibility !== "" && seriousness !== "" ? "block" : "none",
                    backgroundColor: possibility === "L" && seriousness === "L" ? color.partThreeBlue :
                        (possibility === "H" && seriousness === "H") || (possibility === "M" && seriousness === "H") || (possibility === "H" && seriousness === "M") ? color.partThreeRed :
                            (possibility === "L" && seriousness === "H") || (possibility === "H" && seriousness === "L") || (possibility === "M" && seriousness === "M")
                            || (possibility === "M" && seriousness === "L") || (possibility === "L" && seriousness === "M") ? color.partThreeYellow : color.user
                }}>
                    <div className="identifying-word">
                        {error}
                    </div>
                </div>

                {/*黄色报警隐藏*/}
                <div className="identifying-newYellow" style={{
                    display: (possibility === "L" && seriousness === "H") || (possibility === "H" && seriousness === "L") || (possibility === "M" && seriousness === "M")
                    || (possibility === "M" && seriousness === "L") || (possibility === "L" && seriousness === "M")  ? "block" : "none"
                }}>
                    <div className="red-WAP">
                        {/*文字*/}
                        <div className="yellowWord">
                            {LMRAEnum.NEWYELLOW_WORD}
                        </div>
                        {/*图片*/}
                        <span className="SmallPic"/>
                    </div>
                    {/*文本框*/}
                    <div className="fatherCss">
                        <TextArea showCount maxLength={225}
                                  placeholder={noClick ? LMRAEnum.INPUT : undefined} onChange={onYellowChange}
                                  value={yellowInput !== "" ? yellowInput : !noClick ? "" : undefined}
                                  style={{
                                      borderColor: yellowInput !== "" ? undefined : color.loginError,
                                      height: 108,
                                      resize: 'none'
                                  }}/>
                    </div>

                </div>
                <div className="identifying-yellow" style={{
                    display: (possibility === "L" && seriousness === "H") || (possibility === "H" && seriousness === "L") || (possibility === "M" && seriousness === "M")
                    || (possibility === "M" && seriousness === "L") || (possibility === "L" && seriousness === "M") ? "flex" : "none"
                }}>


                    <div className="yellow-WAP"
                         style={{color: !noClick ? color.risk : three === "" ? color.loginError : color.risk}}>
                        {/*文字*/}
                        <span className="yellowWord">
                            {LMRAEnum.YELLOW_WORD}
                        </span>
                        {/*图片*/}
                        <span className="smallPic"/>
                    </div>

                    {/*单选框*/}
                    <ConfigProvider
                        theme={{
                            components: {
                                Radio: {
                                    dotSize: 9,
                                },
                            },
                        }}
                    >
                        <Space>
                            <Radio.Group
                                value={seriousnessValue !== "" && possibilityValue !== "" || three === LMRAEnum.YELLOW_YES ? LMRAEnum.YELLOW_YES : LMRAEnum.YELLOW_NO}
                                rootClassName={noClick ? "edit" : "view"}
                            >
                                <div style={{width: "25.66vw"}} className="radio">
                        <span style={{marginRight: "20px"}}>
                            <Radio onClick={() => {
                                setThree(LMRAEnum.YELLOW_YES)
                            }} style={{fontWeight: "bold"}} value={LMRAEnum.YELLOW_YES}
                                   className="yellowRadioOne">{LMRAEnum.YELLOW_YES}</Radio>
                        </span>
                                    <span>
                         <ConfigProvider
                             theme={{
                                 components: {
                                     Radio: {
                                         dotSize: 0,
                                     },
                                 },
                             }}
                         >
                        <Space>
                            <Radio onClick={() => {
                                setThree("")
                                props.onClick()
                            }} style={{fontWeight: "bold"}} value={LMRAEnum.YELLOW_NO}
                                   className="yellowRadioTwo">{LMRAEnum.YELLOW_NO}</Radio>
                        </Space>
                    </ConfigProvider>
                        </span>
                                </div>
                            </Radio.Group>
                        </Space>
                    </ConfigProvider>
                </div>

                <div className="red">
                    {/*红色报警隐藏*/}
                    <ConfigProvider
                        theme={{
                            components: {
                                Input: {},
                            },
                        }}
                    >
                        <Space>
                            <div className="identifying-red" style={{
                                display: (possibility === "H" && seriousness === "H") || (possibility === "M" && seriousness === "H")
                                || (possibility === "H" && seriousness === "M") ? "block" : "none"
                            }}>
                                <div className="red-WAP">
                                    {/*文字*/}
                                    <div className="redWord">
                                        {LMRAEnum.RED_WORD}
                                    </div>
                                    {/*图片*/}
                                    <span className="SmallPic"/>
                                </div>
                                {/*文本框*/}
                                <div className="fatherCss">
                                    <TextArea showCount maxLength={225}
                                              placeholder={noClick ? LMRAEnum.INPUT : undefined} onChange={onRedChange}
                                              value={redInput !== "" ? redInput : !noClick ? "" : undefined}
                                              style={{
                                                  borderColor: redInput !== "" ? undefined : color.loginError,
                                                  height: 108,
                                                  resize: 'none'
                                              }}/>
                                </div>

                            </div>
                        </Space>
                    </ConfigProvider>
                </div>
            </div>
        </Wrapper>
    )
}

export default PartThree;
