import styled from "styled-components";
import '../../static/font/font.css';
import {color} from '../../static/color/color';

const Wrapper = styled.div`

  margin: 0 auto;
  /* 默认的字体样式 */

  #tlra {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  !important;
    font-size: 12px;
  !important;
    font-weight: 400;
  !important;
    color: ${color.itemTitle};
  }

  .header {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  !important;
    font-size: 12px;
  !important;
    font-weight: 400;
  !important;
    color: ${color.itemTitle};
  }

  .TLRA {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  !important;
    font-size: 14px;
  !important;
    font-weight: 400;
  !important;
    color: ${color.itemTitle};
  }

  /* 以“项目"开头的文字的样式” */
  .project-message {
    font-size: 12px;
    font-weight: 400;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    color: ${color.TLRAProject};
  }
  
  .project-message + div {
    justify-content: flex-end;
  }

  .submit-time {
    justify-content: flex-end;
  }

  .project-num {
    width: 50%;
    margin-left: 12px;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  .expected-entry {
    margin-right: 12px;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  .project-description {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 8px;
  }

  .submit-person {
    margin-left: 12px;
    margin-bottom: 16px;
    flex-wrap: wrap;
  }

  .submit-time {
    margin-right: 12px;
  }

  .TLRA {
    margin-left: 12px;
    margin-right: 12px;
  }

  .line-one, .project-num, .line-three, .expected-entry, .submit-person, .submit-time {
    display: flex;
    width: 100%;
  }

  .file {
    background: ${color.user};
    border: 1px solid ${color.tlraFile};
    margin: 0 12px;
  }

  .footer {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /*当屏幕宽度大于等于1200px时生效 */
  @media (min-width: 1200px) {
    width: 700px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .forbidden {
      pointer-events: none;
      position: relative;
      z-index: 1;
    }

    .forbidden-two {
      width: 700px;
      height: 100vh;
      position: absolute;
      z-index: 2;
      background-color: ${color.prohibitClicking};
    }

    .footer {
      width: 700px;
    }

    .file {
      height: 570px;
    }
  }

  /*当屏幕宽度小于等于1200px时生效 */
  @media (max-width: 1200px) {
    .forbidden {
      pointer-events: none;
      position: relative;
      z-index: 1;
    }

    .forbidden-two {
      width: 100vw;
      height: 118vh;
      margin-top: -100vh;
      position: absolute;
      z-index: 2;
      background-color: ${color.prohibitClicking};
    }

    .footer {
      position: fixed;
      top: 89vh;
      width: 100%;
    }

    .file {
      height: 60vh;
    }
  }

  .file-content {
    width: 100%;
    height: calc(100% - 42px);
  }

  .filePdf {
    width: 100%;
    height: calc(100% - 84px);
    display: flex;
    justify-content: center;
    overflow: scroll;
    overflow-x: hidden;
  }

  .filePdfFooter {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filePdfPage {
    margin: 0 10px;
  }
  .annotationLayer{
    display: none;
  }
  .react-pdf__Page__textContent {
    display: none
  }
  
  #pdf-download{
    display: none;
  }
  
  #pdf-download {
    display: none;
  }
`;

export {Wrapper};
