import React, {CSSProperties, useEffect, useState} from "react";
import {Drawer, DrawerProps} from 'antd';
import {Wrapper} from "./style";
import Selected from "../../../../static/img/Service/CheckboxSelected.svg"
import Checkbox from "../../../../static/img/Service/Error.png";
import {ServerEnum, TLRAEnum} from "../../../../enum/field/field";
import {color} from "../../../../static/color/color";
import {RightOutlined} from "@ant-design/icons";
import moment from "moment/moment";


interface Prop {
    /**
     * 仅作为记住第四步存在
     */
    props?: { state: boolean, time: number, start: boolean }
}

const HoneywellRequest = (prop: Prop) => {
    const [chooseWord, setChooseWord] = useState(ServerEnum.YES);
    const [open, setOpen] = useState(false);
    const [choose, setChoose] = useState(true);
    const [nowTime, setNowTime] = useState("");
    const [passTime, setPassTime] = useState(0);
    const [start, setStart] = useState(false);
    const [or, setOr] = useState(false);
    // 0代表弹窗当前选择否，1代表是
    const [current,setCurrent] = useState(1);
    const [placement] = useState<DrawerProps['placement']>('bottom');
    const choiceYesStyle: CSSProperties = {
        marginTop:"29px",
        width: "100%",
        height: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        color: `${current === 1 ? color.risk:color.TLRAProject}`,
        borderBottom: "1px solid" + color.bottomLine,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }
    const choiceNoStyle: CSSProperties = {
        marginBottom:"0",
        width: "100%",
        height: "20%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        color: `${current === 0 ? color.risk:color.TLRAProject}`,
        borderBottom: "1px solid" + color.bottomLine,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }
    const contentStyle: CSSProperties = {
        overflow: "auto",
        pointerEvents: "auto",
        borderRadius: "1rem 1rem 0 0"
    }

    const data = new Date(passTime);
    const PassTime = passTime !== 0 ?data.getFullYear() + "/" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "/" +
        (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
        (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
        (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()) : ""
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const [pic, setPic] = useState(false);
    const handelPic = () => {
        if (!pic) {
            setPic(true)
            PubSub.publish('four', false)
        } else {
            setPic(false)
            PubSub.publish('four', true)
        }
    }
    const handleChoose = (choose: ServerEnum) => {
        setChoose(false);
        setChooseWord(choose);
    }

    PubSub.subscribe('returnFour', (_, data) => {
        setStart(data);
    })

    useEffect(() => {
        setNowTime(moment().format('YYYY-MM-DD HH:mm'));
    }, [start])

    useEffect(() => {
        if (prop && prop.props) {
            if (prop.props.state) {
                handleChoose(ServerEnum.YES)
                setOr(false)
                setPic(false);
            } else {
                handleChoose(ServerEnum.NO)
                setOr(true);
                setPic(true);
            }
            if (prop.props.state) {
                setStart(prop.props.start);
                setPassTime(prop.props.time)
            }
        }
    })

    return (
        <Wrapper>
            <div className="step-four" style={start ? {pointerEvents: "none"} : {pointerEvents: "unset"}}>
                <div className="submit-time">
                    <div
                        style={start ? PassTime !== "" ? {display: "block"} : {display: "none"} : {display: "none"}}
                        className="time"> {ServerEnum.SUBMIT_TIME}{start ? PassTime : nowTime}</div>
                    <div style={start ? {display: "none"} : {display: "block"}}>&nbsp;</div>
                </div>
                <div className="first-line" onClick={showDrawer}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div className="left-word">{ServerEnum.HIGH_REQUIREMENT}</div>
                        <div style={{
                            width: "5pt",
                            height: "5pt",
                            borderRadius: "50%",
                            backgroundColor: `${color.circle}`,
                            marginLeft: "2pt",
                            display: start ? "none" : "block"
                        }}></div>
                    </div>
                    <div className="first-line-right">
                        <div className="right-word"
                             style={!choose ? {color: color.loginTitle} : {color: color.loginError}}>{choose ? ServerEnum.REQUEST : chooseWord}</div>
                        <RightOutlined/>
                    </div>
                </div>
                <div style={or ? {display: "block"} : {display: "none"}}>
                    <hr className="my-hr"/>
                    <div className="second-line">
                        <div>
                            {ServerEnum.BLOW_REQUIREMENT}
                        </div>
                        <img src={pic ? Selected : Checkbox } alt="" onClick={handelPic}/>
                    </div>
                </div>
                <div className="request-confirm">
                    <Drawer
                        placement={placement}
                        width={500}
                        onClose={onClose}
                        open={open}
                        height={"30vh"}
                        closable={false}
                        contentWrapperStyle={contentStyle}
                    >
                        <div style={{display:"flex",justifyContent:"space-between",marginTop:"-18px",height:"30px",alignItems:"center"}}>
                            <div style={{color:color.drawer,fontSize:"16px"}} onClick={() => {
                                setOpen(false)
                            }}>{ServerEnum.DRAWER_CANCEL}</div>
                            <div style={{fontSize:"16px"}}>{ServerEnum.REQUEST}</div>
                            <div style={{color:color.drawer,fontSize:"16px"}} onClick={() => {
                                setOpen(false)
                                if (current === 1) {
                                    setOr(false)
                                    setPic(false)
                                    handleChoose(ServerEnum.YES)
                                    PubSub.publish('four', false)
                                    PubSub.publish('YON', ServerEnum.YES)
                                } else {
                                    handleChoose(ServerEnum.NO)
                                    setOr(true)
                                    PubSub.publish('four', true)
                                    PubSub.publish('YON', ServerEnum.NO)
                                }
                            }}>{TLRAEnum.SURE_BUTTON}</div>
                        </div>
                        <hr style={{border:"none",height:"0.5px",backgroundColor:color.hr}} className="my-hr"/>
                        <p style={choiceYesStyle} onClick={() => {
                            setCurrent(1);
                        }}>{ServerEnum.YES}</p>
                        <hr style={{border:"none",height:"0.5px",backgroundColor:color.hr}} className="my-hr"/>
                        <p style={choiceNoStyle} onClick={() => {
                            setCurrent(0);
                        }}>{ServerEnum.NO}</p>
                    </Drawer>
                </div>
            </div>
        </Wrapper>
    )
}
export default HoneywellRequest;
