import {instance} from "../util";
import axios from "axios";
import {getHeader} from "../../utils/Request/storageUtils";


/**
 * 我的现场服务具体项目查询
 * @param param
 * param = {
 *     "id": 1cea1020f8f34adab4a17dc25a886b1d（工程师服务请求主键Id：service_request_id）
 * }
 * response = {
 *     "riskCfm": true,（风险确认，若为空则需跳转到风险确认页面）
 *     "contractRisk": "R3",（风险等级）
 *     "customerHigherHoneywellTime": 1692778622000,（客户的标准是否高于霍尼的确认时间，若存在则代表第四步完成）
 *     "customerHigherHoneywell": false,（客户的标准是否高于霍尼标准的标注）
 *     "lmra": false, （jod状态，.，但还没有提交）
 *     "tlraAppro": 0, (若存在，0表示审批拒绝，1表示审批通过，2表示未进行审批)
 *     "lmraTime": 1694851772000,（lmra提交时间，若存在则代表第5步完成）
 *     "deptSubmitTime": 1694851872000,（确认离场时间，若存在则代表所以步骤完成，此时该项目不应呈现出来，若呈现出则代表接口逻辑有误）
 *     "somEngineerServiceRequestJobResponses": [（文件列表，可有多条，第5布上传lmra文件后才有数据，在此之前的步骤为null）
 *             {
 *                 "fileName": "1- Contract_Classification_Form-中英文版 \u00281\u0029.xlsx",
 *                 "filePath": "/som/serviceRequest/job/80fb7b8b9e834f94a0915501d91f73f7.XLSX",
 *                 "fileSize": 35061,
 *                 "jobId": "6c70553b167848c5a78cf42712512a2a",
 *                 "jobTime": 1694851658000,
 *                 "serviceRequestId": "4a5708d5035947bf9f94296110dda834"
 *             },
 *     ]
 * }
 */
const somEngineerServiceRequest_get = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/somEngineerServiceRequest/' + param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 客户选择是否高于霍尼韦尔要求、提交离场服务日期、风险确认
 * @param param
 * param = {
 *     "eid": "fyy",（当前登录人员eid）
 *     "projectNo": "45670085",（项目号）
 *     "serviceRequestId": "a073d5c65c9940d7b4f29d3dfee70028",（工程师服务请求主键Id：service_request_id）
 *     "estEntryDate": 1693638212000（预计入场时间）
 *     "customerHigherHoneywell": true,（客户选择是否高于霍尼韦尔要求带上该参数，其他不用）
 *     "deptDate": "2023-09-29T07:22:20.268Z",（提交离场服务日期带上该参数，其他不用）
 *     "riskCfm": true,（提交离场服务日期带上该参数，其他不用）
 * }
 */
const somEngineerServiceRequest_update = async (param: object) => {
    try {
        return await instance.patch('/es/gdpm/api/somEngineerServiceRequest/update', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 上传LMRA文件
 * @param param
 * params = {
 *     "multipartFile": (文件,类型binary)
 *     "jobTime": 1694848045715（提交文件时间）
 *     "serviceRequestId": a073d5c65c9940d7b4f29d3dfee70028（工程师服务请求主键Id：service_request_id）
 * }
 */
const somEngineerServiceRequestJob_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEngineerServiceRequestJob/insert', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 删除LMRA文件
 * @param param
 * params = {
 *     "id": "64ef0f56e9fa4d2da2a21b240d189753"（文件id，job_id）
 * }
 */
const somEngineerServiceRequestJob_delete = async (param: string) => {
    try {
        return await instance.delete('/es/gdpm/api/somEngineerServiceRequestJob/' + param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 提交LMRA附件（完成步骤5）
 * @param param
 * params = {
 *     "serviceRequestId": "a073d5c65c9940d7b4f29d3dfee70028"（工程师服务请求主键Id：service_request_id）
 * }
 */
const somEngineerServiceRequest_lmraSubmit = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEngineerServiceRequest/lmraSubmit', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 下载LMRA附件
 * params = {
 *     "filePath": "/file/wd"（filePath文件路径）
 * }
 * @param filePath
 * @param fileName
 */
const down_file = async (filePath: string, fileName: string) => {
    try {
        axios({
            // 用axios发送get请求
            method: "get",
            url: `/es/file${filePath}`, // 请求地址
            // data: form, // 参数
            responseType: "blob", // 表明返回服务器返回的数据类型
            headers: {
                "Content-Type": "text/plain",
                "Es-Device": "es-webapp",
                "x-xsrf-token": getHeader().token,
                "es-client-id": getHeader().clientId
            }
        }).then(res => {
            // 处理返回的文件流
            const content = res.data;
            const blob = new Blob([content]);
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }
        });
    } catch (error) {
        return "Error";
    }
}


/**
 * 提交文件排序接口
 * @param param
 */
const somEngineerServiceRequestList_get = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/somEngineerServiceRequestJob/list?serviceRequestId=' + param);
    } catch (error) {
        return "Error";
    }
}


export {
    somEngineerServiceRequest_get,
    somEngineerServiceRequest_update,
    somEngineerServiceRequestJob_insert,
    somEngineerServiceRequestJob_delete,
    somEngineerServiceRequest_lmraSubmit,
    somEngineerServiceRequestList_get,
    down_file,
};
