import {JSEncrypt} from "jsencrypt";

/**
 * 加密服务
 *
 * @class EsEncryptService
 */
export class EsEncryptService {
  // 将private改为public，eslinct语法不支持私有变量
  public encryptor = new JSEncrypt();

  /**
   * 设置公钥
   * @param key 公钥
   */
  setPublicKey(key: string) {
    this.encryptor.setPublicKey(key);
  }

  /**
   * 加密
   * @param obj 对象
   * @param toJson 转换为json字符串
   */
  encryptObject(obj: object | unknown[], toJson: boolean = true) {
    const str = this.encrypt(JSON.stringify(obj));
    if (toJson) return '"' + str + '"';
    return str;
  }

  /**
   * 加密
   * @param str 字符串
   */
  encrypt(str: string) {
    return this.encryptor.encrypt(str);
  }
}
