import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import MyBox from "../../../../components/MyBox";
import {LMRAEnum, ServerEnum} from "../../../../enum/field/field";
import TextArea from "antd/es/input/TextArea";
import MyCard from "../../../../components/MyCard";
import {ConfigProvider, message, Radio, RadioChangeEvent, Space} from "antd";
import {color} from "../../../../static/color/color";
import {somEsrJobThink_insert, somHazardControl_list, somPotentialHazard_list} from "../../../../api/LMRA/LMRA";
import * as store from "store";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";

interface potentialHazard {
    createdAt: string,
    createdBy: string,
    potentialHazardCode: string,
    potentialHazardId: string,
    potentialHazardName: string,
    updatedAt: string,
    updatedBy: string
}

interface ppeResponseList {
    createdAt: string,
    createdBy: string,
    fileName: string,
    filePath: string,
    fileSize: string,
    hazardControlCode: string,
    hazardControlId: string,
    hazardControlName: string,
    ppeId: string,
    ppeName: string,
    updatedAt: string,
    updatedBy: string
}

interface hazardControlList {
    createdAt: string,
    createdBy: string,
    hazardControlCode: string,
    hazardControlId: string,
    hazardControlName: string,
    somPpeResponseList: ppeResponseList[],
    updatedAt: string,
    updatedBy: string
}

interface somPpeResponseList {
    hazardControlId: string,
    ppeId: string,
}


interface Prop {
    currentStep: number,
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setStepTwoTime: React.Dispatch<React.SetStateAction<string>>;
    request: boolean;
    setRequest: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * 点击后的回调函数
     */
    onClickA: () => void;
    onClickB: () => void;
    refresh: (status: number, firstHandler: () => void, secondHandler: () => void) => void;
    setIsSpin: React.Dispatch<React.SetStateAction<boolean>>;

}

interface PpeIds {
    hazardControlId: string,
    ppeIds: string[],
}

const PartTwo = (props: Prop) => {
    // 控制是否发送数据
    const {currentStep, setIsClicked, setStepTwoTime} = props;

    // 全局data值
    const data = store.get("LMRA");

    // jobId与禁止点击状态
    const params = useParams();
    const {jobId} = params;

    // 单选框的值
    const [valueSix, setValueSix] = useState(-1);
    const [valueSeven, setValueSeven] = useState(-1);
    const [valueEight, setValueEight] = useState(-1);
    const [valueEnd, setValueEnd] = useState<string>("");
    // 潜在危险列表被选择的ids
    const [potentialHazardIdList, setPotentialHazardIdList] = useState<string[]>([]);
    // 后端返回被勾选的危险列表ids
    const [potentialHazardIds, setPotentialHazardIds] = useState<string[]>([]);
    // 潜在危险列表（所有数据）
    const [potentialHazardList, setPotentialHazardList] = useState<potentialHazard[]>([]);
    // 危害控制和ppe列表所有数据(仅图片)
    const [hazardControlListImgs, setHazardControlListImgs] = useState<hazardControlList[]>([]);
    // 危害控制和ppe列表被选择的防护（包括图片）被选择的
    const [somPpeRequestList, setSomPpeRequestList] = useState<somPpeResponseList[]>([]);
    // 危害控制和ppe列表所有数据(仅文字)
    const [hazardControlListWords, setHazardControlListWords] = useState<hazardControlList[]>([]);
    // 危害控制和ppe列表(仅文字)被选择的
    const [selectedWords, setselectedWords] = useState<somPpeResponseList[]>([]);
    // 后端返回被勾选的ppe（包括imgs和words的数组）
    const [ppeList, setPpeList] = useState<PpeIds[]>([]);
    // 潜在风险文本域的值
    const [inputValuePotential, setInputValuePotential] = useState("");
    // 危险防护和ppe文本域的值
    const [inputValueOther, setInputValueOther] = useState("");

    const [isClear, setIsClear] = useState(false);

    //获取潜在风险文本域的值
    const onChangePotential = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValuePotential(e.target.value)
    };
    //获取危险防护和ppe文本域的值
    const onChangeOther = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValueOther(e.target.value)
    };

    useEffect(() => {
        somPotentialHazard_list().then(res => {
            if (res != "Error")
                setPotentialHazardList(res.data.data)
        })
        somHazardControl_list().then(res => {
            if (res != "Error") {
                const imgTemp = [];
                const wordTemp = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].somPpeResponseList.length === 0) {
                        wordTemp.push(res.data.data[i])
                        setHazardControlListWords(wordTemp);
                    } else {
                        imgTemp.push(res.data.data[i])
                        setHazardControlListImgs(imgTemp);
                    }
                }
            }
        })
    }, [])
    useEffect(() => {
        if (currentStep === 1) {
            setIsClicked(true)
            if ((potentialHazardIdList.length != 0 || inputValuePotential != "") &&
                (somPpeRequestList.length != 0 || inputValueOther != "" || selectedWords.length != 0) && valueSix != -1 && valueSeven != -1 && valueEight != -1 && valueEnd != "")
                setIsClicked(false);
        }
    }, [valueSix, valueSeven, valueEight, valueEnd, potentialHazardIdList, somPpeRequestList, inputValuePotential, inputValueOther])

    const [num, setNum] = useState(0);
    useEffect(() => {

    }, [data])
    useEffect(() => {
        const tempArray = [];
        for (let i = 0; i < selectedWords.length; i++) {
            tempArray.push({
                hazardControlId: selectedWords[i],
            })
        }
        if (currentStep === 2 && props.request) {
            const params = {
                aenergySourcesTested: valueSix,
                afireHazardsAvoided: valueSeven,
                aproperContainmentReady: valueEnd === "true",
                aspillContainmentNeeded: valueEight,
                jobId: jobId,
                potentialHazardIdList: potentialHazardIdList,
                potentialHazardOther: inputValuePotential,
                ppeOther: inputValueOther,
                somPpeRequestList: [...somPpeRequestList, ...tempArray],
            }
            somEsrJobThink_insert(params).then(
                res => {
                    if (res != "Error") {
                        props.refresh(res.data.status, () => {
                            setIsClear(true);
                            props.setRequest(false);
                            message.error("该步骤已提交。");
                        }, () => {
                            setStepTwoTime(dayjs(res.data.data.updatedAt).format('YYYY-MM-DD HH:mm'));
                            props.setRequest(false);
                        })
                    }

                }
            )
        }
        setTimeout(() => {
            if (currentStep >= 2 && num < 2) {
                const data = store.get("LMRA");
                setNum(num + 1)
                // 初始化文本域
                if (data.somEsrJobThinkResponse) {
                    setInputValuePotential(data.somEsrJobThinkResponse.potentialHazardOther);
                    setInputValueOther(data.somEsrJobThinkResponse.ppeOther);
                    setValueSix(data.somEsrJobThinkResponse.aEnergySourcesTested);
                    setValueSeven(data.somEsrJobThinkResponse.aFireHazardsAvoided);
                    setValueEight(data.somEsrJobThinkResponse.aSpillContainmentNeeded);
                    setValueEnd(data.somEsrJobThinkResponse.aProperContainmentReady ? "true" : "false")
                    setPotentialHazardIds(data.somEsrJobThinkResponse.potentialHazardIds);
                    setPpeList(data.somEsrJobThinkResponse.ppeList);
                    setPotentialHazardIdList(data.somEsrJobThinkResponse.potentialHazardIds);
                }
            }
        }, 1000)
    }, [currentStep])
    return (
        <Wrapper>
            <div style={{pointerEvents: currentStep >= 2 ? "none" : "auto"}}>
                <div className="step-four">
                    <div className="title-four">
                        <div>
                            <div style={{
                                boxSizing: "border-box",
                                height: "22px",
                                paddingTop: "2px"
                            }}>{LMRAEnum.PART_TWO_TITLE_FOUR}</div>
                            <div className="status-point"></div>
                        </div>
                        <div className="title-warning" style={{
                            display: ((potentialHazardIdList ? potentialHazardIdList.length != 0 : false) ||
                                inputValuePotential != "") ? "none" : "block"
                        }}>{LMRAEnum.PRAT_TWO_TITLE_WARNING}</div>
                    </div>
                    <div className="boxs-four">
                        {
                            potentialHazardList.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            <MyBox
                                                isClear={isClear}
                                                isChecked={potentialHazardIds ? potentialHazardIds.includes(item.potentialHazardId) : false}
                                                potentialHazardName={item.potentialHazardName}
                                                potentialHazardId={item.potentialHazardId}
                                                potentialHazardIdList={potentialHazardIdList}
                                                setPotentialHazardIdList={setPotentialHazardIdList}
                                                key={index}/>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div>
                        <div>{LMRAEnum.OTHERS}</div>
                        <TextArea
                            showCount
                            onChange={onChangePotential}
                            maxLength={255}
                            style={{
                                height: 120,
                                marginBottom: 24,
                                resize: 'none',
                                borderRadius: 0,
                                borderColor: (inputValuePotential !== "" || (potentialHazardIdList ? potentialHazardIdList.length != 0 : false)) ? "" : color.redRound
                            }}
                            value={inputValuePotential}
                            placeholder={currentStep >= 2 ? "" : LMRAEnum.INPUT}
                        />
                    </div>
                </div>
                <div className="step-five">
                    <div className="title-five">
                        <div>
                            <div style={{
                                boxSizing: "border-box",
                                height: "22px",
                                paddingTop: "2px"
                            }}>{LMRAEnum.PART_TWO_TITLE_FIVE}</div>
                            <div className="status-point"></div>
                        </div>
                        <div style={{
                            display: ((somPpeRequestList ? somPpeRequestList.length != 0 : false)
                                || inputValueOther != "" || (selectedWords ? selectedWords.length != 0 : false) || ppeList.length != 0) ? "none" : "block"
                        }}>{LMRAEnum.PRAT_TWO_TITLE_WARNING}</div>
                    </div>
                    <div>
                        {
                            hazardControlListImgs.map((item, ins) => {
                                return (
                                    <div key={ins}>
                                        <div>{item.hazardControlName}</div>
                                        <div className="boxs-five">
                                            {
                                                item.somPpeResponseList.map((item: ppeResponseList, index: number) => {
                                                    let checked = false;
                                                    if (ppeList) {
                                                        for (let i = 0; i < ppeList.length; i++) {
                                                            if (ppeList[i].ppeIds.includes(item.ppeId)) {
                                                                checked = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                    return (
                                                        <div key={index}>
                                                            <MyCard
                                                                isClear={isClear}
                                                                isChecked={checked}
                                                                key={index} fileName={item.ppeName}
                                                                filePath={item.filePath}
                                                                hazardControlId={item.hazardControlId}
                                                                hazardControlName={item.hazardControlName}
                                                                ppeId={item.ppeId}
                                                                somPpeRequestList={somPpeRequestList}
                                                                setSomPpeRequestList={setSomPpeRequestList}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="boxs-four">
                            {
                                hazardControlListWords.map((item, index) => {
                                    let checked = false;
                                    if (ppeList) {
                                        for (let i = 0; i < ppeList.length; i++) {
                                            if (ppeList[i].hazardControlId === (item.hazardControlId)) {
                                                checked = true;
                                                break;
                                            }
                                        }
                                    }

                                    return (
                                        <div key={index}>
                                            {
                                                <MyBox
                                                    isClear={isClear}
                                                    isChecked={checked}
                                                    potentialHazardName={item.hazardControlName}
                                                    potentialHazardId={item.hazardControlId}
                                                    potentialHazardIdList={selectedWords}
                                                    setPotentialHazardIdList={setselectedWords}
                                                    key={index}/>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div>
                        <div>{LMRAEnum.OTHERS}</div>
                        <TextArea
                            showCount
                            onChange={onChangeOther}
                            maxLength={255}
                            style={{
                                height: 120,
                                marginBottom: 24,
                                resize: 'none',
                                borderRadius: 0,
                                borderColor: (inputValueOther !== "" || (selectedWords ? selectedWords.length != 0 : false) || (somPpeRequestList ? somPpeRequestList.length != 0 : false) || ppeList.length != 0) ? "" : color.redRound
                            }}
                            placeholder={currentStep >= 2 ? "" : LMRAEnum.INPUT}
                            value={inputValueOther}
                        />
                    </div>
                </div>
                <div className="step-six">
                    <div className={valueSix === -1 ? "title-six red-title" : "title-six"}
                         style={{display: "flex", alignItems: "center"}}>
                        <div style={{boxSizing: "border-box", height: "22px", paddingTop: "2px"}}>
                            {LMRAEnum.PART_TWO_TITLE_SIX}
                        </div>
                        <div className="status-point"></div>
                    </div>
                    <div>
                        {LMRAEnum.PART_TWO_TITLE_SUB}
                    </div>
                    <div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >
                            <Space>
                                <Radio.Group rootClassName={!(currentStep >= 2) ? "edit" : "view"}
                                             onChange={(e: RadioChangeEvent) => {
                                                 if (e.target.value === 0) {
                                                     setValueSix(-1);
                                                     props.onClickA();
                                                 } else {
                                                     setValueSix(e.target.value);
                                                 }
                                             }} value={valueSix}>
                                    <Radio value={1}>{ServerEnum.YES}</Radio>
                                    <Radio value={0}>{ServerEnum.NO}</Radio>
                                    <Radio value={2}>{LMRAEnum.INAPPLICABLE}</Radio>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </div>
                </div>
                <div className="step-seven">
                    <div className={valueSeven === -1 ? "title-seven red-title" : "title-seven"}
                         style={{display: "flex", alignItems: "center"}}>
                        <div style={{boxSizing: "border-box", height: "22px", paddingTop: "2px"}}>
                            {LMRAEnum.PART_TWO_TITLE_SEVEN}
                        </div>
                        <div className="status-point"></div>
                    </div>
                    <div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >
                            <Space>
                                <Radio.Group rootClassName={!(currentStep >= 2) ? "edit" : "view"}
                                             onChange={(e: RadioChangeEvent) => {
                                                 if (e.target.value === 0) {
                                                     setValueSeven(-1);
                                                     props.onClickA();
                                                 } else {
                                                     setValueSeven(e.target.value);
                                                 }
                                             }} value={valueSeven}>
                                    <Radio value={1}>{ServerEnum.YES}</Radio>
                                    <Radio value={0}>{ServerEnum.NO}</Radio>
                                    <Radio value={2}>{LMRAEnum.INAPPLICABLE}</Radio>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </div>
                </div>
                <div className="step-eight">
                    <div className={valueEight === -1 ? "title-eight red-title" : "title-eight"}
                         style={{display: "flex", alignItems: "center"}}>
                        <div style={{boxSizing: "border-box", height: "22px", paddingTop: "2px"}}>
                            {LMRAEnum.PART_TWO_TITLE_EIGHT}
                        </div>
                        <div className="status-point"></div>
                    </div>
                    <div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >
                            <Space>
                                <Radio.Group rootClassName={!(currentStep >= 2) ? "edit" : "view"}
                                             onChange={(e: RadioChangeEvent) => {
                                                 setValueEight(e.target.value);
                                             }} value={valueEight}>
                                    <Radio value={1}>{ServerEnum.YES}</Radio>
                                    <Radio value={0}>{ServerEnum.NO}</Radio>
                                    <Radio value={2}>{LMRAEnum.INAPPLICABLE}</Radio>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </div>
                </div>
                <div className={valueEnd === "" ? "step-end red-title" : "step-end "}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{boxSizing: "border-box", height: "22px", paddingTop: "2px"}}>
                            {LMRAEnum.PART_TWO_TITLE_LAST}
                        </div>
                        <div className="status-point"></div>
                    </div>
                    <div>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                        >
                            <Space>
                                <Radio.Group rootClassName={!(currentStep >= 2) ? "edit" : "view"}
                                             onChange={(e: RadioChangeEvent) => {
                                                 if (e.target.value === "false") {
                                                     setValueEnd("");
                                                     props.onClickB();
                                                 } else {
                                                     setValueEnd(e.target.value);
                                                 }
                                             }} value={valueEnd}>
                                    <Radio value={"true"}>{ServerEnum.YES}</Radio>
                                    <Radio value={"false"}>{ServerEnum.NO}</Radio>
                                </Radio.Group>
                            </Space>
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default PartTwo;
