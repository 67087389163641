import styled from "styled-components";
import {color} from "../../../../static/color/color";


const Wrapper = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;

  .identifying {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }

  .WPA {
    display: flex;
    align-items: center;
  }

  .Word {
    float: left;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .Pic {
    float: left;
    width: 6px;
    height: 6px;
    margin-top: 6px;
    background: ${color.circle};
    vertical-align: middle;
    border-radius: 10px
  }

  .radio {
    display: flex;
    justify-content: flex-end;
  }

  .wordRed {
    display: flex;
  }

  .ant-input {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-group {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-wrapper .ant-radio-checked::after {
    border: unset;
  }

  .edit .ant-radio-inner {
    border: 2px solid ${color.risk};
  }

  .view .ant-radio-inner {
    border: 2px solid ${color.tlraFile};
  }

  .view .ant-radio-checked .ant-radio-inner{
    border-color: #d9d9d9;
  }

  .ant-radio-inner::after {
    background-color: ${color.button};
  }

  .ant-radio-checked {
    border-color: unset;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${color.risk};
    background-color: unset;
  }

  .ant-radio-wrapper .ant-radio-inner:hover {
    border-color: ${color.risk};
  }

  .ant-input-affix-wrapper {
    margin-bottom: 16px;
  }

  .ant-input-affix-wrapper {
    border-radius: unset;
  }

  @media (max-width: 400px) {
    .yellowWord {
      width: 64%;
      float: left;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export {Wrapper};
