import React from "react";
import {Progress} from "antd";
import {Wrapper} from "./style";

interface Prop {
    /**
     * 总步骤数
     */
    steps:number,
    /**
     * 当前步骤
     */
    currentStep:number,
    /**
     * 进度条颜色
     */
    strokeColor:string,
}

const MyProgress = (props: Prop) => {
    const {steps, currentStep, strokeColor} = props;
    const times = 100 / steps;
    return (
        <Wrapper>
            <div className="line">
                <Progress type={"line"} percent={currentStep * times} showInfo={false}
                          strokeColor={strokeColor}></Progress><span>{currentStep}/{steps}</span>
            </div>
        </Wrapper>
    )
}
export default MyProgress;