import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`

  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;

  .departure-date {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    height: 55px;
    align-items: center;
  }

  .time {
    font-size: 12px;
    font-weight: 400;
    color: ${color.TLRAProject};
    line-height: 20px;
  }

  .pro {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .departure-time {
    display: flex;
  }

  .select {
    line-height: 22px;
    display: flex;
  }

  .please-select {
    font-size: 12px;
    font-weight: 400;
    color: ${color.loginError};
  }

  .step-six {
    border-left: solid ${color.button};
  }

  .submitTime {
    padding-left: 12px;
  }
`;
export {Wrapper}
