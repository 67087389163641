import React, {CSSProperties, useEffect, useState} from "react";
import {
    BodyWrapper,
    Choose,
    LittleTile,
    RadioGroup, RadioGroupFirst,
    RedPoint,
    TextArea,
    TimeArea,
    TimeIcon,
    TimeWrapper,
    TitleWrapper,
    Wrapper
} from "./style";
import {ConfigProvider, Radio} from "antd";
import DatePicker from "react-mobile-datepicker";
import dayjs from 'dayjs';
import calendar from '../../../../static/img/Calendar/calendar.png';
import {LMRAStep1} from "../../../../enum/field/field";
import {color} from "../../../../static/color/color";
import {somEsrJob_insert} from "../../../../api/LMRA/LMRA";
import store from "store";
import {useNavigate, useParams} from "react-router-dom";
import PopWindow from "../PopWindow";

interface TextStatus {
    status: 'error' | ""
}

interface RadioState {
    first: number,
    second: number,
    third: number,
    four:number,
}

interface Prop {
    /**
     * 传入状态
     */
    state: number;
    /**
     * 控制邮件弹窗显示
     */
    isHidden:boolean;
    /**
     * lmra页面蒙层
     */
    hide:boolean;
    /**
     * 点击后的回调函数
     */
    onClick: () => void;
    /**
     * 点击按钮点击状态
     */
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * 记录第一个选项的值
     */
    setIsFit: React.Dispatch<React.SetStateAction<number>>;
    /**
     * 当前步骤数
     */
    currentStep:number;
    request: boolean;
    setRequest: React.Dispatch<React.SetStateAction<boolean>>;
    setStepOneTime: React.Dispatch<React.SetStateAction<string>>;
    setIsSpin: React.Dispatch<React.SetStateAction<boolean>>;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
    setHide: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function PartOne(props: Prop) {
    const {isHidden,setIsHidden,setIsFit,hide
        ,setHide,setRequest,setCurrentStep,currentStep} = props;
    /**
     * radioState值为对应单选框的value.默认为0，选‘是’时为1，‘否’时为2
     */
    const defaultRadio: RadioState = {
        first: 0,
        second: 0,
        third: 0,
        four:0,
    }
    const navigate = useNavigate();
    const defaultColor = {first: color.R4, second: color.R4, third: color.R4,four:color.R4};
    const [time, setTime] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    const [isOpen, setIsOpen] = useState(false);
    const [textStatus, setTextStatus] = useState<TextStatus>({status: "error"});
    const [PhysicalTextStatus, setPhysicalTextStatus] = useState<TextStatus>({status: "error"});
    const [textValue, setTextValue] = useState("");
    const [physicalTextValue, setPhysicalTextValue] = useState("");
    const [radioState, setRadioState] = useState<RadioState>(defaultRadio);
    const [colors, setColors] = useState(defaultColor);
    const [pointer, setPointer] = useState<CSSProperties>({pointerEvents: 'auto'});
    const {serviceRequestId} = store.get("ServiceItem");
    const {forbidden} = useParams();
    /*获取全局data的值*/
    const data = store.get("LMRA");

    const [showOtherChoices,setShowOtherChoices] = useState(true);
    const [showPhysicalCondition,setShowPhysicalCondition] = useState(false);


    const textChange = (e: any) => {
        if (e.target.value.length > 0) {
            setTextStatus({status: ""});
        } else {
            setTextStatus({status: 'error'});
        }
        setTextValue(e.target.value);
    }
    const physicalTextChange = (e: any) => {
        if (e.target.value.length > 0) {
            setPhysicalTextStatus({status: ""});
        } else {
            setPhysicalTextStatus({status: 'error'});
        }
        setPhysicalTextValue(e.target.value);
    }
    const onTimeChange = (e: any) => {
        if (e !== null) {
            setTime(dayjs(e).format('YYYY-MM-DD HH:mm'));
            setIsOpen(false);
        }
    }
    const onchange = (value: number, index: number) => {
        setIsFit(value);
        if (value === 1) {
            if (index === 1) {
                setRadioState({...radioState, first: 1});
            } else if (index === 2) {
                setRadioState({...radioState, second: 1});
            } else if (index === 3) {
                setRadioState({...radioState, third: 1});
            } else if (index === 4) {
                setRadioState({...radioState, four: 1});
            }
        } else {
            if (index === 1) {
                setRadioState({...radioState, first: 2});
            } else if (index === 2) {
                setRadioState({...radioState, second: 0});
            } else if (index === 3) {
                setRadioState({...radioState, third: 0});
            }  else if (index === 4) {
                setRadioState({...radioState, four: 0});
            }
        }
    };
    useEffect(() => {
        setColors({
            ...colors,
            first: radioState.first !== 0 ? color.risk : color.R4,
            second: radioState.second === 1 ? color.risk : color.R4,
            third: radioState.third === 1 ? color.risk : color.R4,
            four: radioState.four === 1 ? color.risk : color.R4,
        })
        if (radioState.first === 2) {
            setShowOtherChoices(false);
            setShowPhysicalCondition(true);
        } else {
            setShowOtherChoices(true);
            setShowPhysicalCondition(false);
        }
    }, [radioState]);
    const dateConfig = {
        'year': {
            format: 'YYYY',
            caption: '',
            step: 1,
        },
        'month': {
            format: 'MM',
            caption: '',
            step: 1,
        },
        'date': {
            format: 'DD',
            caption: '',
            step: 1,
        },
        'hour': {
            format: 'hh',
            caption: '',
            step: 1,
        },
        'minute': {
            format: 'mm',
            caption: '',
            step: 1,
        },
    };
    // 发送请求接口
    const insertInfo = (value: object) => {
        somEsrJob_insert(value).then(
            res => {
                if (res != "Error") {
                    if (res.data.status === 0) {
                        navigate(`/lmra/${res.data.data.jobId}/add`);
                        props.setStepOneTime(dayjs(res.data.data.updatedAt).format('YYYY-MM-DD HH:mm'))
                    }
                }
            }
        )
    }
    // 发送第一步数据
    useEffect(() => {
        if (props.state === 0) {
            setPointer({pointerEvents: 'auto'});
        } else {
            setPointer({pointerEvents: 'none'});
        }
        if (props.state === 1 && props.request) {
            const params = {
                arequiredPermitsComplete: radioState.first === 1 || false,
                atoolsAvailable: radioState.first === 1 || false ,
                aunderstandTaskScope: radioState.first === 1 || false,
                aPhysicalConditionFitWork:radioState.first === 1,
                jobTime: time,
                serviceRequestId: serviceRequestId,
                taskDesc: textValue,
                physicalConditionDesc:radioState.first === 1 ? "" : physicalTextValue,
            }
            insertInfo(params);
            props.setRequest(false);
        }
    }, [props.state]);
    const [num, setNum] = useState(0);
    // 初始化数据
    useEffect(() => {
        if (forbidden) {
            if (forbidden.includes("add") && props.state < 1) return;
            if (data) {
                const somEsrJobResponse = data.somEsrJobResponse;
                if (somEsrJobResponse.jobTime && somEsrJobResponse.taskDesc) {
                    setTime(dayjs(new Date(somEsrJobResponse.jobTime)).format("YYYY-MM-DD HH:mm"));
                    setTextValue(somEsrJobResponse.taskDesc);
                    setPhysicalTextValue(somEsrJobResponse.physicalConditionDesc);
                    setRadioState({...radioState, first: somEsrJobResponse.aPhysicalConditionFitWork ? 1 : 2, second: 1, third: 1,four:1});
                }
            } else {
                setNum(num + 1);
            }
        }

    }, [num])
    useEffect(() => {
        if (props.state === 0) {
            if (time !== "" && textValue !== ""  && radioState.first === 1 && radioState.four === 1 && radioState.second === 1 && radioState.third === 1) {
                props.setIsClicked(false);
            } else if (physicalTextValue !== "" && radioState.first === 2 && textValue !== "") {
                props.setIsClicked(false);
            } else {
                props.setIsClicked(true);
            }
        }
    }, [textValue, time, radioState,physicalTextValue]);
    return (
        <Wrapper style={pointer}>
            <BodyWrapper>
                <TimeWrapper>
                    <TitleWrapper>
                        <LittleTile>{LMRAStep1.TIME_TITLE}</LittleTile><RedPoint/>
                    </TitleWrapper>

                    <TimeArea onClick={() => {
                        setIsOpen(true)
                    }}>
                        {time.substring(0,16)}<TimeIcon src={calendar} alt=""/>
                        <DatePicker
                            id='date'
                            min={new Date(0)}
                            value={new Date(time)}
                            theme={'ios'}
                            isOpen={isOpen}
                            showCaption={true}
                            customHeader={LMRAStep1.DATEPICKER_TITLE}
                            onSelect={onTimeChange}
                            onCancel={() => {
                                setIsOpen(false)
                            }}
                            dateConfig={dateConfig}
                        />
                    </TimeArea>
                </TimeWrapper>
                <TitleWrapper>
                    <LittleTile>{LMRAStep1.PER_WORK_DESCREPTION}:</LittleTile><RedPoint/>
                </TitleWrapper>
                <div>
                    <TextArea
                        status={textStatus.status}
                        onChange={textChange}
                        showCount
                        maxLength={255}
                        style={{
                            resize: 'none',
                            borderColor: textValue !== "" ? color.boxborder: color.redRound
                        }}
                        value={textValue}
                    />
                </div>
                <div className="choices">
                    <div className="firstChoice">
                        <Choose style={{color: colors.first}}>
                            <div className='title'>{LMRAStep1.CHOOSE_ONE}</div>
                            <RedPoint/>
                            <ConfigProvider
                            theme={{
                                components: {
                                    Radio: {
                                        dotSize: 9,
                                    },
                                },
                            }}
                            >
                            <RadioGroupFirst rootClassName={pointer.pointerEvents === 'auto' ? "edit" : "view"} onChange={(e) => onchange(e.target.value, 1)} value={radioState.first}>
                                <Radio value={1}>{LMRAStep1.YES}</Radio>
                                <Radio value={2}>{LMRAStep1.NO}</Radio>
                            </RadioGroupFirst>
                            </ConfigProvider>
                        </Choose>
                    </div>
                    <div className="otherChoices" style={{
                        display:showOtherChoices?'block':'none'
                    }}>
                        <Choose style={{color: colors.second}}>
                            <div className='title'>{LMRAStep1.CHOOSE_TOW}</div>
                            <RedPoint/>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Radio: {
                                            dotSize: 9,
                                        },
                                    },
                                }}
                            >
                                <RadioGroup rootClassName={pointer.pointerEvents === 'auto' ? "edit" : "view"} onChange={(e) => onchange(e.target.value, 2)} value={radioState.second}>
                                    <Radio value={1}>{LMRAStep1.YES}</Radio>
                                    <Radio value={2} onClick={props.onClick}>{LMRAStep1.NO}</Radio>
                                </RadioGroup>
                            </ConfigProvider>
                        </Choose>
                        <Choose style={{color: colors.third}}>
                            <div className='title'>{LMRAStep1.CHOOSE_THREE}</div>
                            <RedPoint/>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Radio: {
                                            dotSize: 9,
                                        },
                                    },
                                }}
                            >
                                <RadioGroup rootClassName={pointer.pointerEvents === 'auto' ? "edit" : "view"} value={radioState.third} onChange={(e) => onchange(e.target.value, 3)}>
                                    <Radio value={1}>{LMRAStep1.YES}</Radio>
                                    <Radio value={2} onClick={props.onClick}>{LMRAStep1.NO}</Radio>
                                </RadioGroup>
                            </ConfigProvider>
                        </Choose>
                        <Choose style={{color: colors.four}}>
                            <div className='title'>{LMRAStep1.CHOOSE_FOUR}</div>
                            <RedPoint/>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Radio: {
                                            dotSize: 9,
                                        },
                                    },
                                }}
                            >
                                <RadioGroup rootClassName={pointer.pointerEvents === 'auto' ? "edit" : "view"} value={radioState.four} onChange={(e) => onchange(e.target.value, 4)}>
                                    <Radio value={1}>{LMRAStep1.YES}</Radio>
                                    <Radio value={2} onClick={props.onClick}>{LMRAStep1.NO}</Radio>
                                </RadioGroup>
                            </ConfigProvider>
                        </Choose>
                    </div>
                    <div className="physicalCondition" style={{
                        display:showPhysicalCondition?'block':'none'
                    }}>
                        <TitleWrapper>
                            <LittleTile>{LMRAStep1.PHYSICAL_CONDITION}</LittleTile><RedPoint/>
                        </TitleWrapper>
                        <div>
                            <TextArea
                                status={PhysicalTextStatus.status}
                                onChange={physicalTextChange}
                                showCount
                                maxLength={255}
                                style={{
                                    resize: 'none',
                                    borderColor: physicalTextValue !== "" ? color.boxborder: color.redRound
                                }}
                                value={physicalTextValue}
                            />
                        </div>
                    </div>
                </div>
            </BodyWrapper>
            <PopWindow hidden={isHidden} handleCancel={() => {
                setIsHidden(!isHidden);
                setHide(!hide);
            }} handleSubmit={() => {
                setIsHidden(!isHidden);
                setHide(!hide);
                setRequest(true);
                setCurrentStep(currentStep + 1);
            }}/>
        </Wrapper>
    )
}

