import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Wrapper} from "./style";
import {ServerEnum, TLRAEnum} from "../../enum/field/field";
import {RightOutlined} from "@ant-design/icons";
import store from "store";


interface Prop {
    /**
     * 项目号
     */
    itemNumber: string;

    /**
     * 项目内容
     */
    projectContent: string;

    /**
     * 是否禁用
     */
    user?: string;

    /**
     * 用户id
     */
    id?: string;

    /**
     * 按钮样式
     */
    time: number;

    /**
     * 路由地址
     */
    into: string;

    /**
     * 时间戳
     */
    timeStamp?: number;

    /**
     * service用于风险判断一系列的主键
     */
    serviceId?: string;

    /**
     * 我的审批界面文件路径
     */
    filePath?: string;

    /**
     * 我的审批界面工程师的特定id
     */
    tlraId?: string;

    /**
     * 判断下划线是否需要变色
     */
    backGround?:boolean;
}

const ProjectItem = (props: Prop) => {

    const {itemNumber, projectContent, user, id, time, into, timeStamp, serviceId, tlraId, filePath, backGround} = props;

    const history = useNavigate();



    const [Data, setData] = useState("")

    useEffect(() => {
        // 时间戳转成规定时间格式
        const data = new Date(time);
        setData(data.getFullYear() + "/" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "/" +
            (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()));
    }, [])


    const goInPic = () => {
        if (into === '/TLRA') {
            // 使用缓存取值，
            // 不使用路由将所以值传递：1、不美观，2、传递的值过多打开页面空白
            const data = {
                projectNo: itemNumber,
                estEntryDate: time,
                filePath: filePath,
                projectDesc: projectContent,
                submitBy: id,
                submitByName: user,
                submitTime: timeStamp,
                tlraId: tlraId,
                serviceRequestId: serviceId
            }
            store.set("TLRAItem", data);
            history(`${into}/${itemNumber}`);
        } else if (into === '/Service') {
            const data = {
                projectNo: itemNumber,
                estEntryDate: time,
                projectDesc: projectContent,
                serviceRequestId: serviceId
            }
            store.set("ServiceItem", data);
            history(`${into}/${itemNumber}`);
        }
    }

    return (
        <Wrapper>
            {/*TLRA组件*/}
            <div className="projectItem-root" onClick={goInPic}>
                {/*左边文本*/}
                <div className="left-txt">
                    {/*头部*/}
                    <div className="heard">
                        {/*标题*/}
                        <span className="title">
                          {itemNumber}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{projectContent}
                      </span>
                    </div>
                    {/*底部*/}
                    <div className="bottom">
                        {/*发布人*/}
                        <span className="user">
                          {TLRAEnum.SUBMIT_PEOPLE}{user}({id})
                      </span>
                        {/*日期*/}
                        <span className="date">
                           {ServerEnum.ENTRANCE}{Data}
                      </span>
                    </div>
                </div>
                {/*右边图片*/}
                <div className="right-pic">
                    <div className="pic">
                        <RightOutlined className="goInPic"/>
                    </div>
                </div>
                <hr style={{borderTop:backGround ?"0.5px double rgba(0, 0, 0, 0.4)" : "none"}}/>
            </div>
        </Wrapper>
    )
}
export default ProjectItem;
