import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`
  .allSpan {
    width: 20px;
    margin-left: -2px;
    display: inline-block;
  }

  input {
    width: 16px;
    height: 16px;
    font-weight: bold;
    border: 2px solid #D0D0D0;
    vertical-align: baseline;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  dd {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }

  .word {
    width: 100%;
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;
  }

  .seriousness-WAP {
    margin-top: 16px;
  }

  .seriousness-word {
    font-size: 14px;
    float: left;
  }

  .possibility-word {
    font-size: 14px;
    float: left;
  }


  .seriousness-pic {
    width: 8px;
    height: 8px;
    margin-left: 3px;
    margin-top: 5px;
    background: ${color.circle};
    float: left;
    border-radius: 10px
  }

  .possibility-pic {
    width: 8px;
    height: 8px;
    margin-left: 3px;
    margin-top: 5px;
    background: ${color.circle};
    float: left;
    border-radius: 10px
  }

  .seriousness-WAP {
    display: flex;
  }

  .possibility-WAP {
    display: flex;
  }

  .identifying {
    width: 100%;
    margin-top: 12px;
  }

  .identifying-word {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .identifying-yellow {
    display: flex;
    align-items: center;
  }

  .identifying-newYellow {
    width: 100%;
    margin-top: 22px;
    margin-bottom: 12px;
  }

  .identifying-red {
    width: 100%;
    margin-top: 24px;
  }

  .red-WAP {
    display: flex;
  }

  .yellowWord {
    float: left;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .redWord {
    float: left;
  }

  .yellowWord {
    float: left;
  }

  .smallPic {
    float: left;
    width: 6px;
    height: 6px;
    margin-top: 6.5px;
    background: ${color.circle};
    vertical-align: middle;
    border-radius: 10px
  }

  .SmallPic {
    float: left;
    width: 6px;
    height: 6px;
    margin-top: 6px;
    background: ${color.circle};
    vertical-align: middle;
    border-radius: 10px
  }

  .yellowRadioOne {
    font-size: 14px;
  }

  .yellowRadioTwo {
    font-size: 14px;
  }

  .radio {
    display: flex;
    justify-content: flex-end;
  }

  .yellow-WAP {
    display: flex;
    width: 250.34%;
  }


  .edit .ant-radio-inner {
    border: 2px solid ${color.risk};
  }

  .view .ant-radio-inner {
    border: 2px solid ${color.tlraFile};
  }

  .ant-radio-inner::after {
    background-color: ${color.button};
    border-color: #d9d9d9;
  }

  .view .ant-radio-checked .ant-radio-inner{
    border-color: #d9d9d9;
  }

  .ant-radio-checked {
    border-color: unset;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${color.risk};
    background-color: unset;
  }

  .ant-radio-wrapper .ant-radio-inner:hover {
    border-color: ${color.risk};
  }

  .ant-radio-wrapper {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-group {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-input {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .ant-radio-wrapper .ant-radio-checked::after {
    border: unset;
  }

  .ant-input-affix-wrapper {
    margin-bottom: 16px;
  }

  .red {
    .ant-space-align-center {
      width: 100% !important;
    }
  }

  .ant-space-item {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    border-radius: unset;
  }

  .radio > span > span {
    margin-top: 1px;
  }

  @media (max-width: 1000px) {
    .yellow-WAP {
      width: 265.34%;
    }
  }

  @media (max-width: 400px) {
    .yellow-WAP {
      width: 125.34%;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

`;

export {Wrapper};
