import React, {CSSProperties, useEffect} from "react";
import {Collapse, CollapseProps, ConfigProvider} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {Wrapper} from "./style";

interface Prop {
    /**
     * 当前面板代表的步骤数
     */
    thisKey: number,
    /**
     * 当前步骤的描述
     */
    description: string,
    /**
     * 当前步骤完成的时间
     */
    finishedTime: string,
    /**
     * 当前折叠面板的内容
     */
    children: any
    /**
     * 当前正在进行的步骤数
     */
    activeKey: number
    /**
     * 使用此属性控制当前面板是否折叠
     */
    isActive: boolean
    /**
     * 控制折叠与展开面板
     */
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyCollapse = (props: Prop) => {
    const {thisKey, finishedTime, children, description, activeKey, isActive, setIsActive} = props;
    const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => [
        {
            key: thisKey,
            label: description,
            children: <p>{children}</p>,
            style: panelStyle,
            extra: <span>{finishedTime}</span>
        },
    ];
    const panelStyle: React.CSSProperties = {
        marginBottom: 12,
        border: 'none',
    };
    // useEffect用于确认每次进入页面的默认展开，触发条件暂定，具体需结合流程确定
    useEffect(() => {
        setIsActive(activeKey === thisKey)
    }, [activeKey])
    return (
        <Wrapper>
            <ConfigProvider
                theme={{
                    components: {
                        Collapse: {
                            fontFamily: 'HoneywellSansWeb-Medium, SourceHanSansCN-Normal,sans-serif'
                        },
                    },
                }}
            >
                <Collapse
                    rootClassName={isActive ? "myCollapse" : ""}
                    bordered={false}
                    size={"small"}
                    defaultActiveKey={activeKey}
                    activeKey={isActive ? thisKey : ""}
                    expandIcon={({isActive}) => <DownOutlined rotate={isActive ? 180 : 0}/>}
                    items={getItems(panelStyle)}
                    onChange={() => {
                        setIsActive(!isActive)
                    }}
                />
            </ConfigProvider>
        </Wrapper>
    )
}
export default MyCollapse;