import React from "react";
import {Wrapper} from "./style";
import {LMRAEnum} from "../../enum/field/field";




interface Prop {
    /**
     * 上方文字
     */
    top?:string

    /**
     * 下方文字
     */
    end?:string

    /**
     * 点击后左边的回调函数
     */
    leftOnClick: () => void;

    /**
     * 点击后右边的回调函数
     */
    rightOnClick: () => void;

    /**
     * 点击后左边的回调函数
     */
    leftWord?: string;

    /**
     * 点击后右边的回调函数
     */
    rightWord?: string;
}

export default function Approval(props: Prop) {

    return (
        <Wrapper>
            <div className='Pop-root'>
                <div className='txt'>
                    {props.top}
                    <br/>
                    {props.end}
                </div>
                <hr className="my-hr"/>
                <div className="buttons">
                    <div className='buttonLeft' onClick={props.leftOnClick}>
                        {props.leftWord === undefined ? LMRAEnum.CANCELLATION : props.leftWord}
                    </div>
                    <div className='buttonRight' onClick={props.rightOnClick}>
                        {props.rightWord === undefined ? LMRAEnum.RIGHT_BUTTON : props.rightWord}
                    </div>
                </div>

            </div>
        </Wrapper>
    )
}
