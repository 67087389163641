import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`
	.window{
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 20;
		width: 85vw;
		height: 181px;
		border-radius: 8px;
		background: #fff;
		font-family: HoneywellSansWeb-Medium,SourceHanSansCN-Normal,BlinkMacSystemFont,sans-serif;
		font-size: 1.0625rem;
		text-align: center;
		padding: 20px 10px 0;
		transform: translate(-50%, -50%);
	}
  header {
    color: ${color.redRound};
    line-height: 39px;
  }
  header::first-letter {
    color: ${color.loginTitle};
  }
  article {
    margin: 0 auto;
    width: 275px;
    font-size: 14px;
    line-height: 31px;
    padding-bottom: 11px;
  }
  hr {
    border: none;
    height: 0.5px;
    margin-top: 15px;
    background-color: #E6E6E6;
  }
  footer {
    display: flex;
    justify-content: space-around;
  }
  
  
	
	`
export {Wrapper};