import {instance} from "../util";
import axios from "axios";


/**
 * 审批同意、拒绝
 * @param param
 * params = {
 *   "approveOrReject": false,(是否同意)
 *   "tlraId": "839e26f9bd784763be3c7a854dd423ca"（申请审批id）
 *   "approveTime": "2020-01-01 00:00:00",(审核时间)
 *   "approver": "E69864",（审核人eid）
 *   "comments": "1",（备注）
 *   "serviceRequestId": "c847f3f59c5d4b7f94f999d288bec944"（工程师服务请求主键Id：service_request_id）
 * }
 */
const somEngineerServiceRequestTlraAudit_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEngineerServiceRequestTlraAudit/insert', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * tlra审批具体项目查看风险等级
 * @param param
 * param = {
 *     "projectNo": 1cea1020f8f34adab4a17dc25a886b1d（项目号）
 * }
 * response = {
 *     "contractRisk": "R3",（风险等级）
 * }
 */
const som_contract_classification = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/som/contract/classification/' + param);
    } catch (error) {
        return "Error";
    }
}

/**
 * tlra审批word转PDF
 * @param param
 * param = {
 *     "inputFile": "/som/sfwevreverv.DOCX"（二进制文件）
 * }
 */
const som_wordToPDF = async (param: object) => {
    try {
        return await axios.post('/es/office-svc/wordToPDF', param, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
        });
    } catch (error) {
        return "Error";
    }
}

export {somEngineerServiceRequestTlraAudit_insert, som_contract_classification, som_wordToPDF};
