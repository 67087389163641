import store from 'store';

const USER_KEY = 'user_key'
const NAME = 'name'
//保存
export const saveUser = (user: object) => {
    const oldUser = store.get(USER_KEY);
    // 存储用户信息
    store.set(USER_KEY, {...oldUser, ...user})
}

//读取
export const getUser = () => {
    // 获取用户信息
    return store.get(USER_KEY) || {}
}

//读取
export const getName = () => {
    // 获取用户名字
    return store.get(NAME)
}




