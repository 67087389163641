import React, {CSSProperties, useEffect, useRef, useState} from "react";
import UniversalButton from "../../components/UniversalButton";
import NotesWindow from "./components/NotesWindow";
import {Wrapper} from "./style";
import {color} from "../../static/color/color";
import {TLRAEnum} from "../../enum/field/field";
import Head from "../../components/Head";
import Block from "../../components/Block";
import {som_wordToPDF} from "../../api/TLRA";
import {message, Spin} from "antd";
import FileViewer from "./components/FileViewer";
import store from "store";
import {somEngineerServiceRequest_get} from "../../api/Server";
import {useNavigate} from "react-router-dom";

const TLRA = () => {
    // 缓存取值
    const {
        projectNo,
        estEntryDate,
        filePath,
        projectDesc,
        submitBy,
        submitByName,
        submitTime,
        tlraId,
        serviceRequestId
    } = store.get("TLRAItem");

    /* 控制弹窗是否出现 */
    const [disabled, setDisabled] = useState<boolean>(false);
    const subTitle = useRef("");
    const [forbidden, setForbidden] = useState("");

    // 备注内容
    const [value, setValue] = useState("");

    const [Data, setData] = useState("");
    const [DataTwo, setDataTwo] = useState("");

    const [danger, setDanger] = useState("");
    const [newPath, setNewPath] = useState(`/es/file/${filePath}`);

    const [isSpinning, setSpinning] = useState(false);
    const [spin, setSpin] = useState(true);
    const navigate = useNavigate(); // 路由跳转

    const [wordFile, setWordFile] = useState<File>();

    function getFileFromUrl(url: string, fileName: string) {
        return new Promise((resolve, reject) => {
            let blob = null;
            const xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            // 加载时处理
            xhr.onload = () => {
                // 获取返回结果
                blob = xhr.response;
                const file = new File([blob!], fileName, {type: blob!.type});
                setWordFile(file)
                // 返回结果
                resolve(file);
            };
            xhr.onerror = (e) => {
                reject(e)
            };
            // 发送
            xhr.send();
        });
    }

    const fileType = filePath.substring(filePath.lastIndexOf(".") + 1).toLowerCase();
    const [flush, setFlush] = useState(true);

    useEffect(() => {
        if (fileType == "docx" || fileType == "dox") {
            const formData = new FormData();
            getFileFromUrl(newPath, filePath.substring(filePath.lastIndexOf("/") + 1)).then();
            formData.append('inputFile', wordFile ? wordFile : "");
            som_wordToPDF(formData).then(res => {
                if (res != "Error") {
                    if (res.data.size === 0) {
                        setFlush(!flush)
                    } else {
                        const data = new Blob([res.data], {type: 'application/pdf'});
                        setNewPath(window.URL.createObjectURL(data));
                        setSpin(false);
                    }
                }
            })
        } else {
            setNewPath(`/es/file/${filePath}`);
            setSpin(false)
        }
    }, [flush])


    useEffect(() => {
        const data = new Date(Number(submitTime));
        setData(data.getFullYear() + "/" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "/" +
            (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
            (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
            (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()))

        const dataTwo = new Date(Number(estEntryDate));
        setDataTwo(dataTwo.getFullYear() + "/" + ((dataTwo.getMonth() + 1) < 10 ? "0" + (dataTwo.getMonth() + 1) : (dataTwo.getMonth() + 1)) + "/" +
            (dataTwo.getDate() < 10 ? "0" + dataTwo.getDate() : dataTwo.getDate()))
    }, [Number(submitTime), Number(estEntryDate)])


    useEffect(() => {
        somEngineerServiceRequest_get(serviceRequestId).then(res => {
            if (res != "Error") {
                if (res.data.data.tlraAppro === 0 || res.data.data.tlraAppro === 1)
                    navigate('/Approval');
                else
                    setDanger(res.data.data.contractRisk);
            }
        })
    }, [])

    /**
     * 按钮样式
     */
    const buttonStyleLeft: CSSProperties = {
        width: "150px",
        height: "36px",
        marginLeft: "16px",
        fontFamily: "SourceHanSansCN-Normal",
        background: `${color.user}`,
        color: `${color.button}`,
        border: "2px solid",
    }

    const buttonStyleRight: CSSProperties = {
        width: "150px",
        height: "36px",
        marginRight: "16px",
        fontFamily: "SourceHanSansCN-Normal",
        background: `${color.button}`,
    }
    const [messageApi, contextHolder] = message.useMessage();
    return (
        <Wrapper>
            <div id={"tlra"}>
                {disabled &&
                    <NotesWindow name={subTitle.current} setDisabled={setDisabled} setForbidden={setForbidden}
                                 value={value}
                                 setValue={setValue} tlraID={tlraId !== undefined ? tlraId : ""}
                                 setSpinning={setSpinning} messageApi={messageApi}/>}
                <Spin spinning={isSpinning}>
                    <div className={forbidden}>
                        {contextHolder}
                        <Head
                            name={TLRAEnum.TLRA_APPROVE}
                            state={"none"}
                            Return={'/Approval'}
                        />
                        <div className="header">
                            <div className="line-one">
                                <div className="project-message project-num">{TLRAEnum.PROJECT}{projectNo}<Block
                                    name={danger}/>
                                </div>
                                <div className="expected-entry">{TLRAEnum.ENTRANCE}{DataTwo}</div>
                            </div>
                            <div className="project-message project-description">{TLRAEnum.DESCRIBE}{projectDesc}</div>
                            <div className="line-three">
                                <div className="submit-person">
                                    <div>{TLRAEnum.SUBMIT_PEOPLE}</div>
                                    <div style={{marginTop: '4px'}}>{submitByName}({submitBy})</div>
                                </div>
                                <div className="submit-time">{TLRAEnum.SUBMIT_TIME}{Data}</div>
                            </div>
                        </div>
                        <div className="body">
                            <div className="TLRA">TLRA</div>
                            <Spin spinning={spin}>
                                <div className="file" style={{opacity: spin ? 0 : 1}}>
                                    {
                                        spin ? '' : <FileViewer path={newPath}/>
                                    }
                                </div>
                            </Spin>
                        </div>
                        <div className="footer">
                            <UniversalButton
                                name={TLRAEnum.REFUSE}
                                disabled={false}
                                style={buttonStyleLeft}
                                onClick={() => {
                                    setDisabled(true);
                                    subTitle.current = TLRAEnum.REFUSE_REASONS;
                                    setForbidden("forbidden");
                                }}
                            />

                            <UniversalButton
                                name={TLRAEnum.AGREE}
                                disabled={false}
                                style={buttonStyleRight}
                                onClick={() => {
                                    setDisabled(true);
                                    subTitle.current = TLRAEnum.AGREE_REASON;
                                    setForbidden("forbidden");
                                }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
            <div className='forbidden-two' style={disabled ? {display: "block"} : {display: "none"}}></div>
        </Wrapper>
    )
}
export default TLRA;
