import React, {CSSProperties, useEffect, useState} from "react";
import {Badge} from "antd";
import UniversalButton from "../../components/UniversalButton";
import {Wrapper} from "./style";
import {color} from '../../static/color/color';
import Head from "../../static/img/LoginSucceeded/Head.png";
import TLRA from "../../static/img/LoginSucceeded/TLRApproval.svg";
import Service from "../../static/img/LoginSucceeded/Server.svg";
import {TLRAEnum} from "../../enum/field/field";
import {useNavigate} from "react-router-dom";
import {getUser} from "../../utils/RememberPassword/storageUtils";
import {identity_islogin, isPM} from "../../api/loginSucceeded";
import {som_getTLRAList} from "../../api/Approval";
import {deleteHeader} from "../../utils/Request/storageUtils";
import {logout} from "../../api/loginApi";
import store from "store";

const LoginSucceeded = () => {
    const [name, setName] = useState(""); // 姓名
    const [count, setCount] = useState(0) // TLRA审批图标右上角消息数
    const navigate = useNavigate(); // 路由跳转
    const [pm, setIsPM] = useState<boolean>();


    /**
     * 按钮样式
     */
    const buttonStyle: CSSProperties = {
        width: "100%",
        height: "38.4px",
        fontFamily: "SourceHanSansCN-Normal",
        background: `${color.button}`,
        margin: "1rem",
    }

    const loginOut = () => {
        logout().then(() => {
            store.remove("name")
            deleteHeader(); // 退出登录移除当前XToken
            // 退出到回调地址
            const AppId = 'wx2f784899982c4d14'; // 公众号appid
            const RedirectURI = encodeURI("https://sommini.honeywell.cn"); // 回调地址
            const Scope = 'snsapi_base'; // 这里不进行弹窗获取
            window.location.href =
                `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${RedirectURI}&response_type=code&scope=${Scope}&state=STATE#wechat_redirect`;
        })
    }


    // 身份判别接口
    useEffect(() => {
        identity_islogin(getUser().token.replace("/", "")).then(res => {
            if (res != "Error") {
                setName(res.data.data.userName);
                store.set("name", res.data.data.userName)

                // 判断是否为PM
                isPM(getUser().eid).then(res => {
                        if (res != "Error") {
                            setIsPM(res.data.data);

                            if (res.data.data) {
                                // 获取消息数
                                som_getTLRAList(getUser().eid).then(res => {
                                        if (res != "Error") {
                                            setCount(res.data.data.length)
                                        }
                                    }
                                )
                            }
                        }
                    }
                )
            }
        })
    }, [getUser().eid])

    return (
        <Wrapper>
            <div>
                <div className="header">
                    <img src={Head} alt=""/>
                    <div className="user">
                        {name}&nbsp;&nbsp;({getUser().eid})
                    </div>
                </div>

                <div className="body">
                    <div className="SOM">SOM</div>

                    <div className="option">
                        <Badge count={count} overflowCount={99} size={"small"} offset={[-20, 0]} color={color.circle}>
                            <div className="TLRA" style={{display: pm ? "flex" : "none"}}>
                                <img
                                    src={TLRA} alt=""
                                    onClick={() => {
                                        navigate("/approval")
                                    }}
                                />
                                <span>{TLRAEnum.TLRA_APPROVE}</span>
                            </div>
                        </Badge>

                        <div className="service" style={{marginLeft: pm ? "0" : "0.8rem"}}>
                            <img src={Service} alt=""
                                 onClick={() => {
                                     navigate("/fieldServer")
                                 }}
                            />
                            <span>{TLRAEnum.FIELD_SERVICE}</span>
                        </div>

                    </div>
                </div>

                <div className="footer">
                    <UniversalButton
                        name={TLRAEnum.EXIT_BUTTON}
                        style={buttonStyle}
                        disabled={false}
                        onClick={loginOut}
                    />
                </div>
            </div>
        </Wrapper>
    )
}
export default LoginSucceeded;
