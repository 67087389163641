import React, {useEffect, useState} from "react";
import {Badge} from "antd";
import {Wrapper} from "./style";
import {color} from "../../../../static/color/color";
import {ServerEnum} from "../../../../enum/field/field";
import {RightOutlined} from "@ant-design/icons";
import DatePicker from "react-mobile-datepicker";
import moment from "moment";

interface Prop {
    getDepartureTime: (time: Date) => void

    /**
     * 用于传入最小时间
     */
    Time?: number

}

const DepartureDate = (props: Prop) => {

    const {Time} = props

    const {getDepartureTime} = props;
    const [time, setTime] = useState(new Date());
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState(false);
    const [start, setStart] = useState(false)

    const [minTime, setMinTime] = useState(0);

    const [nowTime, setNowTime] = useState("");

    const dateConfig = {
        'year': {
            format: 'YYYY' + '年',
            caption: '',
            step: 1,
        },
        'month': {
            format: 'MM' + '月',
            caption: '',
            step: 1,
        },
        'date': {
            format: 'DD' + '日',
            caption: '',
            step: 1,
        },
    }


    const handleClick = () => {
        setIsOpen(true);
    }

    const handleCancel = () => {
        setIsOpen(false);
    }

    const handleSelect = (time: Date) => {
        setTime(time);
        getDepartureTime(time);
        setIsOpen(false);
        setDate(true);
        PubSub.publish('six', false);
        PubSub.publish('closeFive', "Six");
    }

    const dataString = time.getFullYear() + "-" + ((time.getMonth() + 1) < 10 ? "0" + (time.getMonth() + 1) : (time.getMonth() + 1)) + "-" + (time.getDate() < 10 ? "0" + time.getDate() : time.getDate());

    PubSub.subscribe('returnSix', (_, data) => {
        setStart(data);
    })

    useEffect(() => {
        setMinTime(Time ? Time : 0)
    })

    useEffect(() => {
        setNowTime(moment().format('YYYY/MM/DD HH:mm'));
    }, [start])

    return (
        <Wrapper>
            <div className="step-six">
                <div className="submitTime">
                    <div style={start ? {display: "block"} : {display: "none"}}
                         className="time"> {ServerEnum.SUBMIT_TIME}{nowTime}</div>
                    <div style={start ? {display: "none"} : {display: "block"}}>&nbsp;</div>
                </div>
                <div className="departure-date" style={start ? {pointerEvents: "none"} : {pointerEvents: "unset"}}
                     onClick={handleClick}>
                    <Badge dot color={color.circle} offset={[4, 11]}
                           style={start ? {display: "none"} : {display: "block"}}>
                        <div className="pro">{ServerEnum.DEPARTURE_DATE}</div>
                    </Badge>
                    <div className="select">
                        <div className="please-select"
                             style={date ? {color: color.loginTitle} : {color: color.loginError}}>{date ? dataString : ServerEnum.REQUEST}</div>
                        <RightOutlined/>
                    </div>
                </div>
            </div>

            <div>
                <DatePicker
                    min={new Date(minTime)}
                    value={time}
                    isOpen={isOpen}
                    theme={'ios'}
                    showCaption={true}
                    customHeader={ServerEnum.DATEPICKER_TITLE}
                    dateConfig={dateConfig}
                    onSelect={handleSelect}
                    onCancel={handleCancel}
                />
            </div>
        </Wrapper>
    )
}
export default DepartureDate;
