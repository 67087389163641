import styled from "styled-components";
import Header from "../../static/img/LoginSucceeded/Header.png"
import '../../static/font/font.css';
import {color} from '../../static/color/color';

const Wrapper = styled.div`
  margin: 0 auto;

  .header {
    background-image: url(${Header});
    background-size: cover;
    background-repeat: no-repeat;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .header img {
    height: 60px;
    width: 60px;
    margin-left: 1.25rem;
  }

  .user {
    font-size: 17px;
    font-weight: 500;
    color: ${color.user};
    line-height: 18px;
    margin-left: 0.75rem;
  }

  .SOM {
    margin-left: 1rem;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
  }

  .option {
    display: flex;
  }

  .option div {
    //height: 53px;
    //width: 60px;
    display: flex;
    flex-direction: column;
  }

  /* 选项图片 */

  .option div img {
    height: 2rem;
    width: 2rem;
  }

  /* 选项文字 */

  .option div span {
    display: inline-block;
    height: 17px;
    font-size: 13px;
    font-weight: 400;
    color: ${color.loginTitle};
    line-height: 17px;
    margin-top: 6px;
  }

  /*控制TLRA审批选项样式*/

  .TLRA {
    margin-left: 1.25rem;
    margin-right: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /*控制现场服务选项样式*/

  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-badge {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  /*当屏幕宽度大于等于1200px时生效 */
  @media (min-width: 1200px) {
    width: 700px;
    .footer {
      width: 700px;
      height: 3.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .body {
      height: 600px;
    }

    .option div img {
      height: 4rem;
    !important;
      width: 4rem;
    !important;
    }

    .header {
      justify-content: start;
    }

    .header img {
      margin-left: 1.25rem;
    }
  }

  /*当屏幕宽度小于等于1200px时生效 */
  @media (max-width: 1200px) {

    .header {
      height: 25vh;
    }

    .body {
      height: 62vh;
    }

    /*退出按钮*/
    .footer {
      width: 100%;
      height: 10vh;
      display: flex;
      justify-content: center;
      align-items: end;
    }
  }

  /*当屏幕宽度大于等于1200px时生效 */
  @media (min-width: 768px) {
    .header {
      height: 330px;
    }
  }

  @media (min-width: 1200px) {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: start;
  }
`;

export {Wrapper}

