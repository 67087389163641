import styled from "styled-components";
import {color} from "../../static/color/color";

export {Wrapper};

const Wrapper = styled.div`

  hr {
    height: 0.5px;
    width: 100%;
    margin-bottom: 0;
    background-color:${color.hr};
    border: none;
  }

  .projectItem-root {
    width: 100%;
  }

  .left-txt {
    height: 100%;
    width: 89.33%;
    display: inline-block;
    float: left;
  }


  .right-pic {
    height: 100%;
    width: 10.66%;
    display: inline-block;
    float: left;
  }

  .heard {
    height: 35%;
    margin: 16px 0 0 4.72%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .title {
    color: ${color.itemTitle};
    font-size: 1rem;

  }

  .bottom {
    height: 41%;
    margin-top: 4px;
    margin-bottom: 16px;
    margin-left: 4.72%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user {
    color: ${color.userDate};
    font-size: 0.75rem;
  }

  .date {
    margin-left: 5.3%;
    color: ${color.userDate};
    font-size: 0.75rem;
  }

  .pic {
    margin-top: 26px;
    margin-left: 10px;
  }

  @media (min-height: 420px) and (max-width: 1200px) {
    hr {
      height: 0.5px;
      width: 100%;
      margin-bottom: 0;
      background-color: ${color.hr};
      border: none;
    }

    .left-txt {
      width: 89.33vw;
      display: inline-block;
      float: left;
    }

    .right-pic {
      width: 10.66vw;
      display: inline-block;
      float: left;
    }

    .heard {
      height: 35%;
      margin: 16px 0 0 4.72vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .title {
      color: ${color.itemTitle};
      font-size: 1rem;

    }

    .bottom {
      height: 41%;
      margin-top: 4px;
      margin-bottom: 16px;
      margin-left: 4.72vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .user {
      color: ${color.userDate};
      font-size: 0.75rem;
    }

    .date {
      margin-left: 5.3vw;
      color: ${color.userDate};
      font-size: 0.75rem;
    }

    .pic {
      margin-top: 26px;
      margin-left: 10px;
    }
  }
`;
