import axios from 'axios';
import {getHeader, saveHeader} from "../utils/Request/storageUtils";
import {message} from "antd";
import store from "store";


const XToken = "x-xsrf-token";
const ClientId = "es-client-id";

export const instance = axios.create({
    baseURL: '',
    headers: {
        "Content-Type": "text/plain",
        "Es-Device": "es-webapp",
        'lang':'zh-CN'
    }
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        const header = getHeader();
        if (config.url === '/es/gdpm/api/somEsrJobThink/insert') {
          store.set("isSend",1);
        } else {
            store.set("isSend",0);
        }
        if (header.clientId) {
            // 设置es-client-id请求头
            config.headers.set(ClientId, header.clientId);

            if (header.token) {
                // 设置x-xsrf-token请求头
                config.headers.set(XToken, header.token);
            }
        }
        // 在发送请求之前做些什么
        // 添加请求头等操作
        return config;
    },
    (error) => {
        if (error.url === '/es/gdpm/api/somEsrJobThink/insert') {
            store.set("isSend",0);
        }
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        if (response.config.url === '/es/gdpm/api/somEsrJobThink/insert') {
            store.set("isSend",0);
        }
        const header: any = response.headers;
        const configHeader: any = response.config.headers;
        if ((response.config["url"] as string).includes("/sso/login") && header) {
            saveHeader({clientId: header.get(ClientId)});
            // 若无法从response.headers取到ClientId，说明用户关闭浏览器，则去response.config.headers寻找
            if (header.get(ClientId) == undefined) {
                saveHeader({clientId: configHeader.get(ClientId)});
            }
        }

        if ((response.config["url"] as string).includes("/identity/islogin")) {
            saveHeader({token: response.headers[XToken]});
        }

        // 登录失效回调地址信息
        const AppId = 'wx2f784899982c4d14'; // 公众号appid
        const RedirectURI = encodeURI("https://sommini.honeywell.cn"); // 回调地址
        const Scope = 'snsapi_base'; // 这里不进行弹窗获取
        switch (response.data.status) {
            case -10205:
            case -10230:
                store.remove("name")
                message.error("登录失效");
                // 退出到回调地址
                window.location.href =
                    `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${RedirectURI}&response_type=code&scope=${Scope}&state=STATE#wechat_redirect`;

                break;
            default:
        }
        return Promise.resolve(response);
    },
    (error) => {
        if (error.url === '/es/gdpm/api/somEsrJobThink/insert') {
            store.set("isSend",0);
        }
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);





