import styled from 'styled-components';
import '../../../../static/font/font.css';
import {color} from '../../../../static/color/color';

const Wrapper = styled.div`

  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal,sans-serif;

  .left-pig {
    height: 24px;
    width: 24px;
    display: inline-block;
    margin: 3px 3px 3px -4px;
    float: left;
  }

  .pig {

    height: 18px;
    width: 18px;
    border-radius: 100%;
    color: ${color.redRound};
    border: 2px solid ${color.redRound};
  }

  .pig-txt {
    font-size: 10px;
    margin: 2.2px 5px 0 5px;;
    line-height: 14px;
    font-weight: 500;
  }

  .right-txt {
    height: 24px;
    border-radius: 4px;
    border: 2px solid ${color.blueRound};
    display: inline-block;
    float: left;
  }

  .txt {
    color: ${color.blueRound};
    margin: 0 auto;
    font-size: 12px;
    white-space: nowrap;
    height: auto;
    padding: 3px 10px 3px 10px;
  }

  .blank {
    display: inline-block;
    float: left;
  }
`;

export {Wrapper};
