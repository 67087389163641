import {instance} from "../util";

/**
 * part-1
 * @param param
 * param={
 *     ""arequiredPermitsComplete": true,
 *      "atoolsAvailable": true,
 *      "aunderstandTaskScope": true,
 *      "jobId": "839e26f9bd784763be3c7a854dd423ca",(lmra第一步主键Id)
 *      "jobTime": "2020-01-01 00:00:00",(提交日期)
 *      "serviceRequestId": "839e26f9bd784763be3c7a854dd423ca",(工程师请求服务id)
 *      "taskDesc": "任务描述"(任务描述)
 * }
 */

const somEsrJob_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEsrJob/insert', param);
    } catch (error) {
        return "Error";
    }
}

/**
 * 查询详情
 * @param param
 * param = {
 *     "serviceRequestId" : "xxxx"（工程师服务id）
 *     "orders" : "xxxx"（排序）
 * }
 */
const somEsrJob_list = async (param: object) => {
    try {
        return await instance.get('/es/gdpm/api/som/somEsrJob/list', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 查询详情
 * @param param
 * param = {
 *     "id" : "xxxx"（jobid）
 * }
 */
const somEsrJob = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/somEsrJob/' + param);
    } catch (error) {
        return "Error";
    }
}


/**
 * part-2
 * 响应:{
 * 	"data": [                                   (数据)
 * 		{
 * 			"createdAt": "",
 * 			"createdBy": "",
 * 			"potentialHazardId": "",
 * 			"thinkId": "",
 * 			"thinkPotentialHazardId": "",
 * 			"updatedAt": "",
 * 			"updatedBy": ""
 * 		}
 * 	],
 * 	"message": "success",                       (消息)
 * 	"status": 0,                                (状态码)
 * 	"time": ""                                  (时间)
 * }
 */

const somEsrJobThinkPpe_list = async () => {
    try {
        return await instance.get('/es/gdpm/api/somEsrJobThinkPpe/list');
    } catch (error) {
        return "Error";
    }
}


/**
 * part-2
 * 响应:{
 * 	"data": [                                   (数据)
 * 		{
 * 			"createdAt": "",
 * 			"createdBy": "",
 * 			"potentialHazardId": "",
 * 			"thinkId": "",
 * 			"thinkPotentialHazardId": "",
 * 			"updatedAt": "",
 * 			"updatedBy": ""
 * 		}
 * 	],
 * 	"message": "success",                       (消息)
 * 	"status": 0,                                (状态码)
 * 	"time": ""                                  (时间)
 * }
 */

const somEsrJobThinkPotentialHazard_list = async () => {
    try {
        return await instance.get('/es/gdpm/api/somEsrJobThinkPotentialHazard/list');
    } catch (error) {
        return "Error";
    }
}


/**
 * part-2
 * @param param
 * param={
 *     "aenergySourcesTested": 0,
 *      "afireHazardsAvoided": 0,
 *      "aproperContainmentReady": true,
 *      "aspillContainmentNeeded": 0,
 *      "jobId": "839e26f9bd784763be3c7a854dd423ca",(lmra第一步主键Id,)
 *      "potentialHazardIdList": [],(潜在隐患id集合)
 *      "potentialHazardOther": "839e26f9bd784763be3c7a854dd423ca",(潜在危险其他描述)
 *       "ppeOther": "839e26f9bd784763be3c7a854dd423ca",()
 *       "somPpeRequestList": [
 *      {
 *          "file": "",(个人防护装备文件)
 *          "hazardControlCode": "839e26f9bd784763be3c7a854dd423ca",(隐患控制code,示例值)
 *          "hazardControlId": "839e26f9bd784763be3c7a854dd423ca",(隐患控制id -和隐患控制code 任意传一个即可)
 *           "ppeCode": "Cut-resistant gioves",(个人防护装备code)
 *          "ppeId": "839e26f9bd784763be3c7a854dd423ca",(个人防护装备 id 新增不传，更新必传)
 *          "ppeName": "抗切断手套"(个人防护装备name)
 *      }
 *      ]
 * }
 */

const somEsrJobThink_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEsrJobThink/insert', param);
    } catch (error) {
        return "Error";
    }
}

/**
 * part-3
 * @param param
 * param={
 *     "jobId": "839e26f9bd784763be3c7a854dd423ca",(lmra主键Id)
 *     "probability": "839e26f9bd784763be3c7a854dd423ca",(概率code,示例值:可能性)
 *     "severity": "839e26f9bd784763be3c7a854dd423ca",(严重程度code,示例值：严重性)
 *     "stopWorkDescReasons": "839e26f9bd784763be3c7a854dd423ca"(?停工的风险和原因,示例值：红色输入框)
 * }
 */

const somEsrJobAssessment_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEsrJobAssessment/insert', param);
    } catch (error) {
        return "Error";
    }
}

/**
 * part-4
 * @param param
 * param={
 *      "jobId": "839e26f9bd784763be3c7a854dd423ca",(lmra主键Id)
 *      "sign": ""(图片以base64形式传值：签名base64)
 * }
 */

const somEsrJobDeclaration_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEsrJobDeclaration/insert', param);
    } catch (error) {
        return "Error";
    }
}


/**
 * part-5
 * @param param
 * param={
 *      "afollowUpRequired": true,(radio的开关)
 *      "followUpRequiredDesc": "跟进工作的描述",(第一个输入框)
 *      "jobId": "839e26f9bd784763be3c7a854dd423ca",(lmra主键Id)
 *      "otherComments": "其他描述",(其他描述，第二个输入框)
 *      "unexpectedHazardsDesc": "意外和危险描述"(红色输入框)
 * }
 */

const somEsrJobReview_insert = async (param: object) => {
    try {
        return await instance.post('/es/gdpm/api/somEsrJobReview/insert', param);
    } catch (error) {
        return "Error";
    }
}
/**
 *返回第二步潜在危险列表
 */
const somPotentialHazard_list = async () => {
    try {
        return await instance.get('/es/gdpm/api/somPotentialHazard/list');
    } catch (error) {
        return "Error";
    }
}
/**
 *返回第二步危害控制和ppe列表
 */
const somHazardControl_list = async () => {
    try {
        return await instance.get('/es/gdpm/api/somHazardControl/list');
    } catch (error) {
        return "Error";
    }
}
/**
 *删除lmra
 */
const somEsrJob_delete = async (param:string) => {
    try {
        return await instance.delete('/es/gdpm/api/somEsrJob/'+param);
    } catch (error) {
        return "Error";
    }
}

export {
    somEsrJob_insert,
    somEsrJob,
    somEsrJob_list,
    somEsrJobThinkPpe_list,
    somEsrJobThinkPotentialHazard_list,
    somEsrJobThink_insert,
    somEsrJobAssessment_insert,
    somEsrJobDeclaration_insert,
    somEsrJobReview_insert,
    somPotentialHazard_list,
    somHazardControl_list,
    somEsrJob_delete,
};

