import styled from "styled-components";
import {color} from "../../../../static/color/color";

const WrapperOne = styled.div`
  .ant-spin .ant-spin-dot{
    margin-top: 50%;
    margin-left: 50vh;
  }

  hr {
    margin: 0;
    border: 0.2px solid ${color.shadow};
  }

  .sign {
    position: relative;
    z-index: 1;
  }

  .cover {
    margin-top: -100vw;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    background-color: ${color.prohibitClicking};
  }

  .top {
    z-index: 200;
    position: absolute;
    margin-top: -2%;
    margin-left: 30%;
  }

  .ant-checkbox-wrapper {
    font-family: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
  }

  .spin {
    margin: 80% 45%;
  }

  .sign-container {
    height: 100vw;
  }

  .head {
    height: 8.8vw;
    margin-bottom: 2vw;
  }

  .draw {
    padding-top: 2vw;
    height: 54.6vw;
  }

  .checked {
    height: 13vw;
  }

  .button {
    height: 18.6vw;
    display: flex;
  }

  .buttonLeft {
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  .buttonRight {
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  @media (max-width: 300px) {
    .head {
      height: 13.8vw;
      width: 100%;
    }

    .draw {
      padding-top: 2vw;
      height: 46.6vw;
    }

    .checked {
      height: 15.6vw;
      padding-bottom: 3vw;
    }

    .button {
      height: 15vw;
      width: 100%;
      margin-bottom: 3vw;
      display: flex;
    }

    .buttonLeft {
      width: 50%;
      color: ${color.lmraButton};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
    }

    .buttonRight {
      width: 50%;
      color: ${color.lmraButton};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
    }
  }

  @media (min-width: 500px) or (min-height: 1000px) {
    .top {
      z-index: 200;
      position: absolute;
      margin-top: -15%;
      margin-left: 20%;
    }
  }
`;
const WrapperTwo = styled.div`

  hr {
    margin: 0;
    border: 0.2px solid ${color.shadow};
  }

  .sign{
    position: relative;
    z-index: 1;
  }
  
  .cover{
    margin-top: -100vh;
    width: 100vw;
    height: 100%;
    z-index: 10;
    position: absolute;
    background-color: ${color.prohibitClicking};
  }

  .top {
    z-index: 200;
    position: absolute;
    margin-top: -18%;
    margin-left: 5%;
  }
  
  .ant-checkbox-wrapper{
    font-family: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
  }

  .spin{
    margin: 25% 50%;
  }
  
    .sign-container{
      height: 100vh;
    }

    .head {
      height: 11vh;
    }

    .draw {
      padding-top: 2vh;
      height: 58.5vh;
    }

    .checked {
      height: 12vh;
    }

    .button {
      height: 15.5vh;
      width: 100%;
      display: flex;
    }

  .buttonLeft {
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  .buttonRight{
    width: 50%;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  @media (max-height: 280px){
    .head {
      height: 9vh;
    }
    
    .draw {
      height: 58.5vh;
    }
    .checked {
      height: 16vh;
    }

    .button {
      height: 13vh;
      width: 100%;
      display: flex;
    }
  }
`;

export {WrapperOne, WrapperTwo};
