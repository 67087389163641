import styled from "styled-components";
import {color} from "../../../../static/color/color";


const Wrapper = styled.div`
  hr {
    height: 1px;
    border: none;
    background-color: ${color.br};
  }

  .Pop-root {
    z-index: 2;
    width: 320px;
    margin-top: -120px;;
    border-radius: 8px;
    background-color: ${color.user};
  }

  .txt {
    text-align: center;
    font-size: 1.0625rem;
    padding: 28px 24px 28px 24px;
  }

  .button {
    padding: 16px 24px 16px 24px;
    color: ${color.lmraButton};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  @media (min-height: 420px) and (max-width: 1300px) {
    hr {
      height: 1px;
      margin-top: 3.4475vh;
      margin-bottom: 0;
      background-color: ${color.br};
      border: none;
    }

    .Pop-root {
      width: 85.3vw;
      margin-top: -35vh;
      border-radius: 8px;
      background-color: ${color.user};
    }

    .txt {
      text-align: center;
      font-size: 1.0625rem;
      padding: 3.4475vh 6.4vw 0 6.4vw;
    }

    .button {
      padding: 1.97vh 6.4vw 1.97vh 6.4vw;
      color: ${color.lmraButton};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
    }

  }

  .my-hr {
    border: none;
    height: 0.5px;
    margin-top: 15px;
    background-color: ${color.hr};
  }


`;

export {Wrapper};
