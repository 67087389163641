import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`

  .step-one {
    padding-left: 16px;
    border-left: solid ${color.button};
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .line-one {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: ${color.loginTitle};
    width: 100%;
    justify-content: space-between;
  }

  .line-two {
    width: 289px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: ${color.loginError};
  }

  .text {
    width: 80%;
  }

`;
export {Wrapper};
