export const color = {
    white: "white", // 白色
    button: "#1274B7", // 按钮颜色
    button10: "#1274B719", // 按钮颜色
    circle: "#be271c", // 登录页面圆圈颜色
    buttonDisabled: "#999", // 按钮禁用颜色
    loginTitle: "#303030", // 登录页面标题颜色
    headBlue:"#E8F1F8", // 头部背景蓝色样式
    error: "rgba(166, 34, 25, 1)", // 错误提示颜色
    loginBackground: "rgba(255, 255, 255, 0.9)", // 登录页面背景色
    exitShadow: "rgba(0,0,0,0.1)", // 退出按钮阴影颜色
    loginBorder: "#00000026", // 登录页面边框色
    loginError: "#A62219", // 登录错误边框颜色
    user: "#FFFFFF", // 登陆成功界面用户颜色#A62219
    TLRAProject: "#707070", // TLRA审批页面以”项目“开头的文字的颜色
    br: "rgba(0, 0, 0, 0.1)", // 全部下划线颜色
    itemTitle: "#303030", // 审核界面标题颜色
    userDate: "rgba(112, 112, 112, 1)", // 审核标题发布者和日期的颜色
    popHr: "#E5E5E5", // 弹窗下划线颜色
    prohibitClicking: "rgba(0, 0, 0, 0.40)", //禁止点击背景色
    blueRound: "#1274B7", // 背景蓝色圆点
    redRound: "#A62219", // 背景红色圆点
    addLMRA: "#F5F5F5", // 增加LMRA行颜色
    confirmForbidden: "#A0A0A0", // 确认按钮禁止点击背景色
    R1: "#7EB338", // R1风险等级颜色
    R2: "#FFDE00", // R2风险等级颜色
    R3: "#F5A623", // R3风险等级颜色
    R4: "#BE271C", // R4风险等级颜色
    risk: "#000000", // 风险等级文字颜色
    bottomLine: "rgba(5, 5, 5, 0.06)", // 下划线颜色
    thanks: "#7EB338", // 我的服务页面最后感谢字体颜色
    tlraFile: "#D0D0D0", // tlra审批页面文件预览盒子边框颜色
    dontClick: "#A0A0A0", // 我的服务界面禁止点击背景色
    drawer:"#007aff", // 弹窗确认取消颜色
    hr:"#E6E6E6", // 下划线颜色
    partThreeBlue:"#E6F7FF",//part-3蓝色危险等级
    partThreeYellow:"#FFE58F",//part-3黄色危险等级
    partThreeRed:"#FFF1F0",//part-3红色危险等级
    collapseHeader:"#D0E9F9",//LMRA折叠面板表头背景色
    boxBG:"#ECECEC",//LMRA选项盒子背景颜色
    lmraButton:"#576B95",//LMRA点击按钮颜色
    cardBorder:"#D0D0D0",//LMRA卡片边框颜色
    shadow:"rgb(238, 238, 238,0.7)",//阴影颜色
    boxborder: '#d9d9d9',//盒子外边框颜色
    signature: '#909090',//签名字体颜色
}
