import React, {CSSProperties} from "react";
import {Wrapper} from "./style";
import {LMRAEnum, LMRAStep1, ServerEnum} from "../../../../enum/field/field";
import UniversalButton from "../../../../components/UniversalButton";
import {color} from "../../../../static/color/color";

interface Prop {
	hidden:boolean
	handleCancel?:()=>void
	handleSubmit?:()=>void
}
const PopWindow = (props:Prop) => {
	const {hidden,handleCancel,handleSubmit} = props;
	const cancel = () => {
		handleCancel && handleCancel();
	}
	const submit = () => {
		handleSubmit && handleSubmit();
	}
	const ButtonStyle:CSSProperties = {
		width:'46%',
		height:'35px',
		lineHeight:'25px',
		fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif",
		textAlign:'center',
		paddingTop:'6px',
	}
	const ButLeftStyle:CSSProperties = {
		...ButtonStyle,
		color:color.button,
		backgroundColor:color.user,
		border:'1px solid',
		boxShadow:'none',
	}
	const ButRightStyle:CSSProperties = {
		...ButtonStyle,
		color:color.user,
		backgroundColor:color.button,
	}
	return(
		<Wrapper>
			<div className="window" hidden={hidden}>
				<header>{LMRAStep1.STEP_ONE_WINDOW_HEAD}</header>
				<article>{LMRAStep1.STEP_ONE_WINDOW_BODY}</article>
				<hr/>
				<footer>
					<UniversalButton name={ServerEnum.DRAWER_CANCEL} disabled={false} onClick={cancel} style={ButLeftStyle}/>
					<UniversalButton name={LMRAEnum.RIGHT_BUTTON} disabled={false} onClick={submit} style={ButRightStyle}/>
				</footer>
			</div>
		</Wrapper>
	)
}
export default PopWindow;
