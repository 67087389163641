import React, {CSSProperties} from "react";
import {Wrapper} from "./style";
import Close from "../../../../static/img/NotesWindow/Close.svg"
import Notification from "../../../../static/img/Service/Important.svg"
import UniversalButton from "../../../../components/UniversalButton";
import {color} from "../../../../static/color/color";
import {ServerEnum} from "../../../../enum/field/field";
import PubSub from "pubsub-js";

interface Prop {
    setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
}

const Waring = (props: Prop) => {
    const buttonRightStyle: CSSProperties = {
        width: "40%",
        height: "36px",
        fontFamily: "SourceHanSansCN-Normal",
        background: `${color.button}`,
    }
    const buttonLeftStyle: CSSProperties = {
        width: "40%",
        height: "36px",
        fontFamily: "SourceHanSansCN-Normal",
        background: `${color.white}`,
        boxShadow:'none',
        color:`${color.button}`,
        border:'1px solid'
    }
    const {setDisplay} = props;
    return (
        <Wrapper>
            <div className="waring">
                <div className="head-waring">
                    <img src={Close} alt="" onClick={() => {
                        setDisplay(false);
                        PubSub.publish('five', false);
                    }} style={{padding: "10px"}}
                    />
                </div>
                <div className="body-waring">
                    <img src={Notification} alt=""/>
                    <div style={{marginLeft: "5px",width:'205px',minWidth:'200px'}}>
                        <div>{ServerEnum.WARNING_CONFIRM}</div>
                        <div>{ServerEnum.CONFIRM_AGAIN}</div>
                    </div>
                </div>
                <div className="footer-waring">
                    <UniversalButton
                        name={ServerEnum.DRAWER_CANCEL}
                        disabled={false}
                        style={buttonLeftStyle}
                        onClick={() => {
                            setDisplay(false);
                        }}
                    />
                    <UniversalButton
                        name={ServerEnum.CONFIRM}
                        disabled={false}
                        style={buttonRightStyle}
                        onClick={() => {
                            setDisplay(false);
                            PubSub.publish('antdFive');

                        }}
                    />
                </div>
            </div>
        </Wrapper>
    )
}

export default Waring;
