import styled from "styled-components";
import {color} from "../../../../static/color/color";


const Wrapper = styled.div`

  .img {
    width: 100%;
    border: 1px solid ${color.cardBorder};
  }
  

  .word {
    width: 90%;
    font-size: 14px;
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
    font-weight: 500;
  }

  .pic {
    display: flex;
    width: 10%;
    justify-content: right;
    align-items: center;
  }

  .button {
    display: flex;
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: ${color.button};
    justify-content: center;
  }
`;

export {Wrapper};
