import styled from 'styled-components';
import Background from '../../static/img/Login/Background.png';
import '../../static/font/font.css';
import {color} from '../../static/color/color';

const Wrapper = styled.div`
  background-image: url(${Background});
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .login-div {
    width: 294px;
    background: ${color.loginBackground};
    border: 1px solid ${color.loginBorder};
    box-shadow: 0 2px 4px ${color.loginBorder};
    margin: auto;
    padding: 80px 40px 40px 40px;
  }

  .title {
    font-size: 24px;
    color: ${color.loginTitle};
    line-height: 33px;
    width: 100%;
    text-align: center;
    margin: auto;
  }

  .logo-div {
    display: flex;
    align-items: center;
    margin: 5px auto 40px;
  }

  .logo {
    width: 55%;
  }

  .safettice {
    border-left: 3px solid;
    width: 45%;
    font-size: 20px;
    margin: auto;
    text-align: center;
  }

  .account-div {
    align-items: center;
  }

  .circle {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: ${color.circle};
    margin-left: 6px;
  }

  .ant-input, .ant-input-affix-wrapper > input.ant-input {
    font-size: 16px;
  }

  .password-div {
    align-items: center;
    margin-top: 20px;
  }

  .ant-btn-primary {
    background-color: ${color.button};

    /*按钮禁用后的背景色*/

    &:disabled {
      background-color: ${color.buttonDisabled};
      color: ${color.white};
    }
  }

  .message {
    font-size: 12px;
    text-align: left;
    color: ${color.error};
  }

  .ant-input, .ant-input:focus, .ant-row, .safettice {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  }

  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    border-color: color${color.loginError};
  }

  /* 当屏幕宽度小于等于400px时生效 */
  @media (max-width: 400px) {
    .login-div {
      width: 80vw;
      padding: 60px 20px 40px 20px;
    }
  }

  /* 当屏幕宽度小于等于280px时生效 */
  @media (max-width: 290px) {
    .login-div {
      width: 80vw;
      padding: 60px 20px 40px 20px;
    }

    .logo-div {
      display: block;
    }

    .logo {
      margin: auto;
    }

    .safettice {
      border-left: none;
      margin: 10px auto;
    }
  }

`;

export {Wrapper};
