import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`

  .cover {
    /*margin-top: -100vw;*/
    width: 100%;
    height: 100%;
    z-index: 15;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${color.prohibitClicking};
  }

  .twoPop{
    z-index: 20;
    position: fixed;
    top: 70%;
    left: 7%;
  }

  @media (width: 1280px) and (height: 800px) {
    .twoPop {
      width: 700px;
      height: 200px;
      z-index: 20;
      margin-left: 5px;
      position: fixed;
      top: unset;
      left: 7%;
    }
  }
  @media (min-width: 1300px)  {
    .cover {
      /*margin-top: -100vw;*/
      justify-content: center;
      align-items: center;
      width: 700px;
      height: 1000px;
      z-index: 15;
      margin-left: -16px;
      position: fixed;
      top: 0;
      left: unset;
      background-color: ${color.prohibitClicking};
    }

    .twoPop {
      width: 700px;
      height: 200px;
      z-index: 20;
      margin-left: 5px;
      position: fixed;
      top: 45%;
      left: unset;
    }
  }

  font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;

  .pro, .time, .column-one, .column-two, .column-three {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .step-five {
    border-left: solid ${color.button};
    padding-left: 16px;
  }

  .add-LMRA-title {
    display: flex;
    background-color: ${color.addLMRA};
    height: 35px;
    align-items: center;
    justify-content: space-between;
  }

  .column-one {
    margin-left: 15px;
    font-size: 12px;
    width: 45%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .column-two {
    display: flex;
    font-size: 12px;
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .column-three {
    font-size: 12px;
    margin-right: 15px;
    width: 35%;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    padding-left: 40px;
  }
  
  .column-three>span {
    color: ${color.button};
  }

  .bin {
    display: flex;
    justify-content: space-between;
  }

  .add, .prompt-text {
    display: flex;
  }

  .file-one {
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fileSpin {
    margin-top: 10%;
  }

  .attachment {
    display: flex;
    align-items: center;
  }

  .ant-upload-wrapper{
    padding-top: 5px;
    padding-left: 5px;
  }

  .add {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: ${color.button};
    justify-content: center;
  }

  .prompt-text {
    font-size: 12px;
    font-weight: 400;
    color: ${color.TLRAProject};
    line-height: 20px;
  }

  .file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 67px;
    height: 21px;
    line-height: 21px;
  }

  .lmra-file {
    display: flex;
  }

`;
export {Wrapper};
