import styled from "styled-components";
import {color} from "../../static/color/color";


const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  z-index: 1;
  

  .Empty {
    padding-top: 70%;
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif;
  }

  .approval-root {
    width: 375px;
    height: 800px;
    border: 1px solid ${color.loginBorder};
    box-shadow: 0 2px 4px ${color.loginBorder};

  }

  .Spin {
    margin-top: 50%;
    margin-left: 50%;
  }
  
  .Empty {
    padding-top: 70%;
  }

  .bed {
    width: 375px;
    position: relative;
    z-index: 1;
  }

  .top {
    z-index: 2;
    position: absolute;
    margin-top: -7%;
  }

  .approval-body {
    height: 90vh;
    margin: 8px auto;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .approval-body::-webkit-scrollbar {
    display: none;
  }

  @media (min-height: 420px) and (max-width: 1200px) {
    .approval-root {
      height: 100vh;
      width: 100vw;
      border: none;
      box-shadow: none;
      overflow: hidden;
    }


    .bed {
      height: 100%;
      width: 100%;
      overflow: auto;
      position: absolute;
      z-index: 1;
    }


    .top {
      z-index: 2;
      margin: 0 auto;
    }

    .approval-body {
      height: 90vh;
      margin: 8px auto;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .approval-body::-webkit-scrollbar {
      display: none;
    }
  }
`;

export {Wrapper};
