import React, {CSSProperties, MutableRefObject, useEffect, useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {WrapperOne, WrapperTwo} from "./style";
import {color} from "../../../../static/color/color";
import Head from "../../../../components/Head";
import {LMRAEnum} from "../../../../enum/field/field";
import {Checkbox, message, Spin} from "antd";
import UniversalButton from "../../../../components/UniversalButton";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import ChangePop from "../../../../components/ChangePop";
import {useNavigate, useParams} from "react-router-dom";
import {somEsrJobDeclaration_insert} from "../../../../api/LMRA/LMRA";

const Signature = () => {
    const can = useRef<any>();

    const html = () => {
        return (
            //外部盒子
            <div className="sign" id="content">
                <Spin  spinning={spin}>
                <div className="sign-container" style={{ opacity: spin? 0: 1}}>
                            {/*头部*/}
                            <div className="head">
                                <Head
                                    name={LMRAEnum.SIGNATURE}
                                    state={'none'}
                                    Return={-1}
                                />
                            </div>
                            {/*画布*/}
                            <div className="draw" id="sign-content" >
                                    <SignatureCanvas
                                        ref={signCanvas}
                                        penColor={color.signature}
                                        canvasProps={{
                                            width: document.documentElement.clientWidth, height:
                                                document.documentElement.clientHeight * 0.585, className: 'sigCanvas'
                                        }}
                                    />
                            </div>
                            <div style={{display:"none"}}>
                                <canvas ref={can}></canvas>
                            </div>
                            {/*复选框*/}
                            <div className="checked">
                                <hr/>
                                <Checkbox onChange={onChange} style={{paddingLeft:"10px"}}>{word}</Checkbox>
                            </div>
                            <hr/>
                            {/*按钮*/}
                            <div className="button">
                                <div className='buttonLeft'>
                                    <UniversalButton name={LMRAEnum.LEFT_BUTTON} disabled={false} onClick={clear}
                                                     style={leftButton}/>
                                </div>
                                <div className='buttonRight'>
                                    <UniversalButton name={LMRAEnum.RIGHT_BUTTON} disabled={!buttonSwitch} onClick={
                                        () => {
                                            if (step) {
                                                if (step === "4") {
                                                    confirmSign()
                                                } else if (step === "5") {
                                                    setHide(true)
                                                }
                                            }
                                        }
                                    } style={buttonSwitch ? rightButton : buttonForbiddenStyle}/>
                                </div>
                            </div>
                        </div>
                </Spin>
                {/*覆盖层*/}
                <div className="cover" style={{display: hide ? "block" : "none"}}></div>
                {/* 弹窗层*/}
                <div style={hide ? {display: "block"} : {display: "none"}} className='top'>
                    <ChangePop
                        top={LMRAEnum.S_PRE}
                        end={LMRAEnum.S_END}
                        leftOnClick={() => {
                            setHide(!hide)
                        }}
                        rightOnClick={() => {
                            confirmSign()
                            setHide(false)
                        }}/>
                </div>
            </div>
        )
    }

    /*路由跳转*/
    const history = useNavigate();
    /*打开弹窗和蒙版*/
    const [hide, setHide] = useState(false);
    /*打开加载中*/
    const [spin, setSpin] = useState(false);
    /*判断横竖屏在return里面*/
    const [Switch] = useState(false);
    /*下面禁用按钮开关*/
    const [buttonSwitch, setButtonSwitch] = useState(false);
    const signCanvas: MutableRefObject<any> = useRef();
    /*获取jobId*/
    const {jobId, step, danger} = useParams();
    /*文字*/
    const [word, setWord] = useState("")
    const [ctx, setCtx] = useState<any>()
    let newcvs = '';
    useEffect(()=>{
        setCtx(signCanvas.current.getCanvas().getContext("2d"));
    },[])


    //左边按钮样式
    const leftButton: CSSProperties = {
        width: "150px",
        height: "36px",
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif",
        background: `${color.user}`,
        color: `${color.button}`,
        border: "2px solid",
    }

    //右边按钮确认样式
    const rightButton: CSSProperties = {
        width: "150px",
        height: "36px",
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif",
        background: `${color.button}`,
        color: `${color.user}`,
        border: "2px solid",
    }

    //右边按钮禁用样式
    const buttonForbiddenStyle: CSSProperties = {
        width: "150px",
        height: "36px",
        backgroundColor: color.dontClick,
        color: color.user,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }

    const changePic = () => {
        if (signCanvas.current){
            const  cvss =signCanvas.current.getCanvas();
            if (cvss){
                const  img =new Image();
                img.src = cvss.toDataURL('image/png');
                const {width,height} = cvss;
                img.onload = function () {
                    cvss.width = height
                    cvss.height = width
                    ctx.translate(cvss.width, 0)
                    ctx.rotate(Math.PI/180*90)
                    ctx.drawImage(img, 0, 0)
                    newcvs = cvss.toDataURL('image/png');
                }
            }
        }
    }

    // 竖屏模式下横过来，横屏状态下不变
    useEffect(() => {
        // 竖屏模式下横过来，横屏状态下不变
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        if (width < height) {
            detectOrient();
        }

            if (danger === "HH" || danger === "MH" || danger === "HM"){
                setWord(LMRAEnum.FOUR_WORD_ONE+danger+LMRAEnum.FOUR_WORD_TWO)
            } else {
                setWord(LMRAEnum.FOUR_TRUE_WORD)
            }
    }, [])

    //保存
    const confirmSign = () => {
        setSpin(!spin)
        if (signCanvas.current) {
        changePic()
        setTimeout(() => {
            changePic()
        }, 200)
        setTimeout(() => {
            changePic()
        }, 400)
        setTimeout(() => {
            if (newcvs!==''){
                const param = {
                    jobId: jobId,
                    sign:  newcvs
                }
                somEsrJobDeclaration_insert(param).then((res) => {
                    setTimeout(() => {
                        setSpin(!spin)
                        history(-1)
                        if (res != "Error")
                            if (res.data.status === -100016)
                                message.error("该步骤已提交。");
                    }, 200)
                })
            }

        }, 450)
    }
    }

    //清除
    const clear = () => {
        signCanvas.current?.clear();
    };
    //画布
    const detectOrient = function (id = 'content') {
        //先将整个h5页面翻转
        const width = document.documentElement.clientWidth,
            height = document.documentElement.clientHeight,
            $wrapper = document.getElementById(id)
        let style = '', canvasStyle = '';
        style += 'width:' + height + 'px;';
        style += 'height:' + width + 'px;';
        style += '-webkit-transform: rotate(90deg); transform: rotate(90deg);';
        style += '-webkit-transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
        style += 'transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;';
        if ($wrapper !== null)
            $wrapper.style.cssText = style;
        //将签名还原翻转，就可以保证在横屏情况下保证画笔的方向跟手势一致，然后再进行高度和宽度的调整。
        const parentElement = document.getElementById("sign-content");
        if (parentElement !== null) {
            const pw = parentElement.clientWidth;
            const ph = parentElement.clientHeight;
            parentElement.style.cssText = 'height: ' + ph + 'px;/*padding: 0 20px;*/';
            if (signCanvas.current) {
                const canvasElement = signCanvas.current.getCanvas();
                canvasElement.height = pw;
                canvasElement.width = ph;
                canvasStyle += '-webkit-transform: rotate(-90deg); transform: rotate(-90deg);';
                canvasStyle += '-webkit-transform-origin: ' + ph / 2 + 'px ' + ph / 2 + 'px;';
                canvasStyle += 'transform-origin: ' + ph / 2 + 'px ' + ph / 2 + 'px;';
                canvasStyle += 'background: #FFFFFF';

                canvasElement.style.cssText = canvasStyle;
            }
        }
    }

    //复选框点击状态
    const onChange = (e: CheckboxChangeEvent) => {
        setButtonSwitch(e.target.checked)
    };

    return (
        document.documentElement.clientWidth < document.documentElement.clientHeight ?
            <WrapperOne>
                {Switch ?
                    <Spin className="spin"/> :
                    html()}
            </WrapperOne> :
            <WrapperTwo>
                {Switch ?
                    <Spin className="spin"/> :
                    html()}
            </WrapperTwo>
    )
}
export default Signature;
