import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`
  //内容背景色
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: ${color.user};
  }

  //表头背景色
  .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    background-color: ${color.addLMRA};
  }

  //展开时表头背景色
  .myCollapse > .ant-collapse-item:last-child .ant-collapse-header {
    background-color: ${color.collapseHeader};
  }

  //表头右侧时间
  .ant-collapse-extra span {
    color: ${color.TLRAProject};
  }

  //表头左侧文字
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    color: ${color.itemTitle};
  }

  .ant-collapse-content-box p {
    margin-top: 0;
    padding-top: 12px;
  }
  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text{
    font-weight: bold;
  }

`;

export {Wrapper};
