import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import ProjectItem from "../../components/ProjectItem";
import Head from "../../components/Head";
import {color} from "../../static/color/color";
import FiledPop from "../../components/FiledPop";
import {otherEnum, ServerEnum} from "../../enum/field/field";
import {getName, getUser} from "../../utils/RememberPassword/storageUtils";
import {som_getFieldService} from "../../api/fieldServer";
import {Empty, Spin} from "antd";

export default function FieldServer() {

    // 控制禁止点击
    const [hide, setHide] = useState(false);
    //加载中开关
    const [spin, setSpin] = useState(true);


    // 我的现场服务查询
    const [data, setData] = useState([]);
    useEffect(() => {
        som_getFieldService(getUser().eid).then(res => {
            if (res != "Error") {
                setData(res.data.data);
                setSpin(false)
            }
        })
    }, [getUser().eid])

    return (
        <Wrapper>

            {/*整体div房间*/}
            {spin ? <div>
                    <Spin tip="Loading..." className="Spin">
                    </Spin>
                </div>
                :
            <div className="approval-root" style={hide ?
                {pointerEvents: "none", backgroundColor: color.prohibitClicking} :
                {pointerEvents: "unset", backgroundColor: color.user}
            }>

                {/*弹窗下层*/}
                <div className='bed'>
                    {/*头部*/}
                    <div className="head">
                        <Head
                            name={ServerEnum.SERVER}
                            state={'inline-block'}
                            Return={'/LoginSucceeded'}
                            onClick={() => {
                                setHide(!hide);
                            }}
                        />
                    </div>

                    {/*身体*/}
                    <div className="approval-body">
                        {
                            // 没有数据则提示暂无数据
                            data.length === 0 ? (<Empty
                                    style={hide ? {display: "none"} : {display: "block"}}
                                    className="Empty"
                                    description={otherEnum.NO_WORD}/>) :
                                // 有则遍历数据
                                (data.map((item) => {
                                    return (
                                        <ProjectItem
                                            itemNumber={item['projectNo']}
                                            projectContent={item['projectDesc']}
                                            user={getName()}
                                            id={getUser().eid}
                                            time={item['estEntryDate']}
                                            into='/Service'
                                            serviceId={item['serviceRequestId']}
                                            key={item['estEntryDate']}
                                            backGround={hide}
                                        />
                                    )
                                }))
                        }
                    </div>
                </div>
            </div>
            }

            {/*弹窗层*/}
            <div style={hide ? {display: "block"} : {display: "none"}} className='top'>
                <FiledPop onClick={() => {
                    setHide(!hide)
                }}/>
            </div>
        </Wrapper>
    )
}


