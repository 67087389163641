import React from "react";
import {Wrapper} from "./style";
import {color} from "../../static/color/color";

interface Prop {
    /**
     * 风险等级
     */
    name: string;
}

const Block = (props: Prop) => {
    const {name} = props;
    return (
        <Wrapper>
            <div style={{
                backgroundColor: name === "R1" ? color.R1 : (name === "R2" ? color.R2 : (name === "R3") ? color.R3 : color.R4),
                color: name === "R4" ? color.user : color.risk
            }}>
                <div className="risk-name">{name}</div>
            </div>
        </Wrapper>
    )
}
export default Block;
