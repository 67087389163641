import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import Checkbox from "../../../../static/img/Service/Checkbox.svg";
import Selected from "../../../../static/img/Service/CheckboxSelected.svg";
import {ServerEnum} from "../../../../enum/field/field";
import moment from "moment";

interface Prop {
    itemNumber: string,
    projectContent: string
}

const RiskIdentification = (props: Prop) => {
    const {itemNumber, projectContent} = props;
    const [pic, setPic] = useState(Checkbox);
    const [nowTime, setNowTime] = useState("")
    const [start, setStart] = useState(false)
    const handelPic = () => {
        if (pic === Checkbox) {
            setPic(Selected);
            PubSub.publish('one', false)
        } else {
            setPic(Checkbox)
        }
    }

    PubSub.subscribe('returnOne', (_, data) => {
        setStart(data)
    })

    useEffect(() => {
        setNowTime(moment().format('YYYY-MM-DD HH:mm'))
    }, [start])

    return (
        <Wrapper>
            <div className="step-one">
                <div className="submit-time" style={start ? {display: "block"} : {display: "none"}}>
                    {ServerEnum.SUBMIT_TIME}{nowTime}
                </div>
                <div className="line-one">
                    <div
                        className="text">{ServerEnum.RISK_IDENTIFICATION_TEXT}{itemNumber}-{projectContent}{ServerEnum.RISK_IDENTIFICATION_EDN}</div>
                    <img src={pic} alt="" width="20px" height="20px" onClick={handelPic}/>
                </div>
                <div className="line-two">{ServerEnum.RISK_IDENTIFICATION_REMIND}</div>
            </div>
        </Wrapper>
    )
}
export default RiskIdentification;
