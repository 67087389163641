import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;

  .window {
    width: 335px;
    height: 226px;
    background: ${color.user};
    border-radius: 4px;
    backdrop-filter: blur(20px);
    border: 1px solid ${color.loginBorder};
    position: fixed;
    left: 50%;
    top: 50%;
    max-width: 700px;
    max-height: 800px;
    margin-left: -167.5px;
    margin-top: -113px;

  }

  .title {
    display: flex;
    justify-content: center;
    height: 22px;
    font-size: 17px;
    font-weight: 500;
    color: ${color.itemTitle};
    line-height: 22px;
  }

  .close {
    display: flex;
    justify-content: flex-end;
  }

  .sub-title {
    margin-left: 14px;
    margin-bottom: 5px;
  }

  .text-area {
    margin: 0 12px;
  }

  .footer-window {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .ant-input:placeholder-shown {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  }

  .ant-input {
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal, BlinkMacSystemFont, sans-serif;
  }

`;

export {Wrapper}
