import {instance} from "../util";
import {EsEncryptService} from "../../utils/encrypt/encrypt.service";
import axios from "axios";


let promisePl: Promise<string> | null;
const encryptService = new EsEncryptService();


/**
 * 登录接口
 * @param param
 * param = {
 *     "account": “fyy”（账号）
 *     "password": "123456"（密码）
 *     "id": "es"（应用编号）
 * }
 */
const sso_login = async (param: object) => {
    try {
        if (!await initKey())
            return "KeyError";
        return await instance.post('/api/sso/login', encryptService.encryptObject(param), {withCredentials: true});
    } catch (error) {
        return "Error";
    }
}


const initKey = async (forceUpdate: boolean = false) => {
    const key = await getKey(forceUpdate);
    if (key) {
        encryptService.setPublicKey(key);
        return true;
    }
    return false;
}


const getKey = (forceUpdate: boolean) => {
    if (!promisePl || forceUpdate) {
        promisePl = getEncryptKey()
            .then(
                (res) => {
                    return res.data.data;
                },
                () => {
                    promisePl = null;
                    return null;
                }
            );
    }
    return promisePl;
}


const getEncryptKey = async () => {
    return instance.get('/api/sso/key');
}


/**
 * 退出登录接口
 */
const logout = async () => {
    try {
        return await instance.post('/es/api/identity/logout', {});
    } catch (error) {
        return "Error";
    }
}


/**
 * 获取openid
 */
const som_getOpenId = async (param: string) => {
    try {
        return await axios.get('/es/gdpm/api/som/getOpenId', {params: {code: param}});
    } catch (error) {
        return "Error";
    }
}


/**
 * 判断openid与eid是否对应
 */
const som_isCorrespond = async (param: object) => {
    try {
        return await axios.post('/es/gdpm/api/som/isCorrespond', param);
    } catch (error) {
        return "Error";
    }
}

export {sso_login, logout, som_getOpenId, som_isCorrespond};
