import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`
  .mask {
    position: fixed;
    z-index: 3;
    height: 100vh;
    width: 100vw;
    background: #fff;
    opacity: 0.5;
  }

  .ant-drawer-title {
    justify-content: center;
    display: flex;
  }
 
  :global {
    .ant-drawer .ant-drawer-body {
      padding-top: 0;
    }
  }

  margin: 0 auto;

  /*使页面滚动条出现不产生页面抖动*/
  padding-left: calc(100vw - 100%);
  /* 默认的字体样式 */
  font-size: 12px;
  font-weight: 400;
  color: ${color.loginTitle};
  display: flex;
  flex-direction: column;

  .style {
    margin-top: 45%;
    margin-left: 50%;
  }

  .Spin {
    margin-top: 50%;
    margin-left: 50%;
  }

  .SpinAll {
    margin-top: 80%;
    margin-left: 50%;
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    color: ${color.itemTitle};
  !important;
  }

  .project-num {
    width: 50%;
    margin-bottom: 8px;
    margin-top: 15px;
  }

  .expected-entry {
    margin-bottom: 8px;
    margin-top: 15px;
    width: 50%;
  }

  .ant-collapse-header-text {
    position: relative;
    z-index: 1;
  }

  .ant-collapse-extra {
    position: absolute;
    z-index: 2;
  }

  /* 以“项目"开头的文字的样式” */

  .project-message {
    font-size: 12px;
    font-weight: 400;
    word-break: break-all;
    word-wrap: break-word;
    white-space: normal;
    color: ${color.loginTitle};
  }

  .line-one, .project-message, .expected-entry {
    display: flex;
  }

  .project-message + div {
    justify-content: flex-end;
  }

  .body-head {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .head-left {
    margin-left: 12px;
    font-size: 15px;
    font-weight: 500;
    color: ${color.loginTitle};
    line-height: 21.6px;
  }

  .head-right {
    margin-right: 12px;
    font-size: 12px;
    font-weight: 400;
    color: ${color.loginTitle};
    line-height: 21.6px;
  }

  .submit-time {
    font-size: 12px;
    margin-bottom: 15px;
    color: ${color.button},
  }

  .thanks {
    padding: 16px 16px;
    font-size: 12px;
    margin-left: 0;
    color: ${color.thanks};
  }


  /*当屏幕宽度大于等于1200px时生效 */
  @media (min-width: 1200px) {
    width: 700px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    .forbidden {
      position: relative;
      z-index: 1;
    }


    .forbidden-two {
      width: 700px;
      height: 105vh;
      position: absolute;
      z-index: 2;
      background-color: ${color.prohibitClicking};
    }

    .footer-service {
      width: 700px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .all {
      height: 800px;

    }

    .scoller {
      max-height: 500px;
      margin: auto;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .scoller::-webkit-scrollbar {
      display: none;
    }


    .main {
      height: 618px;
    }
  }

  /*当屏幕宽度小于等于1200px时生效 */
  @media (max-width: 1200px) {
    .forbidden {
      z-index: 1;
    }

    .forbidden-two {
      width: 100vw;
      height: 160vh;
      position: absolute;
      z-index: 2;
      background-color: ${color.prohibitClicking};
    }

    .footer-service {
      bottom: 0;
      width: 100%;
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      left: 0;
    }

    .all {
      height: 91.5vh;
    }


    .scoller {
      max-height: 40vh;
      margin: auto;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .scoller::-webkit-scrollbar {
      display: none;
    }
  }

  .submit-time {
    font-size: 12px;
    margin-bottom: 15px;
    color: ${color.button},
  }

  .body-scoller {
    max-height: 80vh;
    margin: auto;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .body-scoller::-webkit-scrollbar {
    display: none;
  }

  .ant-drawer .ant-drawer-content {
    border-radius: 1rem 1rem 0 0;
  }

`;
export {Wrapper};
