import {instance} from "../util";


/**
 * 我的现场服务查询
 * @param param
 * param = {
 *     "EId" : "fyy"（当前登录人员eid）
 * }
 * response = [
 *     {
 *       "estEntryDate": 1691724434000,（预计入场时间）
 *       "projectDesc": "SERVIC",（项目描述）
 *       "projectNo": "46695714",（项目号）
 *       "serviceRequestId": "1cea1020f8f34adab4a17dc25a886b1d",（工程师服务id）
 *       "updatedAt": 1694866021000（更新时间）
 *     },
 */
const som_getFieldService = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/som/getFieldService/' + param);
    } catch (error) {
        return "Error";
    }
}


export {som_getFieldService};
