import styled from "styled-components";
import {color} from "../../../../static/color/color";

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  position: absolute;

  .waring {
    width: 90vw;
    height: 190px;
    background: ${color.user};
    border-radius: 4px;
    backdrop-filter: blur(20px);
    border: 1px solid ${color.loginBorder};
    position: fixed;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 700px;
    max-height: 800px;
  }

  .head-waring {
    display: flex;
    justify-content: flex-end;
  }

  .body-waring {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

  .footer-waring {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 23px;
    max-height: 40px;
  }


`;

export {Wrapper};
