

export enum LoginEnum {

    /*登录页面账号输入框默认内容*/
    ACCOUNT_PLACEHOLDER = "请输入员工号",

    /*登录页面账号输入框默认内容*/
    PASSWORD_PLACEHOLDER = "请输入密码",

    /*账号*/
    ACCOUNT = "账号",

    /*密码*/
    PASSWORD = "密码",

    NULL = "NULL",

    /*账户锁定*/
    ACCOUNT_EXPIRED = "您的账户已过期，请与管理员联系",

    /*微信消息推送*/
    NOT_CORRESPOND = "当前登录账号与绑定微信号不一致",

    /*微信消息推送*/
    OPENID_ERROR = "获取微信号失败，请与管理员联系",

    /*账户锁定*/
    ACCOUNT_LOCK = "您的账户已被锁定，请联系管理员进行解锁！",

    /*登录按钮*/
    LOGIN_BUTTON = "登录",

    /*无效的用户名或密码*/
    INVALID = "无效的用户名或密码",

    /*登录数限制*/
    LIMIT = "当前登录用户已达最大登录数9个!",

    /*设置密码*/
    RESET = "请前往Web端重新设置密码",

    /*过期*/
    EXPIRED = "密码/验证码过期",


}


export enum TLRAEnum {

    /*TLRA审批*/
    TLRA_APPROVE = "TLRA审批",

    /*现场服务*/
    FIELD_SERVICE = "现场服务",

    /*退出登录按钮*/
    EXIT_BUTTON = "退出登录",

    /*同意*/
    AGREE = "同意",

    /*同意原因*/
    AGREE_REASON = "同意原因",

    /*拒绝*/
    REFUSE = "拒绝",

    /*拒绝原因*/
    REFUSE_REASONS = "拒绝原因",

    /*确认按钮*/
    SURE_BUTTON = "确认",

    /*审批页面文本框默认内容*/
    TEXT_PLACEHOLDER = "请输入",

    /*备注*/
    NOTE = "备注",

    /*TLRA审批列表标题*/
    FIELD_TITLE = "TLRA审批列表",

    /*项目号*/
    PROJECT = "项目号：",

    /*预计入场*/
    ENTRANCE = "预计入场日期:  ",

    /*项目秒速*/
    DESCRIBE = "项目描述：",

    /*提交时间*/
    SUBMIT_TIME = "提交时间:",

    /*提交人*/
    SUBMIT_PEOPLE = "提交人:",

    NOR_REQUIRED = "非必填",

    NETWORK_ERROR = "网络异常"

}

export enum ServerEnum{

    /*我的现场服务标题*/
    SERVER = "我的现场服务",

    /*弹窗提示信息*/
    MESSAGE = "列表仅显示在电脑端完成了工程师现场服务申请中1-2步骤的服务",

    /*我知道了按钮*/
    KNOW_BUTTON = "我知道了",

    /*现场服务头部左侧文字*/
    HEAD_LEFT = "开展工作前（现场）",

    /*流程5提示文字*/
    PROMPT_TEXT = "当所有现场工作完成后，请点击完成本次现场服务，然后填写离场日期",

    /*现场服务流程5标题字段*/
    WORK_TIME = "工作时间",

    LMRA_SCHEDULE = "LMRA进度",

    OPTIONS = "操作",

    /*现场服务添加LMRA附件文字*/
    ADD_LMRA = "添加LMRA",

    /*选择客户要求是否高于霍尼韦尔提交时间文字*/
    REQUEST = "请选择",

    /*低于霍尼韦尔要求选择栏文字*/
    BLOW_REQUIREMENT = "如低于霍尼韦尔要求，按照霍尼韦尔要求执行",

    /*流程4名称*/
    HIGH_REQUIREMENT = "客户要求是否高于霍尼韦尔要求",

    /*流程5名称*/
    SUBMIT_TLRA = "请每次作业前填写LMRA",

    /* 日期选择器标题 */
    DATEPICKER_TITLE = "请选择离场日期",


    /* 日期选择器标题 */
    WORK_TITLE = "请选择工作时间",

    /* 底部抽屉标题 */
    DRAWER_TITLE = "请上传LMRA附件",

    /* 抽屉拍照选项 */
    DRAWER_PICTURE = "拍照",

    /*抽屉上传文件选项*/
    DRAWER_FILE = "上传文件",

    /*抽屉上传图片选项*/
    DRAWER_PHOTO = "上传图片",

    /*抽屉取消选项*/
    DRAWER_CANCEL = "取消",

    /*流程6离场日期选项*/
    DEPARTURE_DATE = "离场日期",

    /*流程1文字内容*/
    RISK_IDENTIFICATION_TEXT = "我确认知晓 SOM-",

    RISK_IDENTIFICATION_EDN = "项目合同风险分类结果，且知晓相应的重要风险!",

    /*流程1红色警告文字*/
    RISK_IDENTIFICATION_REMIND = "风险等级已发生变更，需要工程师重新确认!",

    /*流程1标题*/
    RISK_CONFIRM = "风险确认",

    LEAVE_TIME = "离开现场服务的日期",

    WARNING_CONFIRM = "请确认是否提交？",

    CONFIRM = "确认",

    CONFIRM_AGAIN = "是否已完成本次所有的现场服务工作？是否回到公司/家或者前往下一个现场？",

    YES = "是",

    NO = "否",

    SUBMIT = "提交",

    FINISH="完成本次现场服务",

    PROJECT_DESCRIPTION = "项目描述：",

    THANK="感谢您的配合，客户现场服务SOM已完成！",

    NONE = "none",

    FLEX = "flex",

    /*预计入场*/
    ENTRANCE="预计入场日期:  ",

    /*提交时间*/
    SUBMIT_TIME = "提交时间:",

    EDIT = "编辑",

    CHECK = "查看",

    DELETE = "删除",


}

export enum LMRAEnum{
    /*head*/
    LMRA = "Last Minute Risk Assessment",
    SIGNATURE = "请签名",

    /*part-3*/
    PART_THREE = "评估控制措施就位后的风险",
    POSSIBILITY = "严重性",
    POSSIBILITY_H = "Major Injury",
    POSSIBILITY_M = "Serious Injury",
    POSSIBILITY_L = "Minor Injury",
    SERIOUSNESS = "可能性",
    SERIOUSNESS_H = "Probable",
    SERIOUSNESS_M = "Possible",
    SERIOUSNESS_L = "Remote",

    DANGER_BLUE = "风险值为LL",
    DANGER = "风险值为",
    NEWRED="高风险HH、HM、MH提醒邮件增加HSE公共邮箱，文案呢改动涉及公众号。",
    DANGER_YELLOW = "请将额外的控制措施就位后再进行工作",
    NEWYELLOW_WORD="额外的风险控制措施：",
    YELLOW_WORD = "额外的控制措施是否就位？  ",
    YELLOW_YES = "是",
    YELLOW_NO = "否",
    DANGER_RED = "请停止工作！",
    RED_WORD = "请描述具体风险及停止工作的原因：  ",
    INPUT = "请输入",
    HORIZONTAL = "-",

    /*part-4*/
    FOUR_WORD_ONE=  "声明 - 评估控制措施就位后的风险仍为",
    FOUR_WORD_TWO=  "，行使停止工作权，待所有风险被控制在可接受范围后将重新填写LMRA。",
    FOUR_TRUE_WORD="声明 - 我认可对工作小组、他人和环境的风险水平在合理可能的范围内尽可能低，并且有足够的控制措施已就位。",
    ADD_SIGNATURE = "添加签名",
    BAD = "获取图片Base64失败",

    /*Signature*/
    LEFT_BUTTON = "重写",
    RIGHT_BUTTON = "提交",
    S_PRE="提交LMRA",
    S_END="系统将会邮件通知您的项目经理、直线经理",

    /*part-5*/
    TXT_ONE = "描述在完成此工作任务期间出现的任何意外危害因素：",
    FIVE_RADIO = "是否需要采取后续行动？",
    TXT_RED = "请描述具体行动内容：",
    TXT_TWO = "其他备注：",

    /*RadioPop*/
    TXT_PRE="请",
    TXT_CENTER="停止工作",
    TXT_END="并在开始工作之前解决该问题。",
    CONFIRM="确认",

    /*ChangePop*/
    CANCELLATION="取消",

    /* LMRA页面底部按钮文字 */
    BOTTOM_BUTTON = "下一步",

    /* LMRA第2步第4小步标题 */
    PART_TWO_TITLE_FOUR = "5 识别潜在的危害因素",

    /* LMRA第2部标题右侧警告 */
    PRAT_TWO_TITLE_WARNING = "请至少选择一个或填写其他",

    /* LMRA第二步其它标题 */
    OTHERS = "其他:",

    /*LMRA第2步第5小步标题*/
    PART_TWO_TITLE_FIVE = "6.危害控制和PPE",

    /*LMRA第2步第6小步标题*/
    PART_TWO_TITLE_SIX = "7. 所有的能量源是否被隔离和测试？",

    /*LMRA第2步第6小步标题下方提示文字*/
    PART_TWO_TITLE_SUB = "能量源：包括电能、机械能、液压能、气动能、化学能、热能、势能、动能和任何其他形式的能量。",

    /*LMRA第2步第7小步标题*/
    PART_TWO_TITLE_SEVEN = "8. 威胁生命的危害因素是否被避免？",

    /*LMRA第2步第8小步标题*/
    PART_TWO_TITLE_EIGHT = "9. 是否需要泄漏控制措施？",

    /*LMRA第2步最后一小步*/
    PART_TWO_TITLE_LAST = "正确的防护措施是否就位？",

    /* 第二步单选按钮文字 */
    INAPPLICABLE = "不适用",

    THREE_PRE_OWN = "请将额外的控制措施就位后",

    THREE_END_OWN = "再进行工作",

    THREE_PRE_DOL = "提交后，系统会将该风险情况邮件通",

    THREE_END_DOL = "知您的项目经理、直线经理及HSE",

    TOW_B_PRE = "请将正确的防卫措施措施就位后",

}

export enum otherEnum{

    NO_WORD="暂无数据",

    APPROVAL="TLRA审批列表",

    IS_DELETE="确认是否删除",

    SURE="确认",
}

export enum LMRAStep1 {
    TIME_TITLE = "工作时间",
    PER_WORK_DESCREPTION = "请描述即将开展的工作任务",
    PHYSICAL_CONDITION = '身体状况',
    CHOOSE_ONE = '1.今天的身体状况是否适合执行今天的工作？',
    CHOOSE_TOW = "2.是否了解工作任务的范围?",
    CHOOSE_THREE = "3.所有的工具是否完好并就位?",
    CHOOSE_FOUR = "4.所有的工作许可是否已完成?",
    YES = "是",
    NO = "否",
    DATEPICKER_TITLE = "请选择工作时间",
    STEP_ONE_WINDOW_HEAD = '请停止工作',
    STEP_ONE_WINDOW_BODY = '提交后，系统会将您的身体情况邮件通知您的项目经理及直线经理',
}

