import styled from "styled-components";
import {color} from "../../static/color/color";

const Wrapper = styled.div`
  .s_type {
    border: none;
    background-color:${color.user};
    padding: 7px 0;
    color: ${color.loginTitle};
    margin: 5px 2.5% 5px 0;
    position: relative;
    height: 50px;
  }


  .select {
    background-color: ${color.button10};
    color: ${color.button};
  }

  .select:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border: 9px solid ${color.button};
    border-top-color: transparent;
    border-left-color: transparent;
  }

  .select:after {
    content: '';
    width: 2px;
    height: 5px;
    position: absolute;
    right: 3px;
    bottom: 5px;
    border: 1px solid #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
  }

  .ant-card .ant-card-cover img {
    padding: 0;
    border-radius: 0;
  }

  .ant-card .ant-card-body {
    padding: 0;
    border-radius: 0;
  }

  .ant-card {
    border-radius: 0;
  }

  .s_type {
    width: 100%;
    margin: 0;
  }

  .my-card {
    border: 1px solid ${color.cardBorder};
  }
  .card-selected {
    border: 1px solid ${color.button};
  }
`;
export {Wrapper};