import React, {CSSProperties, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Input, message, Row} from "antd";
import UniversalButton from "../../components/UniversalButton";
import Show from '../../static/img/Login/Show.png';
import Hide from '../../static/img/Login/Hide.png';
import HoneywellLogo from '../../static/img/Login/Branding.svg';
import {LoginEnum} from '../../enum/field/field';
import {Wrapper} from "./style";
import {som_getOpenId, som_isCorrespond,sso_login} from '../../api/loginApi'
import {getName, getUser, saveUser} from "../../utils/RememberPassword/storageUtils";


const Login = () => {
    const [accountValue, setAccountValue] = useState(getUser().eid ? getUser().eid : ""); // 账号框内容
    const [passwordValue, setPasswordValue] = useState(getUser().password ? getUser().password : ""); // 密码框内容
    const [disabled, setDisabled] = useState(true); // 按钮是否可点击
    const [errorMes, setErrorMes] = useState(LoginEnum.NULL); // 错误信息
    const [messageApi, contextHolder] = message.useMessage(); // 服务器异常错误提示
    const navigate = useNavigate(); // 路由跳转

    /**
     * 按钮样式
     */
    const buttonStyle: CSSProperties = {
        width: "100%",
        height: "38.4px",
        marginTop: "40px",
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif"
    }

    const handleAccount = (event: { target: { value: React.SetStateAction<string> } }) => {
        setAccountValue(event.target.value);
    };

    const handlePassword = (event: { target: { value: React.SetStateAction<string> } }) => {
        setPasswordValue(event.target.value);
    };

    /**
     * 错误弹窗提示
     * @param error
     */
    const errorWindow = (error: string) => {
        messageApi.open({
            type: 'error',
            content: error,
        });
    }


    /**
     * 微信鉴权：一个微信号对应一个eid
     */
    const isCorrespond = (token: string) => {
        const url = window.location.href;
        // 从当前网址中取出code，调用后端接口换取微信号
        som_getOpenId(url.substring(url.indexOf("=") + 1, url.indexOf("&"))).then(res => {
            if (res != "Error" && res.data.status == 0) {
                // 获取微信号成功则进行微信鉴权
                som_isCorrespond({
                    eid: accountValue,
                    openid: res.data.data
                }).then(res => {
                    // 确认一个微信号对应一个eid后，即可登录成功
                    if (res != "Error" && res.data.data) {
                        navigate('/loginSucceeded');
                        const data = {
                            eid: accountValue,
                            password: passwordValue,
                            token: token
                        }
                        saveUser(data) // 登录成功存储用户信息
                    } else
                        setErrorMes(LoginEnum.NOT_CORRESPOND); //微信鉴权失败
                })
            } else
                setErrorMes(LoginEnum.OPENID_ERROR); //获取微信号失败
        })
    }

    //  登录响应事件
    const login = () => {
        sso_login({
            account: accountValue,
            password: passwordValue,
            id: 'es'
        }).then(res => {
            if (res != "Error") {
                if (res !== "KeyError") {
                    switch (res.data.status) {
                        // 无效的用户名或密码
                        case -1: {
                            setErrorMes(LoginEnum.INVALID);
                            break;
                        }
                        // 登录成功
                        case 0: {
                            isCorrespond(res.data.data);
                            break;
                        }
                        // 账户过期
                        case -10206: {
                            setErrorMes(LoginEnum.ACCOUNT_EXPIRED);
                            break;
                        }
                        // 账户锁定
                        case -10207: {
                            setErrorMes(LoginEnum.ACCOUNT_LOCK);
                            break;
                        }
                        // 无效的用户名或密码
                        case -10208: {
                            setErrorMes(LoginEnum.INVALID);
                            break;
                        }
                        // 当前登录用户已达最大登录数9个
                        case -10231: {
                            setErrorMes(LoginEnum.LIMIT);
                            break;
                        }
                        // 密码需重置
                        case -10215: {
                            setErrorMes(LoginEnum.RESET);
                            break;
                        }
                        // 密码/验证码过期
                        case -10232: {
                            setErrorMes(LoginEnum.EXPIRED);
                            break;
                        }

                    }
                } else {
                    errorWindow("初始化Key错误")
                }
            } else {
                errorWindow("服务器异常")
            }
        })
    }


    useEffect(() => {
        // 判断输入框是否为空，为空则按钮不可点击
        accountValue !== "" && passwordValue !== "" ? setDisabled(false) : setDisabled(true);
    }, [accountValue, passwordValue])


    useEffect(() => {
        if (getUser().eid && getName() && window.location.href.includes("code=")) {
            login();
        }
    }, [])

    return (
        <Wrapper>
            {contextHolder}
            <div className="login-div">
                <div className="title">HPS Safety Operation Management</div>

                <div className="logo-div">
                    <div className="logo"><img src={HoneywellLogo} alt=""/></div>
                    <div className="safettice">Safettice</div>
                </div>

                <form className="form">
                    <Row className="account-div">
                        <label>{LoginEnum.ACCOUNT}</label>
                        <div className="circle"></div>
                    </Row>

                    <Input
                        status={errorMes === LoginEnum.NULL ? "" : "error"}
                        placeholder={LoginEnum.ACCOUNT_PLACEHOLDER}
                        onChange={handleAccount}
                        onClick={() => {
                            setErrorMes(LoginEnum.NULL)
                        }}
                        value={accountValue}
                    />

                    <Row className="password-div">
                        <label>{LoginEnum.PASSWORD}</label>
                        <div className="circle"></div>
                    </Row>

                    <Input.Password
                        status={errorMes === LoginEnum.NULL ? "" : "error"}
                        placeholder={LoginEnum.PASSWORD_PLACEHOLDER}
                        iconRender={(visible) => (visible ? <img src={Show} alt=''/> : <img src={Hide} alt=''/>)}
                        onChange={handlePassword}
                        onClick={() => {
                            setErrorMes(LoginEnum.NULL)
                        }}
                        value={passwordValue}
                    />

                    <UniversalButton
                        name={LoginEnum.LOGIN_BUTTON}
                        disabled={disabled}
                        style={buttonStyle}
                        onClick={() => {
                            login();
                        }}
                    />
                    <div className="message"
                         style={errorMes !== LoginEnum.NULL ? {display: "block"} : {display: "none"}}>
                        {errorMes}
                    </div>
                </form>
            </div>
        </Wrapper>

    );
}

export default Login;
