import {instance} from "../util";


/**
 * 身份判别接口
 * @param param
 * param = {
 *     "EId" : "fyy"（当前登录人员eid）
 * }
 */
const isPM = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/som/isPM/' + param);
    } catch (error) {
        return "Error";
    }
}


/**
 * 验证用户登录状态接口
 */
const identity_islogin = async (param: string) => {
    try {
        return await instance.post('/es/api/identity/islogin' + param);
    } catch (error) {
        return "Error";
    }
}


export {isPM, identity_islogin};
