import React, {CSSProperties, useEffect, useState} from "react";
import {Wrapper} from "./style";
import {LMRAEnum, ServerEnum, TLRAEnum} from "../../enum/field/field";
import Block from "../../components/Block";
import MyProgress from "../../components/MyProgress";
import MyCollapse from "../../components/MyCollapse";
import UniversalButton from "../../components/UniversalButton";
import PartThree from "./components/PartThree";
import PartTwo from "./components/PartTwo";
import PartOne from "./components/PartOne";
import Head from "../../components/Head";
import PartFive from "./components/PartFive";
import {color} from "../../static/color/color";
import PartFour from "./components/PartFour";
import * as store from "store";
import RadioPop from "./components/RadioPop";
import ChangePop from "../../components/ChangePop";
import {somEsrJob} from "../../api/LMRA/LMRA";
import {useParams} from "react-router-dom";
import {getUser} from "../../utils/RememberPassword/storageUtils";
import dayjs from "dayjs";
import {Spin} from "antd";
import {Helmet} from 'react-helmet';

const LMRA = () => {
    const {projectNo, projectDesc} = store.get("ServiceItem");
    const [risk, setRisk] = useState("R1");
    // 总步数
    const [steps, setSteps] = useState(5);
    const [currentStep, setCurrentStep] = useState(0);
    const [isClicked, setIsClicked] = useState(true);
    // 控制折叠面板显示
    const [showOne, setShowOne] = useState("none");
    const [showTwo, setShowTwo] = useState("none");
    const [showThree, setShowThree] = useState("none");
    const [showFour, setShowFour] = useState("none");
    const [showFive, setShowFive] = useState("none");
    // 控制折叠展开
    const [isActiveOne, setIsActiveOne] = useState(false);
    const [isActiveTwo, setIsActiveTwo] = useState(false);
    const [isActiveThree, setIsActiveThree] = useState(false);
    const [isActiveFour, setIsActiveFour] = useState(false);
    const [isActiveFive, setIsActiveFive] = useState(false);
    // 步骤完成时间
    const [stepOneTime, setStepOneTime] = useState("");
    const [stepTwoTime, setStepTwoTime] = useState("");
    const [stepThreeTime, setStepThreeTime] = useState("");
    const [stepFourTime, setStepFourTime] = useState("");
    const [stepFiveTime, setStepFiveTime] = useState("");
    // 蒙版
    const [hide, setHide] = useState(false);

    /*单按钮的值*/
    const [preOwn, setPreOwn] = useState("");
    const [endOwn, setEndOwn] = useState("");

    /*双按钮的值*/
    const [preDbl, setPreDbl] = useState("");
    const [endDbl, setEndDbl] = useState("");

    /*按钮开关*/
    const [own, setOwn] = useState(false);
    const [double, setDouble] = useState(false);

    const [canRequest, setCanRequset] = useState(false);

    const [serious, setSerious] = useState(false);

    const [isSpin, setIsSpin] = useState(false);

    //控制第一步邮件弹窗
    const [isHidden,setIsHidden] = useState(true);
    //第一步第一个选项的值
    const [isFit,setIsFit] = useState(1);

    const refresh = (status: number, firstHandler: () => void, secondHandler: () => void) => {
        if (status === -100016) {
            firstHandler();
        } else {
            secondHandler();
        }
        sendData();
    }
    const params = useParams();
    const jobId = params.jobId;
    useEffect(() => {
          sendData();
    }, [jobId])
    const sendData = () => {
        setIsSpin(true);
        if (currentStep === 0) {
            if (params.jobId) {
                if (params.jobId === "0") {
                    setShowOne("block");
                    setIsActiveOne(true);
                    setIsSpin(false);
                }
            }
        }
        if (jobId != "0" && jobId) {
            somEsrJob(jobId).then(res => {
                if (res != "Error") {
                    if (res.data.status === 0) {
                        setCurrentStep(res.data.data.stepIndex);
                        if (!res.data.data.somEsrJobResponse.aPhysicalConditionFitWork)
                            setSteps(1);
                        store.set("LMRA", res.data.data);
                        switch (res.data.data.stepIndex) {
                            case 1:
                                setRisk(res.data.data.riskLeave);
                                setStepOneTime(dayjs(res.data.data.somEsrJobResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setShowOne("block");
                                setIsActiveOne(false);
                                if (params.forbidden === "check" || !res.data.data.somEsrJobResponse.aPhysicalConditionFitWork)
                                    break;
                                setShowTwo("block");
                                setIsActiveTwo(true);
                                break;
                            case 2:
                                setRisk(res.data.data.riskLeave);
                                setStepOneTime(dayjs(res.data.data.somEsrJobResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepTwoTime(dayjs(res.data.data.somEsrJobThinkResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setShowOne("block");
                                setIsActiveOne(false);
                                setIsActiveTwo(false);
                                setShowTwo("block");
                                if (params.forbidden === "check")
                                    break;
                                setShowThree("block");
                                setIsActiveThree(true);
                                break;
                            case 3:
                                setRisk(res.data.data.riskLeave);
                                if (res.data.data.somEsrJobAssessmentResponse.severity === "H" && res.data.data.somEsrJobAssessmentResponse.probability === "H"
                                    || res.data.data.somEsrJobAssessmentResponse.severity === "H" && res.data.data.somEsrJobAssessmentResponse.probability === "M"
                                    || res.data.data.somEsrJobAssessmentResponse.severity === "M" && res.data.data.somEsrJobAssessmentResponse.probability === "H"
                                )
                                    setSteps(4);
                                setStepOneTime(dayjs(res.data.data.somEsrJobResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepTwoTime(dayjs(res.data.data.somEsrJobThinkResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepThreeTime(dayjs(res.data.data.somEsrJobAssessmentResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setIsActiveOne(false);
                                setIsActiveTwo(false);
                                setIsActiveThree(false);
                                setShowOne("block");
                                setShowTwo("block");
                                setShowThree("block");
                                if (params.forbidden === "check")
                                    break;
                                setShowFour("block");
                                setIsActiveFour(true);
                                break;
                            case 4:
                                setRisk(res.data.data.riskLeave);
                                setStepOneTime(dayjs(res.data.data.somEsrJobResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepTwoTime(dayjs(res.data.data.somEsrJobThinkResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepThreeTime(dayjs(res.data.data.somEsrJobAssessmentResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepFourTime(dayjs(res.data.data.somEsrJobDeclarationResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setIsActiveOne(false);
                                setIsActiveTwo(false);
                                setIsActiveThree(false);
                                setIsActiveFour(false);
                                setShowOne("block");
                                setShowTwo("block");
                                setShowThree("block");
                                setShowFour("block");
                                if (res.data.data.somEsrJobAssessmentResponse.severity === "H" && res.data.data.somEsrJobAssessmentResponse.probability === "H"
                                    || res.data.data.somEsrJobAssessmentResponse.severity === "H" && res.data.data.somEsrJobAssessmentResponse.probability === "M"
                                    || res.data.data.somEsrJobAssessmentResponse.severity === "M" && res.data.data.somEsrJobAssessmentResponse.probability === "H"
                                ) {
                                    setSteps(4);
                                    break;
                                } else {
                                    if (params.forbidden === "check")
                                        break;
                                    setShowFive("block");
                                    setIsActiveFive(true);
                                }
                                break;
                            case 5:
                                setRisk(res.data.data.riskLeave);
                                setStepOneTime(dayjs(res.data.data.somEsrJobResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepTwoTime(dayjs(res.data.data.somEsrJobThinkResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepThreeTime(dayjs(res.data.data.somEsrJobAssessmentResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepFourTime(dayjs(res.data.data.somEsrJobDeclarationResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setStepFiveTime(dayjs(res.data.data.somEsrJobReviewResponse.updatedAt).format('YYYY-MM-DD HH:mm'));
                                setIsActiveOne(false);
                                setIsActiveTwo(false);
                                setIsActiveThree(false);
                                setIsActiveFour(false);
                                setIsActiveFive(false);
                                setShowOne("block");
                                setShowTwo("block");
                                setShowThree("block");
                                setShowFour("block");
                                setShowFive("block");
                                break;
                        }
                    }
                    setIsSpin(false)
                }
            })
        }
    };
    const buttonStyle: CSSProperties = {
        width: "100%",
        height: "38.4px",
        backgroundColor: isClicked ? color.dontClick : color.button,
        color: color.user,
        fontFamily: "HoneywellSansWeb-Medium, SourceHanSansCN-Normal, sans-serif",
        lineHeight: "28px",
    }
    return (
        <Wrapper>
            <Helmet>
                <meta property="apple-mobile-web-app-capable" content="yes"/>
                <meta name="viewport"
                      content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            </Helmet>
            <Spin rootClassName="spin" spinning={isSpin}>
                <div className="total" style={{opacity: isSpin ? 0 : 1}}>
                    {/*隔板*/}
                    <div className="cover" style={{display: hide ? "block" : "none"}}></div>

                    <div className="top">
                        <Head name={"Last Minute Risk Assessment"} state={"none"} Return={`/Service/${projectNo}`}/>
                        <MyProgress steps={steps} currentStep={currentStep}
                                    strokeColor={steps === 5 ? color.R1 : color.R4}/>
                    </div>
                    <div className="scoller">
                        <div className="header">
                            <div className="line-one">
                                <div className="project-num">{TLRAEnum.PROJECT}{projectNo}<Block name={risk}/></div>
                                <div className="submit-person">
                                    <div style={{lineHeight: "17px"}}>{store.get("name")}({getUser().eid})</div>
                                </div>
                            </div>
                            <div
                                className="project-description">{TLRAEnum.DESCRIBE}{projectDesc}</div>
                        </div>
                        <div className="body">
                            <div style={{display: showOne}}>
                                <MyCollapse thisKey={1} description={"Part 1 - Stop"} finishedTime={stepOneTime}
                                            activeKey={0}
                                            isActive={isActiveOne}
                                            setIsActive={setIsActiveOne}>
                                    <PartOne
                                        currentStep={currentStep}
                                        setCurrentStep={setCurrentStep}
                                        hide={hide}
                                        setHide={setHide}
                                        setIsFit={setIsFit}
                                        setIsSpin={setIsSpin}
                                        isHidden={isHidden}
                                        setIsHidden={setIsHidden}
                                        setStepOneTime={setStepOneTime} state={currentStep} onClick={() => {
                                        setOwn(true);
                                        setHide(true);
                                    }} setIsClicked={setIsClicked} request={canRequest} setRequest={setCanRequset}/>
                                </MyCollapse>
                            </div>
                            <div style={{display: showTwo}}>
                                <MyCollapse thisKey={2} description={"Part 2 - Think"} finishedTime={stepTwoTime}
                                            activeKey={0} isActive={isActiveTwo}
                                            setIsActive={setIsActiveTwo}>
                                    <PartTwo
                                        setIsSpin={setIsSpin}
                                        refresh={refresh}
                                        onClickA={() => {
                                            setPreOwn("")
                                            setEndOwn("")
                                            setOwn(true);
                                            setHide(true);
                                        }}
                                        onClickB={() => {
                                            setOwn(true);
                                            setHide(true);
                                            setPreOwn(LMRAEnum.TOW_B_PRE);
                                            setEndOwn(LMRAEnum.THREE_END_OWN);
                                        }}
                                        request={canRequest}
                                        setRequest={setCanRequset}
                                        setStepTwoTime={setStepTwoTime}
                                        currentStep={currentStep}
                                        setIsClicked={setIsClicked}
                                    />
                                </MyCollapse>
                            </div>
                            <div style={{display: showThree}}>
                                <MyCollapse thisKey={3} description={"Part 3 - Assessment"} finishedTime={stepThreeTime}
                                            activeKey={0}
                                            isActive={isActiveThree}
                                            setIsActive={setIsActiveThree}>
                                    <PartThree
                                        setIsSpin={setIsSpin}
                                        refresh={refresh}
                                        request={canRequest}
                                        setRequest={setCanRequset}
                                        setStepThreeTime={setStepThreeTime}
                                        state={currentStep}
                                        onClick={() => {
                                            setTimeout(() => {
                                                setOwn(true);
                                                setHide(true);
                                            }, 100)
                                            setPreOwn(LMRAEnum.THREE_PRE_OWN);
                                            setEndOwn(LMRAEnum.THREE_END_OWN);
                                        }}
                                        setIsClicked={setIsClicked}
                                        setSerious={setSerious}
                                    />
                                </MyCollapse>
                            </div>
                            <div style={{display: showFour}}>
                                <MyCollapse thisKey={4} description={"Part 4 - Declaration"} finishedTime={stepFourTime}
                                            activeKey={0}
                                            isActive={isActiveFour}
                                            setIsActive={setIsActiveFour}>
                                    <PartFour request={canRequest} setRequest={setCanRequset} step={steps}
                                              setStepFourTime={setStepFourTime} state={currentStep}
                                              setIsClicked={setIsClicked} onClick={() => {
                                    }}/>
                                </MyCollapse>
                            </div>
                            <div style={{display: showFive}}>
                                <MyCollapse thisKey={5} description={"Part 5 - Review"} finishedTime={stepFiveTime}
                                            activeKey={0}
                                            isActive={isActiveFive}
                                            setIsActive={setIsActiveFive}>
                                    <PartFive
                                        setIsSpin={setIsSpin}
                                        refresh={refresh}
                                        request={canRequest} setRequest={setCanRequset}
                                        setStepFiveTime={setStepFiveTime} state={currentStep}
                                        setIsClicked={setIsClicked}
                                    />
                                </MyCollapse>
                            </div>
                        </div>
                    </div>
                    {/*  <button onClick={() => {
                    scrollToAnchor(anchorRefTwo)
                }}></button>*/}
                    <div className="footer"
                         style={{display: currentStep === steps || params.forbidden === "check" ? "none" : "block"}}>
                        <UniversalButton disabled={isClicked}
                                         name={currentStep === 4 || (params.forbidden === "add" && isFit === 2) ?  ServerEnum.SUBMIT : LMRAEnum.BOTTOM_BUTTON}
                                         style={buttonStyle} onClick={() => {
                            if (params.forbidden === "add" && isFit === 2) {
                                setIsHidden(!isHidden);
                                setHide(!hide);
                            } else if (currentStep === 2 && serious) {
                                setHide(true);
                                setDouble(true);
                                setPreDbl(LMRAEnum.THREE_PRE_DOL);
                                setEndDbl(LMRAEnum.THREE_END_DOL);
                            } else {
                                setIsClicked(true);
                                setCanRequset(true);
                                setCurrentStep(currentStep + 1);
                            }
                        }}/>
                    </div>
                </div>
                {/*单按钮弹窗*/}
                <div className="onePop" style={{display: own ? "block" : "none"}}>
                    <RadioPop pre={preOwn} end={endOwn} onClick={() => {
                        setHide(false);
                        setOwn(false)
                    }}/>
                </div>
                {/*双按钮弹窗*/}
                <div className="twoPop" style={{display: double ? "block" : "none"}}>
                    <ChangePop
                        top={preDbl}
                        end={endDbl}
                        leftOnClick={() => {
                            setHide(false);
                            setDouble(false)
                        }}
                        rightOnClick={() => {
                            setIsClicked(true);
                            setCanRequset(true);
                            setCurrentStep(currentStep + 1);
                            setHide(false);
                            setDouble(false)
                        }}
                    />
                </div>
            </Spin>
        </Wrapper>
    )
}
export default LMRA;

