import React from 'react';
import styled from 'styled-components';
import {HashRouter, Route, Routes} from 'react-router-dom';
import Login from "./Login";
import LoginSucceeded from "./LoginSucceeded";
import TLRA from "./TLRA";
import Approval from "./Approval";
import FieldServer from "./FieldServer";
import Service from "./Service";
import LMRA from "./LMRA";
import Signature from "./LMRA/components/Signature";

const Wrapper = styled.div`
`;


const Layout = () => {
    return (
        <Wrapper>
            <HashRouter>
                <>
                    <Routes>
                        <Route path='/' element={<Login/>}/>
                        <Route path='/loginSucceeded' element={<LoginSucceeded/>}/>
                        <Route path='/TLRA/:projectNo' element={<TLRA/>}/>
                        <Route path='/approval' element={<Approval/>}/>
                        <Route path='/fieldServer' element={<FieldServer/>}/>
                        <Route path='/service/:projectNo' element={<Service/>}/>
                        <Route path='/lmra/:jobId/:forbidden' element={<LMRA/>}/>
                        <Route path='/Signature/:jobId/:step/:danger' element={<Signature/>}/>
                    </Routes>
                </>
            </HashRouter>
        </Wrapper>
    )
};

export default Layout;
