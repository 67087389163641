import React, {memo} from "react";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

interface Prop {
    path: string
}

const FileViewer = memo(function (props: Prop) {

    const config = {
        header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false,
        },
        csvDelimiter: ",", // "," as default,
        pdfZoom: {
            defaultZoom: 1.3, // 1 as default,
            zoomJump: 0.5, // 0.1 as default,
        },
        pdfVerticalScrollByDefault: false, // false as default
    };

    return (
        <DocViewer
            documents={
                [
                    {uri: props.path}, // Remote file
                ]
            }
            config={config}
            pluginRenderers={DocViewerRenderers}
        />
    );
})

FileViewer.displayName = ""

export default FileViewer;
