import React, {useEffect, useState} from "react";
import {Wrapper} from "./style";
import Mask from "../../../../static/img/Service/Add.svg";
import {LMRAEnum} from "../../../../enum/field/field";
import {useNavigate, useParams} from "react-router-dom";
import {Checkbox} from "antd";
import store from "store";

interface Prop {
    /**
     * 传入状态
     */
    state: number;

    /**
     * 点击后的回调函数
     */
    onClick: () => void;

    /**
     * 点击按钮点击状态
     */
    setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
    setStepFourTime: React.Dispatch<React.SetStateAction<string>>;
    request: boolean;
    setRequest: React.Dispatch<React.SetStateAction<boolean>>;


    /**
     * 传入步骤步数
     */
    step: number;
}

const PartFour = (props: Prop) => {
    /*路由跳转*/
    const history = useNavigate();
    /*设置页面禁止点击*/
    const [noClick, setNoclick] = useState(true);
    /*复选框按钮隐藏添加*/
    const [add, setAdd] = useState(true)
    /*图片和添加按钮互换*/
    const [check, setCheck] = useState(true)
    /*标签*/
    const [base64, setBase64] = useState("")
    /*文字*/
    const [word, setWord] = useState("")
    /*获取全局data的值*/
    const data = store.get("LMRA");
    const somEsrJobDeclarationResponse = data.somEsrJobDeclarationResponse;
    const somEsrJobAssessmentResponse = data.somEsrJobAssessmentResponse;

    /*旋转图片所需要的款*/
    const ph = String(window.document.documentElement.clientWidth - 24) + "px";

    /*获取jobId*/
    const {jobId} = useParams();

    /*复选框状态*/
    useEffect(() => {
        if (props.state >= 4) {
            if (somEsrJobDeclarationResponse.sign !== undefined) {
                setCheck(false)
                setAdd(false)
                setBase64(somEsrJobDeclarationResponse.sign)
            }
        }
    }, [somEsrJobDeclarationResponse])

    /*点击按钮后设置禁用状态*/
    useEffect(() => {
        if (props.state >= 4) {
            setNoclick(false)
        }
    }, [props.state])

    /*获取第三步状态值*/
    useEffect(()=>{
        if ((somEsrJobAssessmentResponse.probability === "H" && somEsrJobAssessmentResponse.severity === "H") || (somEsrJobAssessmentResponse.probability === "M" && somEsrJobAssessmentResponse.severity === "H") || (somEsrJobAssessmentResponse.probability === "H" && somEsrJobAssessmentResponse.severity === "M")){
            setWord(LMRAEnum.FOUR_WORD_ONE+somEsrJobAssessmentResponse.severity+somEsrJobAssessmentResponse.probability+LMRAEnum.FOUR_WORD_TWO)

        } else {
            setWord(LMRAEnum.FOUR_TRUE_WORD)
        }
    },[somEsrJobAssessmentResponse])

    return (
        <Wrapper>
            {/*整体盒子*/}
            <div className="partFour-root" style={{pointerEvents: noClick ? "unset" : "none"}}>
                {/*头部*/}
                <div style={{display: "flex"}}>
                    {/*文字*/}
                    <div className="word">
                        {word}
                    </div>
                    {/*复选框*/}
                    <div className="pic" style={{display: check ? "none" : "flex"}}>
                        <Checkbox checked={!check}></Checkbox>
                    </div>
                </div>
                {/*进入按钮*/}
                <div className="button" style={{display: add ? "flex" : "none", marginTop: "20px"}}
                     onClick={() => {
                         history(`/Signature/${jobId}/${props.step}/${somEsrJobAssessmentResponse.probability+somEsrJobAssessmentResponse.severity}`)
                     }}>
                    <img src={Mask} alt=""/>
                    <div className="pro">{LMRAEnum.ADD_SIGNATURE}</div>
                </div>
                {/*图片*/}
                <div style={{display: add ? "none" : "inline-block" }}>
                    <div className="outBox" style={{height: ph}}>
                        <img src={`${base64}`} alt={LMRAEnum.BAD} className="img"/>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default PartFour;
