import {instance} from "../util";


/**
 * TLRA审批列表查询
 * @param param
 * param = {
 *     "EId" : "fyy"（当前登录人员eid）
 * }
 * response = [
 *    {
 *       "estEntryDate": 1691392397000,(预计入场时间)
 *       "filePath": "/som/serviceRequest/tlra/bc45a225b1584abc8e9688b987fedce9.XLSX",（文件路径）
 *       "projectDesc": "SERVICES",（项目描述）
 *       "projectNo": "46555244",（项目号）
 *       "submitBy": "ys",（提交人eid）
 *       "submitByName": "wys",（提交人姓名）
 *       "submitTime": 1694157215000,（提交时间）
 *       "tlraId": "7bcee4015299429bb87a4a2942e29621"（工程师服务请求tlra文件id，审批同意拒绝需要用到）
 *     },
 * ]
 */
const som_getTLRAList = async (param: string) => {
    try {
        return await instance.get('/es/gdpm/api/som/getTLRAList/' + param);
    } catch (error) {
        return "Error";
    }
}


export {som_getTLRAList};
