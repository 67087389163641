import React, {CSSProperties} from "react";
import {Button, ConfigProvider} from "antd";

interface Prop {
    /**
     * 按钮名称
     */
    name: string;

    /**
     * 是否禁用
     */
    disabled: boolean;

    /**
     * 按钮样式
     */
    style?: CSSProperties;

    /**
     * 点击事件
     */
    onClick?: () => void;

}

const UniversalButton = (props: Prop) => {
    const {name, disabled, style, onClick} = props;

    return (
        <ConfigProvider autoInsertSpaceInButton={false}>
            <Button type="primary" disabled={disabled} style={style} onClick={onClick}>
                {name}
            </Button>
        </ConfigProvider>
    );

}

export default UniversalButton;
