import styled from "styled-components";

const Wrapper = styled.div`
  
  .line {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
  
  span {
    font-size: 15px;
    line-height: 30px;
    font-family: HoneywellSansWeb-Medium, SourceHanSansCN-Normal,sans-serif;
    margin-top: 6px;
  }
  
  .ant-progress-line{
    margin-bottom: 0;
  }
  
`;
export {Wrapper};