import React, {useEffect} from 'react';
import Layout from "./pages";


function App() {
    const AppId = 'wx2f784899982c4d14'; // 公众号appid
    const RedirectURI = encodeURI("https://sommini.honeywell.cn"); // 回调地址
    const Scope = 'snsapi_base'; // 这里不进行弹窗获取
    const url = window.location.href // 当前网址


    useEffect(() => {
        // 如果当前网址不是微信重定向页面，就跳转到获取获取 code 的网址
        if (!url.includes("code=")) {
            window.location.href =
                `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${RedirectURI}&response_type=code&scope=${Scope}&state=STATE#wechat_redirect`;
        }
    }, []);

    return (
        <div className="App">
            <Layout />
        </div>
    );
}

export default App;
