import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {Drawer, DrawerProps, message, Progress, Spin, Upload, UploadProps} from "antd";
import moment from "moment";
import DatePicker from 'react-mobile-datepicker';
import {Wrapper} from "./style";
import Mask from "../../../../static/img/Service/Add.svg";
import {otherEnum, ServerEnum} from "../../../../enum/field/field";
import {color} from "../../../../static/color/color";
import {
    somEngineerServiceRequest_get,
    somEngineerServiceRequestJob_delete,
    somEngineerServiceRequestJob_insert,
} from "../../../../api/Server";
import {RcFile} from "antd/es/upload";
import store from "store";
import {somEsrJob_delete, somEsrJob_insert} from "../../../../api/LMRA/LMRA";

import {useNavigate} from "react-router-dom";
import ChangePop from "../../../../components/ChangePop";


interface File {
    time: string,
    timeNumber: number,
    jobId: string,
    serviceRequestId: string,
    probability: string,
    severity: string,
    stepIndex: number,
    aPhysicalConditionFitWork:boolean
}

interface props {
    /**
     * 仅作为记住第四步存在
     */
    Props?: { state: boolean, array: File[], time: number }

    /**
     * 用于传入最小时间
     */
    Time?: number

    /**
     * 用于传入最小时间
     */
    onClick?: () => void


}

const AddLMRA = (Prop: props) => {
    const storeData = store.get("ServiceItem");
    const serviceRequestId = storeData.serviceRequestId;
    const projectDesc = storeData.projectDesc;
    const navigate = useNavigate(); // 路由跳转
    const {Props, Time, onClick} = Prop;
    const [snap, setSnap] = useState("flex");
    const [display, setDisplay] = useState("none");
    // DatePicker值
    const [time, setTime] = useState(new Date());
    // 控制DatePicker显示
    const [isOpen, setIsOpen] = useState(false);
    const [start, setStart] = useState(false);
    // 点击提交立刻获取的时间
    const [nowTime, setNowTime] = useState("");
    // 后端返回的时间
    const [passTime, setPassTime] = useState(0);
    const [minTime, setMinTime] = useState(0);
    const [times, setTimes] = useState(0);
    const [myFile, setMYFile] = useState<RcFile>();
    const currIndex = useRef(0);
    // 控制Drawer显示
    const [open, setOpen] = useState(false);
    const [placement] = useState<DrawerProps['placement']>('bottom');
    const [state, setstate] = useState("");
    const [hide, setHide] = useState(false);
    const [arrIndex, setArrIndex] = useState(0);
    const [arrJobId, setArrJobId] = useState("");

    const [isDeleted, setIsDeleted] = useState(true);

    // 进度条步骤数
    let file: File = {
        time: "",
        timeNumber: 0,
        jobId: "",
        serviceRequestId: "",
        probability: "",
        severity: "",
        stepIndex: 0,
        aPhysicalConditionFitWork:true
    }
    let tempFiles: File[] = [];
    const [files, setFiles] = useState(tempFiles);
    // fies[0].stepIndex === files[0]
    const headerStyle: CSSProperties = {
        borderBottom: "none",
    }

    const bodyStyle: CSSProperties = {
        height: "25%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px solid" + color.loginBorder
    }

    const optionStyle: CSSProperties = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        color: `${color.button}`,
        background: `${color.user}`,
        height: "25%",
    }

    const optionCancelStyle: CSSProperties = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        color: `${color.button}`,
        background: `${color.user}`,
        height: "25%",
    }

    const optionPhotoStyle: CSSProperties = {
        display: snap,
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "400",
        color: `${color.button}`,
        background: `${color.user}`,
        height: "25%",
    }

    const contentStyle: CSSProperties = {
        width: "100%",
        height: " 50%",
        overflow: "auto",
        background: color.user,
        pointerEvents: "auto",
        borderRadius: "1rem 1rem 0 0"
    }


    const data = new Date(passTime);
    const PassTime = passTime !== 0 ?data.getFullYear() + "/" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "/" +
        (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
        (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
        (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()) : ""

    const dateConfig = {
        'year': {
            format: 'YYYY' + '年',
            caption: '',
            step: 1,
        },
        'month': {
            format: 'MM' + '月',
            caption: '',
            step: 1,
        },
        'date': {
            format: 'DD' + '日',
            caption: '',
            step: 1,
        },
        'hour': {
            format: 'hh' + '时',
            caption: '',
            step: 1,
        },
        'minute': {
            format: 'mm' + '分',
            caption: '',
            step: 1,
        },
    }


    PubSub.subscribe('returnFive', (_, data) => {
        setStart(data);
    })

    PubSub.subscribe('closeFive', (_, data) => {
        setstate(data);
    })

    useEffect(() => {
        setNowTime(moment().format('YYYY-MM-DD HH:mm'));
        setTime(moment().toDate())
    }, [start, isOpen])

    const onClose = () => {
        setOpen(false);
    };
    // TODO 弹窗
    // 删除LMRA附件
    const handleDelete = (index: number, jobId: string) => {
        if (jobId === "") return;
        somEsrJob_delete(jobId).then(() => {
            const tempFiles: File[] = [...files];
            tempFiles.splice(index, 1);
            setFiles(tempFiles);
            PubSub.publish('fiveDelete');
        })
    }

    useEffect(() => {
        setMinTime(Time ? Time : 0);
        setSnap(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? "flex" : "none");
        setIsSpin(true)
        somEngineerServiceRequest_get(serviceRequestId).then(res => {
            if (res != "Error")
                if (res.data.data.lmraTime) {
                    setIsDeleted(false);
                    if (store.get("isSend") === '1') {
                        let exit = true;
                        while (exit) {
                            if (store.get("isSend") === '0')
                            exit = false;
                        }
                    }
                    setIsSpin(false);
                } else {
                    setIsSpin(false);
                }
        })
    }, [])

    // 如果没有LMRA附件则隐藏表头
    useEffect(() => {
        if (files.length === 0) {
            setDisplay(ServerEnum.NONE);
        }
    }, [files])

    useEffect(() => {
        if (Props !== undefined) {
            setClose(!Props?.state);
            if (Props?.state !== undefined) {
                setStart(true);
            }
            setDisplay("flex")
            if (Props?.array) {
                setFiles(Props?.array);
                setPassTime(Props?.time)
            }
        }
    }, [start])

    useEffect(() => {
        setStart(false);
        if (Props === undefined) {
            if (files.length !== 0) {
                setDisplay("flex")
            } else {
                setDisplay("none")
            }
        } else {
            setDisplay("flex")
        }
    }, [files.length])

    const handleCancel = () => {
        setIsOpen(false);
    }
    const handleSelect = (selectTime: Date) => {
        setTime(selectTime);
        const sendTimeString = selectTime.getFullYear() + "-" + (selectTime.getMonth() + 1 < 10 ? "0" + (selectTime.getMonth() + 1) : selectTime.getMonth() + 1)
            + "-" + (selectTime.getDate() < 10 ? "0" + selectTime.getDate() : selectTime.getDate()) + " " + (selectTime.getHours() < 10 ? "0" + selectTime.getHours() :
                selectTime.getHours()) + ":" + (selectTime.getMinutes() < 10 ? "0" + selectTime.getMinutes() : selectTime.getMinutes()) + ":" + (selectTime.getSeconds() < 10 ? "0" + selectTime.getSeconds() : selectTime.getSeconds());
        const timeString = selectTime.getFullYear() + "-" + (selectTime.getMonth() + 1 < 10 ? "0" + (selectTime.getMonth() + 1) : selectTime.getMonth() + 1)
            + "-" + (selectTime.getDate() < 10 ? "0" + selectTime.getDate() : selectTime.getDate()) + " " + (selectTime.getHours() < 10 ? "0" + selectTime.getHours() :
                selectTime.getHours()) + ":" + (selectTime.getMinutes() < 10 ? "0" + selectTime.getMinutes() : selectTime.getMinutes());
        const timeNumber = selectTime.getTime();
        const params = {
            jobTime: sendTimeString,
            serviceRequestId: serviceRequestId,
            taskDesc: projectDesc
        }
        somEsrJob_insert(params).then(res => {
            if (res != "Error") {
                file = {
                    time: timeString,
                    timeNumber: timeNumber,
                    jobId: res.data.jobId,
                    serviceRequestId: "",
                    probability: "",
                    severity: "",
                    stepIndex: 0,
                    aPhysicalConditionFitWork:true
                };
                setIsOpen(false);
                setDisplay(ServerEnum.FLEX);
                tempFiles = [...files];
                tempFiles.push(file);
                setFiles(tempFiles);
            }
        })
    }

    const [close, setClose] = useState(true);

    PubSub.subscribe('close', (_, data) => {
        setClose(data);
    })

    //上传照片组件参数
    const photoProps: UploadProps = {
        beforeUpload: (file) => {
            setMYFile(file);
            return false;
        },
    };
    // 上传图片组件参数
    const props: UploadProps = {
        beforeUpload: (file) => {
            setMYFile(file);
            const fileTypes = [
                "image/apng",
                "image/bmp",
                "image/gif",
                "image/jpeg",
                "image/pjpeg",
                "image/png",
                "image/svg+xml",
                "image/tiff",
                "image/webp",
                "image/x-icon",
            ];
            const isPNG = fileTypes.includes(file.type);
            if (!isPNG) {
                message.error(`${file.name} is not a picture file`);
            }
            return false;
        },
    };
    // 上传文件组件参数
    const fileProps: UploadProps = {
        beforeUpload: (file) => {
            setMYFile(file);
            return false;
        },
    };

    const [isSpin, setIsSpin] = useState(false);

    const myUploadFile = () => {
        setTimes(times + 1);
        PubSub.publish("callback");
        const formData = new FormData();
        if (myFile)
            formData.append('multipartFile', myFile);
        formData.append("jobTime", files[currIndex.current].timeNumber.toString());
        if (serviceRequestId)
            formData.append("serviceRequestId", serviceRequestId);
        if (files[currIndex.current].jobId) {
            somEngineerServiceRequestJob_delete(files[currIndex.current].jobId).then()
        }
        somEngineerServiceRequestJob_insert(formData).then(res => {
            setFiles([]);
            if (serviceRequestId) {
                setIsSpin(true);
                if (res != "Error") {
                    if (myFile) {
                        const temp: any[] = [];
                        res.data.data.map((it: any) => {
                            const data = new Date(it.jobTime);
                            const Data = data.getFullYear() + "-" + ((data.getMonth() + 1) < 10 ? "0" + (data.getMonth() + 1) : (data.getMonth() + 1)) + "-" +
                                (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) + "  " +
                                (data.getHours() < 10 ? "0" + data.getHours() : data.getHours()) + ":" +
                                (data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes())
                            temp.push({
                                time: Data,
                                name: it.fileName,
                                timeNumber: it.jobTime,
                                jobId: it.jobId,
                                serviceRequestId: it.serviceRequestId,
                                probability: it.probability,
                                severity: it.severity,
                                stepIndex: it.stepIndex,
                            })
                        })
                        setFiles(temp);
                        setIsSpin(false)
                        if (myFile.name !== "") {
                            if (state !== "Six") {
                                PubSub.publish('five', false);
                            }
                        }
                    }
                }
            }
        })
        setOpen(false);
    }
    return (
        <Wrapper>
            <DatePicker
                min={new Date(minTime)}
                value={time}
                theme={'ios'}
                isOpen={isOpen}
                showCaption={true}
                customHeader={ServerEnum.WORK_TITLE}
                dateConfig={dateConfig}
                onSelect={handleSelect}
                onCancel={handleCancel}
            />
            <Spin spinning={isSpin} className="fileSpin">
                <div className="step-five" style={{opacity: isSpin ? 0 : 1}}>
                    <div className="prompt-text submitTime">
                        <div
                            style={!close ? PassTime !== "" ? {display: "block"} : {display: "none"} : {display: "none"}}
                            className="time"> {ServerEnum.SUBMIT_TIME}{!close ? PassTime : nowTime}</div>
                        <div style={!close ? {display: "none"} : {display: "block"}}
                             className="pro">{ServerEnum.PROMPT_TEXT}</div>
                    </div>
                    <div>
                        <div className="add"
                             style={{display: close ? "flex" : "none"}}
                             onClick={() => {
                                 navigate('/lmra/0/add')
                             }}>
                            <img src={Mask} alt=""/>
                            <div className="pro">{ServerEnum.ADD_LMRA}</div>
                        </div>
                        <div className="add-LMRA-title" style={{display: display}}>
                            <div className="column-one">
                                <div className="workTime pro">{ServerEnum.WORK_TIME}</div>
                            </div>
                            <div className="column-two">
                                <div className="pro">{ServerEnum.LMRA_SCHEDULE}</div>
                            </div>
                            <div className="column-three">
                                {ServerEnum.OPTIONS}
                            </div>
                        </div>
                        <div className="scoller">
                            {
                                files.map((it, index) => (
                                    <div className="file-one" key={index}
                                         style={{background: index % 2 !== 0 ? color.addLMRA : color.user}}>
                                        <div className="column-one">{it.time}</div>
                                        <div className="attachment column-two">
                                            <div className="lmra-file">
                                                <div className="file-name">
                                                    {it.stepIndex}/{!it.aPhysicalConditionFitWork?1:(it.probability === "H" &&
                                                    it.severity === "H") ? 4 : (it.probability === "H" &&
                                                    it.severity === "M") ? 4 : (it.probability === "M" && it.severity === "H") ? 4 : 5}
                                                </div>
                                                <div
                                                    style={{boxSizing: "border-box", paddingTop: "2px", paddingLeft: "5px"}}>
                                                    <Progress type="circle" percent={100 / (!it.aPhysicalConditionFitWork ? 1:(it.probability === "H"
                                                        && it.severity === "H") ? 4 : (it.probability === "H" && it.severity === "M") ? 4 : (it.probability === "M"
                                                        && it.severity === "H") ? 4 : 5) * it.stepIndex}
                                                              showInfo={false} strokeWidth={10} size={14} strokeColor={
                                                        ((it.probability === "H" &&
                                                            it.severity === "H") ? 4 : (it.probability === "H" &&
                                                            it.severity === "M") ? 4 : (it.probability === "M" && it.severity === "H") ? 4 : 5) === 4 || !it.aPhysicalConditionFitWork ? color.R4 : color.button
                                                    }/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column-three bin">
                                                <span onClick={() => {
                                                    navigate(`/lmra/${it.jobId}/edit`);
                                                }} style={{
                                                    display: it.stepIndex === 5 || ((
                                                        it.severity === "H" && it.probability === "H"
                                                        || it.severity === "H" && it.probability === "M"
                                                        || it.severity === "M" && it.probability === "H"
                                                    ) && it.stepIndex === 4) || !isDeleted || !it.aPhysicalConditionFitWork ? "none" : "inline-block"
                                                }}>{ServerEnum.EDIT}</span>
                                            <span onClick={() => {
                                                navigate(`/lmra/${it.jobId}/check`);
                                            }} style={{
                                                display: it.stepIndex === 5 || ((
                                                    it.severity === "H" && it.probability === "H"
                                                    || it.severity === "H" && it.probability === "M"
                                                    || it.severity === "M" && it.probability === "H"
                                                ) && it.stepIndex === 4) || !isDeleted || !it.aPhysicalConditionFitWork ? "inline-block" : "none"
                                            }}>{ServerEnum.CHECK}</span>
                                            <span style={{display:  it.stepIndex === 5 || ((
                                                    it.severity === "H" && it.probability === "H"
                                                    || it.severity === "H" && it.probability === "M"
                                                    || it.severity === "M" && it.probability === "H"
                                                ) && it.stepIndex === 4) ? "none" : isDeleted && it.aPhysicalConditionFitWork ? "block" : "none"}} onClick={() => {
                                                setHide(true)
                                                setArrIndex(index)
                                                setArrJobId(it.jobId)
                                            }}>{ServerEnum.DELETE}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        {/*9.25以下关于弹窗的代码暂时废弃*/}
                        {/* 添加LMRA弹窗 */}
                        <div className="drawer">
                            <Drawer
                                title={ServerEnum.DRAWER_TITLE}
                                placement={placement}
                                closable={false}
                                onClose={onClose}
                                open={open}
                                headerStyle={headerStyle}
                                contentWrapperStyle={contentStyle}
                            >
                                <div style={{height: "100%"}}>
                                    <div style={bodyStyle}>
                                        <Upload {...photoProps} showUploadList={false} maxCount={1} style={bodyStyle}
                                                disabled={true}
                                                onChange={myUploadFile}>
                                            <div style={optionPhotoStyle}
                                                 className="pic">{ServerEnum.DRAWER_PICTURE}</div>
                                        </Upload>
                                    </div>
                                    <div style={bodyStyle}>
                                        <Upload {...props} showUploadList={false} maxCount={1} onChange={myUploadFile}>
                                            <div style={optionStyle}>{ServerEnum.DRAWER_PHOTO} </div>
                                        </Upload>
                                    </div>
                                    <div style={bodyStyle}>
                                        <Upload {...fileProps} showUploadList={false} maxCount={1}
                                                onChange={myUploadFile}>
                                            <div style={optionStyle}>{ServerEnum.DRAWER_FILE}</div>
                                        </Upload>
                                    </div>
                                    <div style={optionCancelStyle} onClick={() => {
                                        setOpen(false)
                                    }}
                                         className="cancel">{ServerEnum.DRAWER_CANCEL}
                                    </div>
                                </div>
                            </Drawer>
                        </div>
                    </div>

                </div>
            </Spin>
            {/*隔板*/}
            <div className="cover" style={{display: hide ? "block" : "none"}}></div>
            {/*双按钮弹窗*/}
            <div className="twoPop" style={{display: hide ? "block" : "none"}}>
                <ChangePop top={otherEnum.IS_DELETE} leftOnClick={() => {
                    setHide(false)
                }} rightWord={otherEnum.SURE}
                           rightOnClick={() => {
                               handleDelete(arrIndex, arrJobId);
                               setHide(false);
                               if (onClick) {
                                   onClick()
                               }
                           }
                           }/>
            </div>
        </Wrapper>
    )
}
export default AddLMRA;
